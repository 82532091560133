const errorCode = (errorObj) => {
	if (errorObj?.response?.data?.uuid) return errorObj.response.data.uuid

	return btoa(
		JSON.stringify({
			error: errorObj?.response?.data ?? { ...errorObj },
			date: new Date().toString(),
		}),
	)
}

export default errorCode
