import styled from 'styled-components'

export const FieldTitle = styled.div`
	font-weight: bold;
	color: var(--color-text-dark);
	font-size: 0.8em;
`

export const FieldFlexBox = styled.div`
	display: flex;
	align-items: center;
	gap: 2px;

	.whatsIcon {
		margin-top: 0.4rem;
		color: #25d366;
	}
`

export const FieldValue = styled.div`
	color: var(--color-text-light);
	font-size: 0.9em;

	.whatsIcon {
		color: #25d366;
	}
`
