import styled from 'styled-components'

export const SliderWrapper = styled.div`
	margin-top: calc(var(--spacing) * 0.8);
	margin-left: calc(-2 * var(--spacing));
	margin-right: calc(-2 * var(--spacing));

	.slick-slider {
		padding-bottom: 25px;
		box-sizing: content-box;
	}
	.slick-track {
		display: flex;
	}
	.slick-list {
		padding: 0 calc(1.75 * var(--spacing));
	}
	.slick-slide {
		padding: 0 calc(0.25 * var(--spacing));
		float: none;
		height: auto;
		> div {
			height: 100%;
		}
	}

	.slick-arrow {
		position: absolute;
		top: 0;
		bottom: 0;
		width: calc(-2 * var(--spacing));
		font-size: 0;
		color: transparent;
		z-index: 1;
	}
	.slick-prev {
		left: 0;
	}
	.slick-next {
		right: 0;
	}

	.slick-dots {
		position: absolute;
		bottom: 0;

		display: block;
		width: 100%;
		padding: 0;
		margin: 0;
		list-style: none;
		text-align: center;
	}
	.slick-dots li {
		position: relative;
		display: inline-block;
		margin: 0;
		padding: 0;
		cursor: pointer;
	}
	.slick-dots li button {
		font-size: 0;
		line-height: 0;
		display: block;
		padding: 6px 3px;

		cursor: pointer;

		color: transparent;
		border: 0;
		outline: none;
		background: transparent;
	}
	.slick-dots li button:hover,
	.slick-dots li button:focus {
		outline: none;
	}
	.slick-dots li button:hover:before,
	.slick-dots li button:focus:before {
		opacity: 1;
	}
	.slick-dots li button:before {
		content: '';
		display: block;

		width: 6px;
		height: 6px;
		border-radius: 3px;

		background: black;
		transition: width 0.2s ease;
	}
	.slick-dots li.slick-active button:before {
		width: 15px;
		color: black;
	}
`
