import React from 'react'
import { nanoid } from 'nanoid'
import Svg from '~/components/svg'
import { themeColors } from '~/styles/cssVariables'

function IconData(props) {
	const id = `id${nanoid()}`
	return (
		<Svg viewBox="0 0 20.5 20.5" {...props}>
			<linearGradient
				id={id}
				x1="0.5"
				x2="1"
				y1="1"
				y2="0"
				gradientUnits="objectBoundingBox"
			>
				{themeColors.map((c, i, arr) => (
					<stop
						key={`key-${i + c}`}
						offset={i === arr.length - 1 ? 0.9 : i / (arr.length - 1)}
						stopColor={c}
					/>
				))}
			</linearGradient>
			<path
				d="M6.2 8.41a.77.77 0 00-1.08-.04.77.77 0 00-.05 1.1l3.9 4.21a.77.77 0 00.54.25h.02a.78.78 0 00.55-.22L19.9 3.9a.77.77 0 000-1.1.77.77 0 00-1.09 0l-9.27 9.25zm13.53 1.07a.77.77 0 00-.77.77 8.71 8.71 0 01-8.71 8.7 8.71 8.71 0 01-8.7-8.7 8.71 8.71 0 018.7-8.7.77.77 0 00.77-.77.77.77 0 00-.77-.77A10.26 10.26 0 000 10.26 10.26 10.26 0 0010.25 20.5 10.26 10.26 0 0020.5 10.26a.77.77 0 00-.77-.78z"
				fill={props.color || `url(#${id})`}
			/>
		</Svg>
	)
}

export default IconData
