import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import copy from 'clipboard-copy'
import { DEFAULT_REQUEST_ERROR_MSG } from '~/config/constants'
import error500 from '~/images/error500.svg'
import { mq } from '~/helpers/breakpoints'

const FormError = ({ error, showImg }) => {
	const [copied, setCopied] = useState(false)

	const handleCodeClick = () => {
		;(async () => {
			try {
				await copy(error.code)
				setCopied(true)
			} catch (err) {
				console.error('Erro ao copiar código', err)
			}
		})()
	}

	return (
		<Wrapper>
			<Message>
				{error?.message ??
					(typeof error === 'string' ? error : DEFAULT_REQUEST_ERROR_MSG)}
			</Message>
			{error?.code ? (
				<>
					{showImg ? <Error500 /> : undefined}
					<CodeBoxPreTitle>
						Se o erro persistir, informe o código de erro abaixo ao
						administrador do site
					</CodeBoxPreTitle>
					<CodeBoxTitle>Código do erro:</CodeBoxTitle>
					<CodeBox onClick={handleCodeClick}>
						<Code>{error.code}</Code>
						<HelperText success={copied}>
							{copied ? 'Copiado!' : 'Clique para copiar'}
						</HelperText>
					</CodeBox>
				</>
			) : undefined}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	margin: calc(var(--spacing) * 2) auto calc(var(--spacing) * 1);
	text-align: center;
	width: 100%;
`
const Message = styled.div`
	color: var(--color-error);
	font-weight: 500;
`

const CodeBoxPreTitle = styled.div`
	font-size: 0.8rem;
	margin-top: 1rem;
	font-weight: bold;
`

const CodeBoxTitle = styled.div`
	font-size: 0.8rem;
	margin-top: 1rem;
	font-weight: bold;
	color: #d22a2a;
`

const CodeBox = styled.div`
	font-size: 0.7em;
	margin-top: 1em;
`
const Code = styled.div`
	width: 100%;
	max-height: 30px;
	word-break: break-all;
	font-family: monospace;
	overflow: auto;
	line-height: 11px;
	position: relative;
	cursor: pointer;
	padding: 0.5em;
	border: 1px solid #ccc;
	border-radius: 2px;
	background-color: #fff;
	color: #000;
`

const HelperText = styled.div`
	margin: 0 auto;
	text-align: center;
	text-transform: lowercase;
	font-size: 0.8em;
	margin-top: 0.3em;
	transition: all 0.2s ease;
	cursor: pointer;

	${(props) =>
		props.success &&
		css`
			color: var(--color-success);
			font-size: 1em;
		`}
`

const Error500 = styled.div.attrs({ className: 'error-img' })`
	background-image: url(${error500});
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center bottom;
	height: 60vw;
	max-height: 0;
	width: 100%;
	padding-top: 70%;
	margin-left: calc(var(--site-h-padding) * -1);
	margin-right: calc(var(--site-h-padding) * -1);
	margin-top: var(--spacing);

	${mq.min('md')} {
		max-width: 100%;
		max-height: 23vh;
		margin-left: 0;
		margin-right: 0;
		padding-top: 0;
	}
`

export default FormError
