/* eslint-disable indent */
import { css } from 'styled-components'
import { mq } from '~/helpers/breakpoints'

export const themeColors =
	process.env.REACT_APP_UNIVERSO_LASA === 'true'
		? ['#E92100', '#04EAC3', '#00BEFF', '#5747C3']
		: [
				'#f37121',
				'#FF0000',
				'#433a98', // original: #322B71
		  ]

export default css`
	:root {
		/* colors */

		--color-red: #ff0037; /* old:  #E92100 /*

      /* text */
		--color-text-light: #707070;
		--color-text-lighter: #aaa;
		--color-text-dark: #222;

		/* status */
		--color-error: #d22e2e;
		--color-success: #12c97a;

		--color-tap: rgba(77, 58, 152, 0.4);

		/* gradients */
		/* define gradiente de acordo com as cores principais definidas no theme.js */
		/* → A → B → C */
		--gradient: linear-gradient(
			to right,
			${themeColors
				.map((c, i) => {
					if (
						i === themeColors.length - 1 &&
						!(process.env.REACT_APP_UNIVERSO_LASA === 'true')
					)
						return `${c} 110%`
					return c
				})
				.join(',')}
		);
		/* ↓ A - B - C */
		--gradient-top-bottom: linear-gradient(
			to bottom,
			${themeColors
				.map((c, i) => {
					if (
						i === themeColors.length - 1 &&
						!(process.env.REACT_APP_UNIVERSO_LASA === 'true')
					)
						return `${c} 110%`
					return c
				})
				.join(',')}
		);
		/* → A → B → C → B → A */
		--gradient-symetric: linear-gradient(
			to right,
			${[themeColors, themeColors.slice(0, -1).reverse()]
				.map((c) => c)
				.join(',')}
		);

		/* title */
		--title-size: 2.7rem;
		--title-weight: bold;
		--title-color: var(--color-text-dark);

		--border-radius: 5px;

		--input-line-thickness: 2px;

		/* spacing */
		--spacing: 1.25rem;
		--site-h-padding: var(--spacing);
		${mq.min('md')} {
			--site-h-padding: calc(var(--spacing) * 2);
		}
		/*${mq.min('lg')} {
      --site-h-padding: calc(var(--spacing) * 3);
    }*/
	}
`
