import React from 'react'
import styled from 'styled-components'
import { nanoid } from 'nanoid'
import { rotate } from '~/styles/animations'
import { themeColors } from '~/styles/cssVariables'

function Loading({ text, size }) {
	const id = `id${nanoid()}`

	return (
		<Centralize>
			<LoadingSvg viewBox="0 0 100 100" size={size}>
				<defs>
					<linearGradient
						id={id}
						x1=".5"
						x2=".5"
						y2="1"
						gradientUnits="objectBoundingBox"
					>
						{themeColors.map((c, i, arr) => (
							<stop
								key={`key-${i + c}`}
								offset={i / (arr.length - 1 || 1)}
								stopColor={c}
							/>
						))}
					</linearGradient>
				</defs>
				<path
					fill={`url(#${id})`}
					d="M-2404-188a50 50 0 01-19-4 50 50 0 01-16-11 50 50 0 01-11-16 50 50 0 01-4-19 50 50 0 014-19 50 50 0 0111-16 50 50 0 0116-11 50 50 0 0119-4 50 50 0 0119 4 50 50 0 0116 11 50 50 0 0111 16 50 50 0 014 19 50 50 0 01-4 19 50 50 0 01-11 16 50 50 0 01-16 11 50 50 0 01-19 4zm0-93a43 43 0 00-43 43 43 43 0 0043 43 43 43 0 0043-43 43 43 0 00-43-43z"
					transform="rotate(-90 -1033 -1321)"
				/>
			</LoadingSvg>
			<span>{text}</span>
		</Centralize>
	)
}

const Centralize = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const LoadingSvg = styled.svg`
	animation: ${rotate} 0.6s infinite linear;
	width: calc(5rem * ${(props) => props.size || 1});

	&:first-child:not(:last-child) {
		margin-bottom: calc(var(--spacing) * 0.7);
	}
`

export default Loading
