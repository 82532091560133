import styled from 'styled-components'
import { hideVisually } from 'polished'

import { mq } from '~/helpers/breakpoints'
import PhotoImg from '../../components/nameTag/photoImg'

export const HiddenInput = styled.input`
	${hideVisually()}
`

export const PhotoField = styled.div`
	margin-top: var(--spacing);
	display: flex;
	flex-direction: column;

	${PhotoImg} {
		align-self: center;
	}

	.btns {
		margin: var(--spacing) 0;
	}

	${mq.min('md')} {
		flex-direction: row;
		align-items: center;
		justify-content: space-evenly;
		max-width: 600px;
	}
`

export const PhotoSavingLoader = styled.div`
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	& ~ * {
		visibility: hidden;
	}
`
