import React from 'react'
import { Redirect } from 'react-router'
import { useSelector } from 'react-redux'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

import MainLayout from '~/layouts/mainLayout'
import { getStages } from '~/store/reducers/stages/selectors'
import { getViewPortSize } from '~/store/reducers/ui/selectors'
import IconData from '~/svgs/iconData'
import { breakpointMatch } from '~/helpers/breakpoints'
import { FormBody, FormTip, Loading, Text } from '~/components'
import { stages } from '~/config/menu'
import history from '~/helpers/history'
import Button from '~/components/button'
import { getUser } from '~/store/reducers/user/selectors'

import { path as PersonalDataPath } from '../personalDataPage'
import { path as homePath } from '../homePage'
import { StyledStageLink } from './styles'

export const title = 'Dados cadastrais'
export const path = '/dados-cadastrais'
export const icon = IconData

const RegistrationPage = () => {
	const { search } = useLocation()
	const { backTo } = queryString.parse(search)

	const { loading, currentStages } = useSelector(getStages)
	const viewPortSize = useSelector(getViewPortSize)

	const { revisaoGeralFinalizada } = useSelector(getUser)
	const readyToFinish =
		currentStages?.length > 1 &&
		currentStages?.reduce(
			(ready, s) => (ready === false ? false : s.porcentagem === 1),
			undefined,
		)

	if (breakpointMatch(viewPortSize).isDesktop)
		return <Redirect replace to={PersonalDataPath} />

	if (loading)
		return (
			<MainLayout>
				<Loading />
			</MainLayout>
		)

	return (
		<MainLayout onGoBack={() => history.push(backTo || homePath)}>
			<FormBody>
				<Text dark>
					<p>
						Preencha seus dados com muita atenção e não deixe de anexar a foto
						do documento oficial que comprove os dados preenchidos.
					</p>
				</Text>
				<FormTip>
					Certifique que os documentos estão legíveis, prefira locais com boa
					claridade e se possível utilize um aplicativo de scanner caso
					digitalize seu documento pelo celular.
				</FormTip>

				{currentStages
					?.filter((cs) => cs.rota === path)?.[0]
					?.subEtapa?.map((se) => {
						const description = stages
							?.filter((s) => s.path === path)[0]
							.children?.filter((c) => c.path === se.rota)?.[0]?.description
						return (
							<StyledStageLink
								key={se.rota}
								name={se.nome}
								description={description}
								percent={se.porcentagem}
								enabled
								to={se.rota}
								displayArrow
							/>
						)
					})}

				{!revisaoGeralFinalizada && (
					<Button
						style={{ marginTop: '50px' }}
						to="/revisao"
						large
						primary
						disabled={!readyToFinish}
					>
						Revisar para enviar
					</Button>
				)}
			</FormBody>
		</MainLayout>
	)
}

export default RegistrationPage
