import React from 'react'
import Svg from '~/components/svg'

function IconWarning(props) {
	return (
		<Svg viewBox="0 0 20 20" {...props}>
			<path
				fill="#ffa100"
				d="M10 20a10 10 0 1110-10 10 10 0 01-10 10zm-1-7v2h2v-2zm0-8v6h2V5z"
			/>
		</Svg>
	)
}

export default IconWarning
