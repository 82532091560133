import React from 'react'
import styled, { css } from 'styled-components'
import { mq } from '~/helpers/breakpoints'
import error404 from '~/images/error404.svg'
import errorGeneric from '~/images/errorGeneric.svg'

function Error({ notFound, msg, ...props }) {
	return (
		<ErrorWrapper {...props}>
			<ErrorTitle>Ops!</ErrorTitle>
			<Description notFound={notFound}>
				{notFound
					? 'Página não encontrada'
					: msg ||
					  'Ocorreu um erro no carregamento da página. Por favor tente novamente mais tarde.'}
			</Description>
			<ErrorImg notFound={notFound} />
		</ErrorWrapper>
	)
}

const ErrorWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

const ErrorTitle = styled.div`
	font-size: 2rem;
	font-weight: 600;
	color: var(--color-text-dark);
	text-transform: uppercase;
	margin: 0.5em 0 0.6em;
	margin-left: calc(var(--spacing) * 1);
	${mq.min('md')} {
		width: 500px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-top: 0;
	}
`

const Description = styled.div`
	margin-left: calc(var(--spacing) * 1);

	${mq.min('md')} {
		width: 500px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
	}

	${(props) =>
		props.notFound
			? css`
					font-size: 1.4em;
					font-weight: 500;
					margin-bottom: calc(var(--spacing) * 2);
			  `
			: css`
					font-size: 0.95em;
					font-weight: normal;
					margin-bottom: calc(var(--spacing) * 1);
			  `}
`

const ErrorImg = styled.div.attrs({ className: 'error-img' })`
	/* background-image: url(${(props) =>
		props.notFound ? error404 : errorGeneric}); */
	// TODO If not work IR
	${(props) =>
		props.notFound
			? `background-image: url(${error404});`
			: `background-image: url(${errorGeneric});`}

	background-repeat: no-repeat;
	background-size: 125% 100%;
	background-position: calc(50% - 20px) bottom;
	height: 120vw;
	width: 100vw;
	margin-left: calc(var(--site-h-padding) * -1);
	margin-right: calc(var(--site-h-padding) * -1);

	${mq.min('md')} {
		max-width: 100%;
		max-height: 500px;

		background-position: center bottom;
	}
`

export default styled(Error)``
