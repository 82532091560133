import React from 'react'
import { Field } from 'react-final-form'

const FieldValuesSpy = ({ children, name, names }) => {
	if (!Array.isArray(names)) {
		return (
			<Field name={names ?? name} subscription={{ value: true }}>
				{({ input: { value, ...input }, meta }) =>
					children(value, { ...input, ...meta })
				}
			</Field>
		)
	}

	return names.reduce(
		(acc, fieldName) => (values) =>
			(
				<Field name={fieldName} subscription={{ value: true }}>
					{({ input: { value } }) => acc({ ...values, [fieldName]: value })}
				</Field>
			),
		children,
	)()
}

export default FieldValuesSpy
