import React from 'react'
import styled, { css } from 'styled-components'

function FieldSection({ label, children, titleProps, labelLine, ...props }) {
	return (
		<section {...props}>
			<SectionTitle {...titleProps} line={labelLine}>
				{label}
			</SectionTitle>
			{children}
		</section>
	)
}

const SectionTitle = styled.h1`
	font-size: 1rem;
	font-weight: 400;
	color: var(--color-text-dark);
	margin: 1.7em 0 1em;
	${(props) =>
		props.line &&
		css`
			border-bottom: 1px solid #ccc;
			padding-bottom: 0.4em;
		`}
`

export default FieldSection
