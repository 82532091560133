import React from 'react'
import styled from 'styled-components'
import { LogoAmericanas } from '~/components'
import { mq } from '~/helpers/breakpoints'
import Header from './header'

import background from '~/images/public-background.png'
import titlePortal from '~/images/title-portal-boas-vindas.png'

const LoginLayout = ({ children, ...props }) => {
	return (
		<Layout {...props}>
			<Header />
			<Main>
				<ContentWrapper>{children}</ContentWrapper>
			</Main>

			<Footer>
				<img src={titlePortal} alt="" />
				<LogoAmericanas color="#fff" size={2} />
			</Footer>
		</Layout>
	)
}

const Layout = styled.div`
	height: 100%;
	display: grid;
	grid-template-rows: 50px 1fr 50px;
	grid-template-areas:
		'header'
		'main'
		'footer';

	${mq.min('md')} {
		padding: 0 160px;
		padding: 0 max(160px, calc((100vw - 1280px) / 2));
		background: url(${background});
		background-size: cover;
		background-position: top right;

		grid-template-columns: 1fr 550px 1fr;
		grid-template-rows: 1fr minmax(530px, 60vh) 1fr;
		grid-template-areas:
			'header header header'
			'.      main   .'
			'footer footer footer';
		gap: var(--spacing);
	}
	${mq.min('lg')} {
		padding: 140px 160px;
		padding: 140px max(160px, calc((100vw - 1280px) / 2));
		background: url(${background});
		background-size: cover;
		background-position: top right;

		grid-template-rows: 50% 50%;
		grid-template-columns: 1fr 550px;
		grid-template-columns: 1fr min(
				calc(50vw - 160px - var(--spacing) * 3),
				550px
			);
		grid-template-areas:
			'header main'
			'footer main';
	}
	${`${mq.min('lg')}and (max-height: 700px)`} {
		padding-top: 90px;
		padding-bottom: 90px;
	}
`

const Main = styled.main`
	padding-left: var(--site-h-padding);
	padding-right: var(--site-h-padding);

	grid-area: main;

	${mq.desktop} {
		background: #fff;
		padding: calc(1 * var(--spacing)) calc(3 * var(--spacing))
			calc(3 * var(--spacing));
		border-radius: var(--border-radius);
	}
`

const ContentWrapper = styled.div`
	flex-grow: 1;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding-top: calc(1.5 * var(--spacing));
	align-items: center;
	justify-content: center;
`

const Footer = styled.footer`
	grid-area: footer;
	flex-direction: column;
	display: none;

	img {
		width: 55%;
		margin-bottom: 30px;

		${mq.min('lg')} {
			width: 73%;
			margin-bottom: 70px;
		}
	}

	${mq.min('md')} {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 1rem 1rem 2rem 1rem;
	}
	${mq.min('lg')} {
		justify-content: flex-end;
		align-items: baseline;
		padding: 0;
	}
`

export default LoginLayout
