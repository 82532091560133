import React from 'react'
import { formatCPF } from '~/helpers/formatters'
import { cpf } from '~/helpers/validators'
import TextField from './textField'

function CpfField({ name, validators, fieldProps, ...props }) {
	validators = [...(validators ?? []), cpf]

	return (
		<TextField
			name={name}
			validators={validators}
			formatter={formatCPF}
			parser={(value) => value.replace(/[^0-9]/g, '').slice(0, 11)}
			fieldProps={fieldProps}
			inputMode="numeric"
			{...props}
		/>
	)
}

export default CpfField
