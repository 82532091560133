import React from 'react'
import styled from 'styled-components'
import clsx from 'clsx'
import { mq } from '~/helpers/breakpoints'
import IconArrow from '~/svgs/iconArrow'
import Svg from '../svg'
import Percentage from './percentage'
import Link from '../link'
import IconWarning from '~/svgs/iconWarning'

const StageLink = ({
	Icon,
	name,
	description,
	to,
	percent,
	enabled,
	error,
	displayArrow,
	...props
}) => {
	return (
		<Wrapper
			to={to}
			percent={percent}
			$enabled={enabled}
			error={error}
			title={name}
			{...props}
		>
			{Icon && <Icon color={enabled ? undefined : 'var(--color-text-light)'} />}
			<Name>{name}</Name>
			{description && <Description>{description}</Description>}
			{displayArrow && (
				<Arrow>
					<IconArrow size={0.7} />
				</Arrow>
			)}

			{(error || Boolean(percent)) && (
				<StatusIconWrapper>
					{error ? <StyledIconWarning /> : <Percentage progress={percent} />}
				</StatusIconWrapper>
			)}
		</Wrapper>
	)
}

const Wrapper = styled(Link).attrs((props) => ({
	className: clsx({
		enabled: props.$enabled,
		error: props.error,
	}),
	disabled: !props.$enabled,
}))`
	border: 1px solid #efefef;
	background: #e5e5e5;
	border-radius: var(--border-radius);
	cursor: default;
	display: flex;
	position: relative;
	min-width: 100%;
	padding: calc(var(--spacing) * 0.6) calc(var(--spacing) * 1.15);
	font-size: 1.1rem;
	font-weight: 500;
	text-decoration: none;
	flex-direction: column;
	justify-content: space-between;
	align-items: start;
	color: var(--color-text-light);
	:hover,
	:focus {
		color: var(--color-text-light);
	}
	transition: color 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease,
		border 0.2s ease;

	-webkit-tap-highlight-color: var(--color-tap);
	user-select: none;
	outline: none;

	${Svg} {
		font-size: 1.55em;
		${mq.min('md')} {
			font-size: 2em;
		}
	}

	&.enabled {
		background: #fff;
		cursor: pointer;
		color: var(--color-text-dark);

		:hover,
		:focus {
			color: var(--color-text-dark);
		}
		:focus-visible {
			border: 1px solid #aaa;
			box-shadow: 0 0 1px 1px #aaa;
		}
		:hover {
			border-color: transparent;
			box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
		}
	}

	&.error {
		border-color: #e92100;
	}
`

const StatusIconWrapper = styled.div.attrs({
	className: 'status-icon',
})`
	position: absolute;
	left: -0.875rem;
	top: 50%;
	transform: translateY(-50%);

	--status-icon-size: ${(props) => props.size || 1};
	width: calc(var(--status-icon-size) * 1.75rem);
	height: calc(var(--status-icon-size) * 1.75rem);
`

const StyledIconWarning = styled(IconWarning)`
	width: 100%;
	height: 100%;
`

const Name = styled.div.attrs({ className: 'name' })``

const Arrow = styled.div`
	position: absolute;
	right: 0.5rem;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`

const Description = styled.div`
	color: #707070;
	font-weight: 300;
	font-size: 0.78em;
	margin-top: 0.3em;

	min-width: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

export default styled(StageLink)``
