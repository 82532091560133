import React, { useState } from 'react'
import toast from 'react-hot-toast'
import styled from 'styled-components'
import { blobToBase64 } from 'base64-blob'
import api from '~/services/api'
import IconEye from '~/svgs/iconEye'
import Loading from './loading'
import Modal from './modal'

const ReviewAttachmentBtn = ({ type, label }) => {
	const [fileView, setFileView] = useState(undefined)

	// ------ lógica para baixar e visualizar imagem ------
	const attachmentGetter = async () => {
		try {
			const { data } = await api.upload(type).get()
			return data
		} catch (err) {
			// console.error(err)
			toast.error('Não foi possível recuperar o arquivo. Tente Novamente')
			return false
		}
	}

	const handleView = async () => {
		try {
			setFileView('loading')
			setFileView(await blobToBase64(await attachmentGetter()))
		} catch (err) {
			setFileView(undefined)
			console.error(err)
			toast.error('Ocorreu um erro ao exibir o arquivo. Tente Novamente')
		}
	}

	return (
		<>
			<Btn onClick={handleView}>
				<IconEye size={1.6} />
			</Btn>

			{/* Visualização do arquivo */}
			<Modal
				isOpen={Boolean(fileView)}
				onRequestClose={() => {
					setFileView(undefined)
				}}
				renderHeader={() => label}
				// renderFooter={() => (<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
				//   <Button to={HomePath} primary large>Começar</Button>
				// </div>)}
			>
				{fileView === 'loading' ? (
					<div style={{ height: '60vh' }}>
						<Loading size={0.5} />
					</div>
				) : (
					<img src={fileView} alt="" />
				)}
			</Modal>
		</>
	)
}

const Btn = styled.button.attrs({ type: 'button' })`
	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
	color: var(--color-text-lighter);
	transition: all 0.2s ease;
	:hover,
	:focus {
		color: var(--color-text-light);
	}
	:active {
		transform: scale(0.9);
	}
	-webkit-tap-highlight-color: transparent;
	user-select: none;
`

export default ReviewAttachmentBtn
