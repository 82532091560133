import React from 'react'
import NumberFormat from 'react-number-format'
import { TextInput } from '..'

function NumberInput({
	onBlur,
	onFocus,
	onChange,
	onValueChange,
	error,
	valid,
	inputRef,
	...props
}) {
	return (
		<NumberFormat
			thousandSeparator="."
			decimalSeparator=","
			decimalScale="2"
			allowNegative={false}
			onBlur={onBlur}
			onFocus={onFocus}
			onChange={onChange}
			onValueChange={onValueChange}
			displayType="input"
			customInput={TextInput}
			error={error}
			valid={valid}
			autoComplete="off"
			inputMode="decimal"
			{...props}
		/>
	)
}

export default NumberInput
