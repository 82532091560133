import React from 'react'
import styled from 'styled-components'

import DocsList from './docsList'
import StartForm from '../startForm'

const DocsBox = () => {
	return (
		<Wrapper>
			<Header>
				<span>Lista de documentos</span>
			</Header>
			<Body>
				<DocsList />
				<StartForm />
			</Body>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	background: #fff;
	border-radius: calc(var(--border-radius) * 2);
	padding: calc(var(--spacing) * 1.5) calc(var(--spacing) * 2)
		calc(var(--spacing) * 2);
`

const Header = styled.div`
	padding-bottom: calc(var(--spacing) * 0.7);
	margin-bottom: 0;
	border-bottom: 1px solid #e6e6e6;
	color: var(--color-text-dark);
	font-weight: bold;

	display: flex;
	align-items: center;
	justify-content: space-between;
`

const Body = styled.div`
	padding: calc(var(--spacing) * 1);
	padding-bottom: 0;
`

export default DocsBox
