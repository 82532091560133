import React from 'react'
import Svg from '~/components/svg'

function IconAttachment(props) {
	return (
		<Svg viewBox="16 5 21 24" {...props}>
			<path d="M30.379 13.388l-1.268-1.268-6.346 6.342a2.693 2.693 0 103.808 3.81l7.613-7.611a4.485 4.485 0 00-6.343-6.342l-7.994 7.987-.017.017a6.257 6.257 0 008.848 8.849l.016-.017 5.456-5.455-1.269-1.268-5.456 5.454-.017.016a4.461 4.461 0 01-6.31-6.308l.017-.017 7.994-7.995a2.691 2.691 0 013.806 3.805l-7.613 7.611a.9.9 0 11-1.269-1.267l6.344-6.343z" />
		</Svg>
	)
}

export default IconAttachment
