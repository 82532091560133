import React, { useEffect, useRef } from 'react'
import { remToPx } from 'polished'
import Slider from 'react-slick'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { getViewPortSize } from '~/store/reducers/ui/selectors'
import { breakpointMatch } from '~/helpers/breakpoints'

import { SliderWrapper } from './styles'

function Carousel({ children, ...props }) {
	const vp = useSelector(getViewPortSize)
	const { isMobile } = breakpointMatch(vp)

	const sliderRef = useRef()

	const sliderSettings = {
		arrows: true,
		dots: true,
		infinite: false,
		speed: 500,
		centerMode: true,
		centerPadding:
			parseInt(
				remToPx(getComputedStyle(document.body).getPropertyValue('--spacing')),
			) * 1.75,
		slidesToShow: isMobile ? 1 : 3,
		slidesToScroll: 1,
	}

	useEffect(() => {
		if (!sliderRef.current) return

		const { list } = sliderRef.current.innerSlider
		const slider = sliderRef.current.innerSlider.list.parentElement

		const resizeObserver = new ResizeObserver((entries) => {
			// eslint-disable-next-line no-restricted-syntax
			for (const entry of entries) {
				if (entry.contentBoxSize) {
					// Firefox implements `contentBoxSize` as a single content rect, rather than an array
					const contentBoxSize = Array.isArray(entry.contentBoxSize)
						? entry.contentBoxSize[0]
						: entry.contentBoxSize
					slider.style.height = `${contentBoxSize.blockSize}px`
				} else {
					slider.style.height = `${entry.contentRect.height}px`
				}
			}
		})

		resizeObserver.observe(list)
	}, [])

	return (
		<SliderWrapper {...props}>
			<Slider {...sliderSettings} ref={sliderRef}>
				{children}
			</Slider>
		</SliderWrapper>
	)
}

export default styled(Carousel)``
