import { combineReducers } from 'redux'

import user from './user/reducer'
import ui from './ui/reducer'
import stages from './stages/reducer'
import { LOGOUT } from './user/types'

const rootReducer = combineReducers({
	user,
	ui,
	stages,
})

export default (state, action) =>
	rootReducer(action.type === LOGOUT ? undefined : state, action)
