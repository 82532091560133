/* eslint-disable no-shadow */
import React, { useMemo } from 'react'
import { Form } from 'react-final-form'
import setFieldData from 'final-form-set-field-data'
import createDecorator from 'final-form-calculate'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { differenceInYears, parse } from 'date-fns'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

import MainLayout from '~/layouts/mainLayout'
import {
	AttachmentField,
	Button,
	CheckBoxField,
	CpfField,
	DateTextField,
	FieldWrapper,
	FormError,
	FormWrapper,
	FormBody,
	FormFooter,
	FormTip,
	SelectField,
	Text,
	TextField,
	FieldValuesSpy,
	FormSection,
	FieldSection,
	FormIsntComplete,
	Loading,
	FormInfo,
	FormRow,
} from '~/components'
import usePBVForm from '~/hooks/usePBVForm'
import history from '~/helpers/history'
import { getUser } from '~/store/reducers/user/selectors'
import { UFS, COUNTRIES, SEXES, TIPOS_FAMILIAR } from '~/config/constants'
import {
	dateBefore,
	minLength,
	nameMustBeComplete,
	required,
} from '~/helpers/validators'
import { setUser } from '~/store/reducers/user/actions'
import { getViewPortSize } from '~/store/reducers/ui/selectors'
import { breakpointMatch } from '~/helpers/breakpoints'

import { path as familyPath } from '../familyPage'

export const title = () => {
	if (window.location.pathname.indexOf('novo') !== -1)
		return 'Adicionar familiar'
	return 'Editar familiar'
}
export const path = '/dados-cadastrais/familia/:id'

// decorator para apagar campos de anexo se tipoFamiliar === "cônjuge"
const tipoFamiliarHandler = createDecorator({
	field: 'tipoFamiliar',
	updates: (value) => {
		if (value === 'filho') return {}

		return {
			certidaoNascimento: {},
			cadernetaVacinacao: {},
		}
	},
})
// decorator para apagar caderneta de vacinação se dataNascimento > 5 anos
const dataNascimentoHandler = createDecorator({
	field: 'dataNascimento',
	updates: (value) => {
		if (
			differenceInYears(new Date(), parse(value, 'dd/MM/yyyy', new Date())) <= 5
		)
			return {}

		return {
			cadernetaVacinacao: {},
		}
	},
})
// decorator para apagar uf caso pais !== brasil
const paisHandler = createDecorator({
	field: 'pais',
	updates: {
		uf: (paisValue, values) => (paisValue !== 'BR' ? undefined : values.uf),
	},
})

const attachmentFields = [
	'certidaoNascimento.idAnexo',
	'cadernetaVacinacao.idAnexoIdentificacao',
	'cadernetaVacinacao.idAnexoQuadroVacinal',
]

const dateFields = ['dataNascimento']

const FamilyMemberPage = () => {
	const { id } = useParams() // pode ser 'novo' ou o id do familiar

	const { search } = useLocation()
	const { backTo } = queryString.parse(search)

	const vp = useSelector(getViewPortSize)
	const { isDesktop } = breakpointMatch(vp)

	const user = useSelector(getUser)
	const dispatch = useDispatch()

	const partnerFilledOrInexistant =
		(user?.estadoCivil === 'casado' && user?.conjuge) ||
		(user?.estadoCivil !== undefined && user?.estadoCivil !== 'casado')

	const defaultValues = useMemo(
		() => ({
			tipoFamiliar: partnerFilledOrInexistant ? 'filho' : undefined,
			pais: 'BR', // valor default para país
		}),
		[partnerFilledOrInexistant],
	)

	const { loading, error, initialValues, submitForm, editingDisabled } =
		usePBVForm(`familia/${id === 'novo' ? '' : id}`, {
			stagePath: familyPath,
			isNew: id === 'novo',
			attachmentFields,
			dateFields,
			defaultValues,
			afterSubmit: (values, initialValues) => {
				if (values.tipoFamiliar === 'conjuge') {
					// bota informações no estado geral para uso em regras de campos de outros formulários
					dispatch(
						setUser({
							conjuge: values,
						}),
					)
				}
				if (
					initialValues.tipoFamiliar === 'conjuge' &&
					values.tipoFamiliar === 'filho'
				) {
					dispatch(
						setUser({
							conjuge: undefined,
						}),
					)
				}
			},
		})

	// const handleGoBack = () => history.push(backTo || familyPath)
	const handleGoBack = () => history.goBack()

	const handleSubmit = async (values) => {
		try {
			if (!editingDisabled) {
				await submitForm(values)
				history.push(familyPath)
			} else {
				handleGoBack()
			}
		} catch (err) {
			console.log(err)
		}
	}

	if (error) console.log(error)

	if (loading)
		return (
			<MainLayout
				hasSideMenu
				onGoBack={() => history.push(backTo || familyPath)}
			>
				<Loading />
			</MainLayout>
		)

	return (
		<MainLayout hasSideMenu onGoBack={() => history.push(backTo || familyPath)}>
			<Form
				onSubmit={handleSubmit}
				initialValues={initialValues}
				subscription={{
					submitting: true,
					submitSucceeded: true,
					pristine: true,
					submitError: true,
				}}
				decorators={[tipoFamiliarHandler, dataNascimentoHandler, paisHandler]}
				mutators={{ setFieldData }}
				validate={() => ({})}
			>
				{({ handleSubmit, submitError, submitting }) => (
					<FormWrapper onSubmit={handleSubmit}>
						<FormBody>
							<Text dark>
								{editingDisabled ? (
									<FormInfo>
										Você já enviou seus dados de família e neste momento do
										processo eles não podem ser alterados
									</FormInfo>
								) : (
									<p>
										Preencha seus dados com muita atenção e não deixe de anexar
										a foto do documento oficial que comprove os dados
										preenchidos.
									</p>
								)}
							</Text>

							{/* VÍNCULO FAMILIAR */}
							<FieldWrapper>
								<SelectField
									name="tipoFamiliar"
									label="Vínculo do familiar"
									options={TIPOS_FAMILIAR}
									disabled={
										(initialValues?.tipoFamiliar !== 'conjuge' &&
											partnerFilledOrInexistant) ||
										editingDisabled
									}
									validators={[required]}
								/>
							</FieldWrapper>

							{/* NOME */}
							<FieldWrapper>
								<TextField
									name="nome"
									label="Nome completo"
									validators={[required, nameMustBeComplete, minLength(6)]}
									disabled={editingDisabled}
								/>
							</FieldWrapper>

							<FormRow template="1fr 1fr" disableTemplate={!isDesktop}>
								{/* DATA DE NASCIMENTO */}
								<FieldWrapper>
									<DateTextField
										name="dataNascimento"
										label="Data de Nascimento"
										validators={[required, dateBefore()]}
										disabled={editingDisabled}
									/>

									{/* <FieldValuesSpy name="dataNascimento">
                    {dataNascimento => dateMaxAge(60)(dataNascimento)}
                  </FieldValuesSpy> */}
								</FieldWrapper>

								{/* CPF */}
								<FieldWrapper>
									<CpfField
										name="cpf"
										label="CPF"
										disabled={editingDisabled}
										validators={[required]}
									/>
								</FieldWrapper>

								{/* PAÍS */}
								<FieldWrapper>
									<SelectField
										name="pais"
										label="País de nascimento"
										options={COUNTRIES}
										disabled={editingDisabled}
										validators={[required]}
									/>
								</FieldWrapper>

								{/* UF */}
								<FieldValuesSpy name="pais">
									{(pais) =>
										pais === 'BR' || !pais ? (
											<FieldWrapper>
												<SelectField
													name="uf"
													label="UF"
													options={UFS}
													disabled={!pais || editingDisabled}
													validators={[required]}
												/>
											</FieldWrapper>
										) : undefined
									}
								</FieldValuesSpy>

								{/* SEXO */}
								<FieldWrapper>
									<SelectField
										name="sexo"
										label="Sexo"
										options={SEXES}
										disabled={editingDisabled}
										validators={[required]}
									/>
								</FieldWrapper>
							</FormRow>

							{/* É DEPENDENTE */}
							<FieldWrapper>
								<CheckBoxField
									name="flagDependenteIR"
									label="Dependente para fins de imposto de renda"
									disabled={editingDisabled}
								/>
							</FieldWrapper>

							{/* DOCUMENTOS */}
							<FieldValuesSpy names={['tipoFamiliar', 'dataNascimento']}>
								{({ tipoFamiliar, dataNascimento }) =>
									tipoFamiliar === 'filho' ? (
										<FormSection label="Documentos">
											<FormTip>
												Certifique que os documentos estão legíveis, prefira
												locais com boa claridade e se possível utilize um
												aplicativo de scanner caso digitalize seu documento pelo
												celular.
											</FormTip>

											{/* CERTIDÃO NASCIMENTO */}
											<FieldSection label="Certidão de Nascimento">
												<FieldWrapper>
													<AttachmentField
														name="certidaoNascimento.idAnexo"
														type={`certidao_nascimento${
															id === 'novo' ? '' : `/${id}`
														}`}
														label="Foto da Certidão"
														disabled={editingDisabled}
														validators={[required]}
													/>
												</FieldWrapper>
											</FieldSection>

											{/* CADERNETA VACINAÇÃO */}
											{differenceInYears(
												new Date(),
												parse(dataNascimento, 'dd/MM/yyyy', new Date()),
											) <= 5 && (
												<FieldSection label="Caderneta de vacinação">
													<FieldWrapper>
														<AttachmentField
															name="cadernetaVacinacao.idAnexoIdentificacao"
															type={`caderneta_vacinacao_identificacao${
																id === 'novo' ? '' : `/${id}`
															}`}
															label="Página de identificação"
															disabled={editingDisabled}
															validators={[required]}
														/>
													</FieldWrapper>

													<FieldWrapper>
														<AttachmentField
															name="cadernetaVacinacao.idAnexoQuadroVacinal"
															type={`caderneta_vacinacao_quadro_vacinal${
																id === 'novo' ? '' : `/${id}`
															}`}
															label="Quadro Vacinal"
															disabled={editingDisabled}
															validators={[required]}
														/>
													</FieldWrapper>
												</FieldSection>
											)}
										</FormSection>
									) : undefined
								}
							</FieldValuesSpy>
						</FormBody>

						<FormFooter>
							{submitError && <FormError error={submitError} />}

							<Button type="submit" primary large submitting={submitting}>
								{editingDisabled ? (
									'Voltar'
								) : (
									<>
										{id === 'novo' ? 'Adicionar' : 'Salvar'}
										<FormIsntComplete> e continuar depois</FormIsntComplete>
									</>
								)}
							</Button>
						</FormFooter>
					</FormWrapper>
				)}
			</Form>
		</MainLayout>
	)
}

export default FamilyMemberPage
