import React from 'react'
import styled, { css } from 'styled-components'
import Header from './header'

const WelcomeLayout = ({ children, onGoBack, noPadding, center, ...props }) => {
	return (
		<Layout>
			<Header onGoBack={onGoBack} />
			<Main noPadding={noPadding}>
				<ContentWrapper center={center} {...props}>
					{children}
				</ContentWrapper>
			</Main>
			{/* <Footer>
        <LogoAmericanas />
      </Footer> */}
		</Layout>
	)
}

const Layout = styled.div`
	height: 100%;
	display: grid;
	grid-template-rows: 50px 1fr;
	grid-template-areas:
		'header'
		'main';
`

const Main = styled.main`
	padding-left: var(--site-h-padding);
	padding-right: var(--site-h-padding);
	grid-area: main;
	margin-bottom: calc(var(--spacing) * 2);
`

const ContentWrapper = styled.div`
	flex-grow: 1;
	height: 100%;
	display: flex;
	flex-direction: column;
	/* padding-top: calc(var(--spacing) * 1); */
	${(props) =>
		props.center &&
		css`
			align-items: center;
			justify-content: center;
		`}
`

// const Footer = styled.footer`
//   padding-left: var(--site-h-padding);
//   padding-right: var(--site-h-padding);
//   grid-area: footer;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `

export default WelcomeLayout
