import React from 'react'
import Svg from '~/components/svg'

function IconBack({ thick, ...props }) {
	if (thick) {
		return (
			<Svg viewBox="10 10 20 20" {...props}>
				<path d="M18.27 20.5l7.2-7.19a1.36 1.36 0 00-1.93-1.91l-8.14 8.14a1.35 1.35 0 00-.04 1.87l8.18 8.2a1.36 1.36 0 001.92-1.92z" />
			</Svg>
		)
	}

	return (
		<Svg viewBox="0 0 477 477" {...props}>
			<path d="M145 239L361 23c5-5 5-14 0-19s-14-5-19 0L116 229c-5 5-5 14 0 19l226 225c2 3 6 4 9 4s7-1 10-4c5-5 5-14 0-19L145 239z" />
		</Svg>
	)
}

export default IconBack
