import React from 'react'
import styled from 'styled-components'
import {
	LogoAme,
	LogoB2W,
	LogoIF,
	LogoAmericanas,
	LogoLets,
	LogoBancoTalentos,
} from '~/components'
import { mq } from '~/helpers/breakpoints'

const Header = ({ hideMenu, ...props }) => {
	return (
		<HeaderContainer {...props}>
			{process.env.REACT_APP_UNIVERSO_LASA === 'true' ? (
				<>
					<LogoAmericanas />
					<LogoIF />
					<LogoAme />
					<LogoB2W />
					<LogoLets />
				</>
			) : (
				<LogoBancoTalentos />
			)}
		</HeaderContainer>
	)
}

const HeaderContainer = styled.header`
	padding-left: calc(var(--site-h-padding) * 2);
	padding-right: calc(var(--site-h-padding) * 2);
	background: #e6e6e6;
	grid-area: header;
	z-index: 2;
	padding-top: 4px;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	height: 50px;

	color: #000;
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* gap: 0.8rem; */
	${mq.max('22.49em')} {
		font-size: 0.8em;
	}
	${mq.min('md')} {
		display: none;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 4px;
		background: var(--gradient);
	}
`

export default Header
