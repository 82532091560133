import styled from 'styled-components'
import clsx from 'clsx'

import { InputBtn } from '~/components'

export const Wrapper = styled.div.attrs((props) => ({
	className: clsx({
		disabled: props.disabled,
	}),
}))`
	background-color: #fff;
	border-radius: var(--border-radius) var(--border-radius) 0 0;
	border: 1px solid #efefef;
	border-bottom: 2px solid #12c97a;
	margin-bottom: 2rem;
	position: relative;
	/* box-shadow: 0 0 2px #0006; */
	font-size: 1.05rem;
	height: 6.2em;
	min-width: 100%;
	transition: all 0.4s ease;
	padding: 0.7rem;

	&::after {
		content: '';
		position: absolute;
		left: -1px;
		right: -1px;
		bottom: 0;
		height: var(--input-line-thickness);
		transition: all 0.4s ease;
		z-index: 1;
	}
`

export const Btns = styled.div`
	background: #fff;
	border-top-right-radius: 5px;
	position: absolute;
	right: 0;
	bottom: 0.5rem;

	${InputBtn} {
		height: 100%;
	}
`

export const ItemTypeVT = styled.div`
	font-weight: 600;
	font-size: 1.2em;
	line-height: 1em;
	margin: 0;
	color: #000;
`

export const ItemVT = styled.div`
	font-size: 0.875em;
	margin: 0.7rem 0;
	color: #707070;
`
