/* eslint-disable indent */
/* eslint-disable no-plusplus */
/* eslint-disable no-continue */
import { cloneDeep, get, set, update } from 'lodash'
import { format, isValid, parseISO, parse, formatISO } from 'date-fns'

// **** FORM FIELD CONVERTERS
export const composeConverters = (converters) => (values) =>
	converters.reduce((curValues, converter) => converter(curValues), values)

// dataNascimento: '1990-05-13'   ==>    dataNascimento: '13/05/1990'
// usa-se um parser pois prevê que a data pode vir em outros padrões iso, não somente YYYY-MM-DD
export const dateFieldsIsoToStr = (fields) => (values) => {
	if (!fields?.length) return values

	const newValues = cloneDeep(values)

	for (const f of fields) {
		// só funciona para campos [] com um array no caminho (ex.: []familia.docs[].certidaoNascimento.idAnexo)
		// (não funciona com []familia.docs[].certidaoNascimento.anexos[].id)
		if (f.indexOf('[]') === 0) {
			// se campo começa com [], é indicador de um campo de array
			const pathToArray = f.slice(2).replace(/\[\]\..*/, '')
			const array = get(newValues, pathToArray)
			if (!array?.length) continue
			const pathInsideArray = f.slice(f.slice(2).indexOf('[]') + 5)
			for (let i = 0; i < array.length; i++) {
				update(
					newValues,
					`${pathToArray}[${i}].${pathInsideArray}`,
					(oldValue) => {
						if (!oldValue) return

						const date = parseISO(oldValue)

						return isValid(date) ? format(date, 'dd/MM/yyyy') : oldValue
					},
				)
			}
		} else {
			const oldValue = get(values, f)
			const date = parseISO(oldValue)

			if (oldValue)
				set(newValues, f, isValid(date) ? format(date, 'dd/MM/yyyy') : oldValue)
		}
	}

	return newValues
}

// dataNascimento: '13/05/1990'   ==>    dataNascimento: '1990-05-13'
export const dateFieldsStrToIso = (fields) => (values) => {
	if (!fields?.length) return values

	const newValues = cloneDeep(values)

	for (const f of fields) {
		// só funciona para campos [] com um array no caminho (ex.: []familia.docs[].certidaoNascimento.idAnexo)
		// (não funciona com []familia.docs[].certidaoNascimento.anexos[].id)
		if (f.indexOf('[]') === 0) {
			// se campo começa com [], é indicador de um campo de array
			const pathToArray = f.slice(2).replace(/\[\]\..*/, '')
			const array = get(newValues, pathToArray)
			if (!array?.length) continue
			const pathInsideArray = f.slice(f.slice(2).indexOf('[]') + 5)
			for (let i = 0; i < array.length; i++) {
				update(
					newValues,
					`${pathToArray}[${i}].${pathInsideArray}`,
					(oldValue) => {
						if (!oldValue) return

						const date = parse(oldValue, 'dd/MM/yyyy', new Date())

						return isValid(date)
							? formatISO(date, { representation: 'date' })
							: oldValue
					},
				)
			}
		} else {
			const oldValue = get(values, f)
			const date = parse(oldValue, 'dd/MM/yyyy', new Date())

			if (oldValue)
				set(
					newValues,
					f,
					isValid(date)
						? formatISO(date, { representation: 'date' })
						: oldValue,
				)
		}
	}

	return newValues
}

// dataNascimento: '1990-05'   ==>    dataNascimento: '05/1990'
export const monthFieldsIsoToStr = (fields) => (values) => {
	if (!fields?.length) return values

	const newValues = cloneDeep(values)

	for (const f of fields) {
		const oldValue = get(values, f)
		const date = parse(oldValue, 'yyyy-MM', new Date())

		if (oldValue)
			set(
				newValues,
				f,
				isValid(date) ? format(date, 'MM/yyyy', new Date()) : oldValue,
			)
	}

	return newValues
}

// dataNascimento: '05/1990'   ==>    dataNascimento: '1990-05'
export const monthFieldsStrToIso = (fields) => (values) => {
	if (!fields?.length) return values

	const newValues = cloneDeep(values)

	for (const f of fields) {
		const oldValue = get(values, f)
		const date = parse(oldValue, 'MM/yyyy', new Date())

		if (oldValue)
			set(
				newValues,
				f,
				isValid(date) ? format(date, 'yyyy-MM', new Date()) : oldValue,
			)
	}

	return newValues
}

// idAnexo: '1872638-99273123-19238799-623918723'   ==>    idAnexo: { uuid: '1872638-99273123-19238799-623918723' }
export const attachmentUuidToObj = (fields) => (values) => {
	if (!fields?.length) return values

	const newValues = cloneDeep(values)

	for (const f of fields) {
		// só funciona para campos [] com um array no caminho (ex.: []familia.docs[].certidaoNascimento.idAnexo)
		// (não funciona com []familia.docs[].certidaoNascimento.anexos[].id)
		if (f.indexOf('[]') === 0) {
			// se campo começa com [], é indicador de um campo de array
			const pathToArray = f.slice(2).replace(/\[\]\..*/, '')
			const array = get(newValues, pathToArray)
			if (!array?.length) continue
			const pathInsideArray = f.slice(f.slice(2).indexOf('[]') + 5)
			for (let i = 0; i < array.length; i++) {
				update(
					newValues,
					`${pathToArray}[${i}].${pathInsideArray}`,
					(oldValue) => {
						if (!oldValue) return

						return {
							uuid: oldValue,
						}
					},
				)
			}
		} else {
			const oldValue = get(values, f)

			if (oldValue) {
				set(
					newValues,
					f,
					oldValue
						? {
								uuid: oldValue,
						  }
						: undefined,
				)
			}
		}
	}

	return newValues
}

// idAnexo: { uuid: '1872638-99273123-19238799-623918723' }   ==>    idAnexo: '1872638-99273123-19238799-623918723'
export const attachmentObjToUuid = (fields) => (values) => {
	if (!fields?.length) return values

	const newValues = cloneDeep(values)

	for (const f of fields) {
		// só funciona para campos [] com um array no caminho (ex.: []familia.docs[].certidaoNascimento.idAnexo)
		// (não funciona com []familia.docs[].certidaoNascimento.anexos[].id)
		if (f.indexOf('[]') === 0) {
			// se campo começa com [], é indicador de um campo de array
			const pathToArray = f.slice(2).replace(/\[\]\..*/, '')
			const array = get(newValues, pathToArray)
			if (!array?.length) continue
			const pathInsideArray = f.slice(f.slice(2).indexOf('[]') + 5)
			for (let i = 0; i < array.length; i++) {
				update(
					newValues,
					`${pathToArray}[${i}].${pathInsideArray}`,
					(oldValue) => {
						if (!oldValue) return

						return oldValue?.uuid
					},
				)
			}
		} else {
			const oldValue = get(values, f)

			if (oldValue) set(newValues, f, oldValue?.uuid)
		}
	}

	return newValues
}
