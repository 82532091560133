import React from 'react'
import styled from 'styled-components'

const FormSection = ({ label, children, ...props }) => (
	<section {...props}>
		<SectionTitle>{label}</SectionTitle>
		{children}
	</section>
)

const SectionTitle = styled.h1`
	font-size: 1.125rem;
	font-weight: 600;
	color: var(--color-text-dark);
	margin: 1.7em 0 1em;
`

export default FormSection
