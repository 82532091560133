/* eslint-disable indent */
import styled, { css } from 'styled-components'

const FormRow = styled.div`
	${({ disableTemplate, template }) =>
		!disableTemplate &&
		css`
			display: grid;
			grid-template-columns: ${Array.isArray(template)
				? template
						.map((l) => (l === 'auto' ? '1fr' : !isNaN(l) ? `${l * 100}%` : l))
						.join(' ')
				: template};
			column-gap: var(--spacing);
		`}
`

export default FormRow
