import React from 'react'
import Svg from '~/components/svg'

function IconCheckBox({ checked, ...props }) {
	return (
		<Svg viewBox="0 30 448 452" size={1.25} {...props}>
			{checked ? (
				<path d="M400 480H48c-27 0-48-21-48-48V80c0-27 21-48 48-48h352c27 0 48 21 48 48v352c0 27-21 48-48 48zm-205-98l184-184c7-6 7-16 0-23l-22-22c-7-7-17-7-23 0L184 303l-70-70c-6-7-16-7-23 0l-22 22c-7 7-7 17 0 23l104 104c6 6 16 6 22 0z" />
			) : (
				<path d="M400 32H48A48 48 0 000 80v352a48 48 0 0048 48h352a48 48 0 0048-48V80a48 48 0 00-48-48zm11.4 371.2c0 22.2-18 40.2-40.2 40.2H76.8c-22.2 0-40.2-18-40.2-40.2V108.8c0-22.2 18-40.2 40.2-40.2h294.5c22.2 0 40.2 18 40.2 40.2v294.4z" />
			)}
		</Svg>
	)
}

export default IconCheckBox
