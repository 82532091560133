import React from 'react'
import Svg from '~/components/svg'

function IconEye({ crossed, ...props }) {
	return !crossed ? (
		<Svg viewBox="0 0 576 512" {...props}>
			<path d="M573 241C518 136 411 64 288 64S58 136 3 241a32 32 0 000 30c55 105 162 177 285 177s230-72 285-177a32 32 0 000-30zM288 400a144 144 0 11144-144 144 144 0 01-144 144zm0-240a95 95 0 00-25 4 48 48 0 01-67 67 96 96 0 1092-71z" />
		</Svg>
	) : (
		<Svg viewBox="0 0 640 512" {...props}>
			<path d="M320 400c-76 0-137-59-143-133L72 186c-14 17-26 35-37 55a32 32 0 000 30 321 321 0 00363 167l-52-41a144 144 0 01-26 3zm314 58l-111-85a331 331 0 0082-102 32 32 0 000-30A321 321 0 00320 64a308 308 0 00-147 38L45 3a16 16 0 00-22 3L3 31a16 16 0 003 23l589 455a16 16 0 0022-3l20-25a16 16 0 00-3-23zM450 316l-39-30a95 95 0 005-30 95 95 0 00-121-92 48 48 0 019 28 47 47 0 01-2 10l-73-57a142 142 0 0191-33 144 144 0 01144 144c0 22-5 42-14 60z" />
		</Svg>
	)
}

export default IconEye
