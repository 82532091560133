import React from 'react'
import { nanoid } from 'nanoid'

import { themeColors } from '~/styles/cssVariables'

function Check(props) {
	const id = `id${nanoid()}`
	return (
		<svg viewBox="0 0 132 132" {...props}>
			<defs>
				<linearGradient
					id={id}
					x1=".5"
					x2=".5"
					y2="1"
					gradientUnits="objectBoundingBox"
				>
					{themeColors.map((c, i, arr) => (
						<stop
							key={`key-${i + c}`}
							offset={i / (arr.length - 1)}
							stopColor={c}
						/>
					))}
				</linearGradient>
			</defs>
			<path
				d="M40 54a5 5 0 00-7 0 5 5 0 00-1 7l26 27a5 5 0 003 2 5 5 0 004-2l63-63a5 5 0 000-7 5 5 0 00-7 0L61 78z"
				fill={`url(#${id})`}
			/>
			<path
				d="M127 61a5 5 0 00-5 5 56 56 0 01-56 56 56 56 0 01-56-56 56 56 0 0156-56 5 5 0 005-5 5 5 0 00-5-5A66 66 0 000 66a66 66 0 0066 66 66 66 0 0066-66 5 5 0 00-5-5z"
				fill={`url(#${id})`}
			/>
		</svg>
	)
}

export default Check
