/* eslint-disable no-shadow */
import React from 'react'
import createDecorator from 'final-form-calculate'
import setFieldData from 'final-form-set-field-data'
import { Form } from 'react-final-form'
// import queryString from 'query-string'
// import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import {
	AttachmentField,
	Button,
	DateTextField,
	FieldValuesSpy,
	FieldWrapper,
	FormBody,
	FormError,
	FormFooter,
	FormInfo,
	FormIsntComplete,
	FormRow,
	FormTip,
	FormWrapper,
	Loading,
	SelectField,
	Text,
	TextField,
} from '~/components'
import {
	COUNTRIES,
	ESCOLARIDADES,
	FORMACOES,
	TIPOS_INSTITUICAO,
} from '~/config/constants'
import history from '~/helpers/history'
import { dateBefore, minLength } from '~/helpers/validators'
import usePBVForm from '~/hooks/usePBVForm'
import MainLayout from '~/layouts/mainLayout'
// import { path as registrationPath } from '../registrationPage'
import ErrorPage from '../errorPage'
import { getViewPortSize } from '~/store/reducers/ui/selectors'
import { breakpointMatch } from '~/helpers/breakpoints'

export const title = 'Formação'
export const path = '/dados-cadastrais/formacao'

const escolaridadeHandler = (form) =>
	createDecorator({
		field: 'escolaridade',
		updates: (value) => {
			if (!form.getFieldState('escolaridade')?.modified) return {}

			const escolaridadeIndex = ESCOLARIDADES.findIndex((e) => e[0] === value)

			if (escolaridadeIndex < 2)
				return {
					tipoInstituicao: 'outra_instituicao',
					curso: undefined,
				}

			return {
				tipoInstituicao: undefined,
				curso: undefined,
			}
		},
	})(form)

const attachmentFields = ['comprovanteEscolaridade.idAnexo']
const monthFields = ['inicio', 'conclusao']

const defaultValues = {
	pais: 'BR',
}

const EducationPage = () => {
	// const { search } = useLocation()
	// const { backTo } = queryString.parse(search)

	const vp = useSelector(getViewPortSize)
	const { isDesktop } = breakpointMatch(vp)

	const { loading, error, initialValues, submitForm, editingDisabled } =
		usePBVForm('formacao', {
			attachmentFields,
			monthFields,
			defaultValues,
		})

	const handleGoBack = () => history.goBack()

	const handleSubmit = async (values) => {
		try {
			if (!editingDisabled) {
				await submitForm(values)
			} else {
				handleGoBack()
			}
		} catch (err) {
			console.log(err)
		}
	}

	if (loading)
		return (
			<MainLayout hasSideMenu onGoBack={handleGoBack}>
				<Loading />
			</MainLayout>
		)
	if (error) return <ErrorPage hasSideMenu onGoBack={handleGoBack} />

	return (
		<MainLayout hasSideMenu onGoBack={handleGoBack}>
			<Form
				onSubmit={handleSubmit}
				initialValues={initialValues}
				subscription={{
					submitting: true,
					submitSucceeded: true,
					pristine: true,
					submitError: true,
				}}
				decorators={[escolaridadeHandler]}
				mutators={{ setFieldData }}
			>
				{({ handleSubmit, submitError, submitting }) => (
					<FormWrapper onSubmit={handleSubmit}>
						<FormBody>
							<Text dark>
								{editingDisabled ? (
									<FormInfo>
										Você já enviou seus dados de formação e neste momento do
										processo eles não podem ser alterados
									</FormInfo>
								) : (
									<p>
										Preencha seus dados com muita atenção e não deixe de anexar
										a foto do documento oficial que comprove os dados
										preenchidos.
									</p>
								)}
							</Text>

							<FormRow template="1fr 1fr" disableTemplate={!isDesktop}>
								{/* ESCOLARIDADE */}
								<FieldWrapper>
									<SelectField
										name="escolaridade"
										label="Escolaridade"
										options={ESCOLARIDADES}
										disabled={editingDisabled}
									/>
								</FieldWrapper>

								{/* INSTITUIÇÃO */}
								<FieldWrapper>
									{/* TODO F: substituir por um select de faculdades? (quando escolaridade >= 8) lembrar que select deverá ter busca e possibilidade para inserir outro */}
									<TextField
										name="instituicao"
										label="Nome da Instituição"
										validators={[minLength(3)]}
										disabled={editingDisabled}
									/>
								</FieldWrapper>

								<FieldValuesSpy name="escolaridade">
									{(escolaridade) => {
										const escolaridadeIndex = ESCOLARIDADES.findIndex(
											(e) => e[0] === escolaridade,
										)
										return (
											<>
												{/* TIPO DE INSTITUIÇÃO */}
												<FieldWrapper shouldRender={!(escolaridadeIndex < 2)}>
													<SelectField
														name="tipoInstituicao"
														label="Tipo de Instituição"
														options={
															escolaridadeIndex < 4
																? TIPOS_INSTITUICAO.slice(0, 5)
																: TIPOS_INSTITUICAO.slice(5)
														}
														disabled={!escolaridade || editingDisabled}
														helperText="Opcional"
													/>
												</FieldWrapper>

												{/* CURSO/FORMAÇÃO */}
												<FieldWrapper shouldRender={!(escolaridadeIndex < 2)}>
													<SelectField
														name="formacao"
														label="Formação"
														showSearchByDefault
														sort
														options={
															escolaridadeIndex < 4
																? FORMACOES.slice(0, 13)
																: FORMACOES.slice(12, -1)
														}
														otherOption={[FORMACOES.slice(-1)]}
														disabled={editingDisabled}
														helperText="Opcional"
													/>
												</FieldWrapper>
											</>
										)
									}}
								</FieldValuesSpy>
							</FormRow>

							{/* PAÍS */}
							<FieldWrapper>
								<SelectField
									name="pais"
									label="País"
									showSearchByDefault
									options={COUNTRIES}
									disabled={editingDisabled}
								/>
							</FieldWrapper>

							<FormRow template="1fr 1fr" disableTemplate={!isDesktop}>
								{/* INÍCIO */}
								<FieldWrapper>
									<DateTextField
										hideDays
										name="inicio"
										label="Início"
										placeholder="mês/ano (ex.: 04/2010)"
										validators={[dateBefore('MM/yyyy')]}
										disabled={editingDisabled}
									/>
								</FieldWrapper>

								{/* CONCLUSÃO */}
								<FieldWrapper>
									<DateTextField
										hideDays
										name="conclusao"
										label="Conclusão"
										placeholder="mês/ano (ex.: 04/2010)"
										disabled={editingDisabled}
									/>
								</FieldWrapper>
							</FormRow>

							{/* COMPROVANTE DE ESCOLARIDADE */}
							<FieldWrapper>
								<AttachmentField
									name="comprovanteEscolaridade.idAnexo"
									type="comprovante_escolaridade"
									label="Comprovante de escolaridade"
									helperText="Diploma ou declaração de matrícula"
									disabled={editingDisabled}
								/>
								<FormTip>
									Deve estar carimbado e assinado ou com código de autenticação
									digital.
								</FormTip>
							</FieldWrapper>
						</FormBody>

						<FormFooter>
							{submitError && <FormError error={submitError} />}

							<Button type="submit" primary large submitting={submitting}>
								{editingDisabled ? (
									'Voltar'
								) : (
									<>
										Salvar
										<FormIsntComplete> e continuar depois</FormIsntComplete>
									</>
								)}
							</Button>
						</FormFooter>
					</FormWrapper>
				)}
			</Form>
		</MainLayout>
	)
}

export default EducationPage
