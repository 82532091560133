import React, { useState } from 'react'
import { Form } from 'react-final-form'
import createDecorator from 'final-form-calculate'
import { FaWhatsapp } from 'react-icons/fa'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { GoAlert } from 'react-icons/go'

import MainLayout from '~/layouts/mainLayout'
import {
	Button,
	CheckBoxField,
	EmailField,
	FieldWrapper,
	FormError,
	Modal,
	Text,
	TextField,
	PhoneField,
	CpfField,
	FormWrapper,
	FormBody,
	FormFooter,
	FieldValuesSpy,
	Loading,
	FormInfo,
	FormWarning,
} from '~/components'
import {
	dateBefore,
	nameMustBeComplete,
	pis,
	required,
} from '~/helpers/validators'

import usePBVForm from '~/hooks/usePBVForm'
import { formatCPF, formatPhone, formatPIS } from '~/helpers/formatters'
import DateTextField from '~/components/fields/dateTextField'
import history from '~/helpers/history'
import { path as homePath } from '../homePage'
import { setUser } from '~/store/reducers/user/actions'
import { STATUS_ESOCIAL } from '~/config/constants'

import ErrorPage from '../errorPage'
import { FieldTitle, FieldValue, FieldFlexBox } from './styles'

export const title = 'Pré-cadastro'
export const path = '/pre-cadastro'

// decorator para apagar o campo PIS ao selecionar checkbox "primeiro emprego"
const formDecorator = createDecorator({
	field: 'primeiroEmprego',
	updates: {
		pis: (primeiroEmpregoValue, values) =>
			primeiroEmpregoValue ? undefined : values.pis,
	},
})

const dateFields = ['dataNascimento']
const defaultValues = {
	preCadastroFinalizado: false,
}

const PreRegistrationPage = () => {
	const { search } = useLocation()
	const { backTo } = queryString.parse(search)

	const dispatch = useDispatch()

	const { loading, error, initialValues, submitForm, editingDisabled } =
		usePBVForm('preCadastro', {
			dateFields,
			defaultValues,
			afterSubmit: (values) => {
				// bota informações no estado geral para uso em regras de campos de outros formulários
				dispatch(
					setUser({
						dataNascimento: values.dataNascimento,
					}),
				)
			},
		})

	const handleGoBack = () => history.push(backTo || homePath)

	const [confirmation, setConfirmation] = useState({})

	const handleSave = (values) => {
		if (editingDisabled) handleGoBack()
		else setConfirmation(values)
	}

	const handleConfirmation = async (values) => {
		await submitForm(values)
		handleGoBack()
	}

	if (loading)
		return (
			<MainLayout hasSideMenu onGoBack={handleGoBack}>
				<Loading />
			</MainLayout>
		)

	if (error) return <ErrorPage hasSideMenu onGoBack={handleGoBack} />

	return (
		<MainLayout hasSideMenu onGoBack={handleGoBack}>
			<Form
				onSubmit={handleSave}
				initialValues={initialValues}
				subscription={{ submitting: true, pristine: true }}
				decorators={[formDecorator]}
				validate={(values) => ({
					nome: required(values.nome),
					dataNascimento: required(values.dataNascimento),
					celular: required(values.celular),
					pis:
						!values.primeiroEmprego && !values.pis
							? 'Campo obrigatório'
							: undefined,
				})}
			>
				{({ handleSubmit, submitError, submitting }) => (
					<FormWrapper onSubmit={handleSubmit}>
						<FormBody>
							<Text dark>
								{editingDisabled ? (
									<FormInfo>
										Seus dados de pré-cadastro já foram enviados ao eSocial e
										não podem ser alterados
									</FormInfo>
								) : (
									<p>
										Leia atentamente, confirme se seus dados estão corretos e
										informe o número de seu cadastro no PIS. Caso não possua,
										informe e iremos solicitar.
									</p>
								)}
							</Text>

							{initialValues?.validacaoEsocial && (
								<FormWarning highlight>
									<Text>
										{initialValues.validacaoEsocial?.erros && (
											<>
												<p>
													O eSocial validou seus dados de pré-cadastro e
													retornou com os seguintes erros. Verifique abaixo as
													recomendações
												</p>
												<ul>
													{initialValues.validacaoEsocial.erros.map((err) => (
														<li key={err}>{STATUS_ESOCIAL[err]}</li>
													))}
												</ul>
											</>
										)}

										{initialValues.validacaoEsocial?.orientacao && (
											<>
												<p>
													<strong style={{ color: '#000' }}>
														{Object.values(
															initialValues.validacaoEsocial.orientacao,
														).filter((v) => v).length === 1
															? 'Recomendação'
															: 'Recomendações'}{' '}
														do eSocial:
													</strong>
												</p>
												<ul>
													{Object.entries(
														initialValues.validacaoEsocial.orientacao,
													)
														.filter((v) => v[1])
														.map(([key, orientacao]) => (
															<li key={key}>{orientacao}</li>
														))}
												</ul>
											</>
										)}
									</Text>
								</FormWarning>
							)}

							<FieldWrapper>
								<CpfField
									name="cpf"
									label="CPF"
									formatter={formatCPF}
									forceErrorUntilChange={
										initialValues?.validacaoEsocial?.campos?.cpf
									}
									disabled
								/>
							</FieldWrapper>

							<AlertBox>
								<GoAlert
									size={24}
									color="EBC100"
									style={{ marginRight: '10px' }}
								/>
								<div>
									Atenção, preencha o campo de nome completo igual ao seu RG!
								</div>
							</AlertBox>

							<FieldWrapper>
								<TextField
									name="nome"
									label="Nome completo"
									validators={[nameMustBeComplete]}
									forceErrorUntilChange={
										initialValues?.validacaoEsocial?.campos?.nome
									}
									disabled={editingDisabled}
								/>
							</FieldWrapper>

							<FieldWrapper>
								<DateTextField
									name="dataNascimento"
									label="Data de Nascimento"
									validators={[dateBefore()]}
									disabled={editingDisabled}
									forceErrorUntilChange={
										initialValues?.validacaoEsocial?.campos?.dataNascimento
									}
								/>

								{/* <FieldValuesSpy name="dataNascimento">
                  {dataNascimento => dateMaxAge(60)(dataNascimento)}
                </FieldValuesSpy> */}
							</FieldWrapper>

							<FieldWrapper>
								<EmailField
									name="email"
									label="E-mail"
									forceErrorUntilChange={
										initialValues?.validacaoEsocial?.campos?.email
									}
									disabled
								/>
							</FieldWrapper>

							<FieldWrapper>
								<PhoneField
									mobile
									name="celular"
									label="Celular"
									forceErrorUntilChange={
										initialValues?.validacaoEsocial?.campos?.celular
									}
									disabled={editingDisabled}
								/>
								<FieldFlexBox>
									<CheckBoxField
										name="numeroWhatsapp"
										label="Este número também é whatsapp"
										disabled={editingDisabled}
									/>{' '}
									<FaWhatsapp className="whatsIcon" />
								</FieldFlexBox>
							</FieldWrapper>

							<FieldWrapper>
								<FieldValuesSpy name="primeiroEmprego">
									{(primeiroEmprego) => (
										<TextField
											name="pis"
											label="PIS"
											inputMode="numeric"
											validators={[pis]}
											formatter={formatPIS}
											parser={(value) => value.replace(/\D/g, '').slice(0, 11)}
											forceErrorUntilChange={
												initialValues?.validacaoEsocial?.campos?.pis
											}
											disabled={primeiroEmprego || editingDisabled}
										/>
									)}
								</FieldValuesSpy>
								<CheckBoxField
									name="primeiroEmprego"
									label="Primeiro emprego, não possuo PIS"
									disabled={editingDisabled}
								/>
							</FieldWrapper>
						</FormBody>

						<FormFooter>
							{submitError && <FormError error={submitError} />}

							<Button type="submit" primary large submitting={submitting}>
								{editingDisabled ? 'Voltar' : 'Salvar'}
							</Button>
						</FormFooter>
					</FormWrapper>
				)}
			</Form>

			<Modal
				isOpen={!isEmpty(confirmation)}
				onRequestClose={() => setConfirmation({})}
				renderHeader={() => <>Confirma que os dados abaixo estão corretos?</>}
			>
				<Form
					onSubmit={handleConfirmation}
					initialValues={confirmation}
					subscription={{ submitting: true, pristine: true, values: true }}
					validate={(values) => ({
						preCadastroFinalizado: values.preCadastroFinalizado
							? undefined
							: 'Campo obrigatório',
					})}
				>
					{({ handleSubmit, submitting, values }) => (
						<form
							onSubmit={handleSubmit}
							style={{
								width: '100%',
								height: '100%',
								display: 'flex',
								flexDirection: 'column',
								paddingTop: 'var(--spacing)',
							}}
						>
							<AlertBox style={{ textAlign: 'center' }}>
								<GoAlert
									size={12}
									color="EBC100"
									style={{ marginRight: '10px' }}
								/>
								<div>
									Atenção, verifique se o campo nome completo <br /> está igual
									ao seu RG!
								</div>
							</AlertBox>

							<FieldWrapper>
								<FieldTitle>Nome completo</FieldTitle>
								<FieldValue>{values?.nome}</FieldValue>
							</FieldWrapper>

							<FieldWrapper>
								<FieldTitle>CPF</FieldTitle>
								<FieldValue>{formatCPF(values?.cpf)}</FieldValue>
							</FieldWrapper>

							<FieldWrapper>
								<FieldTitle>Data de Nascimento</FieldTitle>
								<FieldValue>{values?.dataNascimento}</FieldValue>
							</FieldWrapper>

							<FieldWrapper>
								<FieldTitle>E-mail</FieldTitle>
								<FieldValue>{values?.email}</FieldValue>
							</FieldWrapper>

							<FieldWrapper>
								<FieldTitle>Celular</FieldTitle>
								<FieldValue>
									{formatPhone(values?.celular)}{' '}
									<small>
										{values?.numeroWhatsapp && (
											<FaWhatsapp size={16} className="whatsIcon" />
										)}
									</small>
								</FieldValue>
							</FieldWrapper>

							<FieldWrapper>
								<FieldTitle>PIS</FieldTitle>
								<FieldValue>
									{values?.primeiroEmprego
										? 'Primeiro emprego, não possuo PIS'
										: formatPIS(values?.pis)}
								</FieldValue>
							</FieldWrapper>

							<FieldWrapper>
								<CheckBoxField
									name="preCadastroFinalizado"
									label="Li e confirmo que meus dados estão corretos."
									autoFocus
								/>
							</FieldWrapper>

							<div
								style={{
									display: 'flex',
									justifyContent: 'space-evenly',
									gap: 'var(--spacing)',
									width: '100%',
								}}
							>
								<Button
									style={{ width: '6em' }}
									onClick={() => setConfirmation({})}
								>
									Editar
								</Button>
								<Button
									style={{ width: '6em' }}
									type="submit"
									primary
									submitting={submitting}
									disabled={!values.preCadastroFinalizado}
								>
									Enviar
								</Button>
							</div>
						</form>
					)}
				</Form>
			</Modal>
		</MainLayout>
	)
}

// const FieldTitle = styled.div`
//   font-weight: bold;
//   color: var(--color-text-dark);
//   font-size: .8em;
// `
// const FieldValue = styled.div`
//   color: var(--color-text-light);
//   font-size: .9em;
// `

const AlertBox = styled.div`
	width: 100%;
	height: 48px;
	background-color: #fff;
	border-radius: 7px 7px 0 0;
	border-bottom: 3px solid #ebc100;
	background-color: #fffbeb;
	margin: 10px auto;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.9em;

	@media (max-width: 500px) {
		flex-direction: column;
		height: 70px;

		div {
			font-size: 13px;
			text-align: center;
			margin-top: 5px;
		}
	}
`

export default PreRegistrationPage
