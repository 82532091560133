/* eslint-disable indent */
import * as t from './types'

const ui = (state = {}, action) => {
	switch (action.type) {
		case t.SET_APP_READY:
			return {
				...state,
				appReady: action.state,
			}
		case t.SET_VIEWPORT_SIZE:
			return {
				...state,
				viewPortSize: action.size,
			}
		case t.SET_CURRENT_PAGE:
			return {
				...state,
				currentPage: {
					title: action?.title ?? state?.currentPage?.title,
					path: action?.path ?? state?.currentPage?.path,
				},
			}
		default:
			return state
	}
}

export default ui
