import React from 'react'

import IconBack from '~/svgs/iconBack'
import history from '~/helpers/history'

import { BorderLessBtn } from './styles'

function BackButton({ thick, onClick, ...props }) {
	return (
		<BorderLessBtn onClick={onClick || (() => history.goBack())} {...props}>
			<IconBack size={1.3} thick={thick} />
		</BorderLessBtn>
	)
}

export default BackButton
