import React from 'react'

import { Text, Title } from '~/components'
import Accordion from '~/components/accordion'
import history from '~/helpers/history'
import MainLayout from '~/layouts/mainLayout'

import { path as homePath } from '../homePage'
import { AboutWrapper } from './styles'

export const title = 'Sobre o Portal Boas-vindas'
export const path = '/sobre'

const imagesList = [
	{
		url: 'https://www.freepik.com/vectors/clock',
		text: 'Clock vector created by freepik - www.freepik.com',
	},
	{
		url: 'https://www.freepik.com/vectors/background',
		text: 'Background vector created by starline - www.freepik.com',
	},
	{
		url: 'https://www.freepik.com/vectors/people',
		text: 'People vector created by stories - www.freepik.com',
	},
	{
		url: 'https://www.freepik.com/vectors/business',
		text: 'Business vector created by starline - www.freepik.com',
	},
	{
		url: 'https://www.freepik.com/vectors/website',
		text: 'Website vector created by starline - www.freepik.com',
	},
]

const AboutPage = () => {
	return (
		<MainLayout onGoBack={() => history.push(homePath)}>
			<AboutWrapper>
				<Title size="1.8rem">Sobre o Portal Boas-vindas</Title>
				<Text>
					<p>
						O Portal Boas-vindas, é o caminho por onde a Americanas SA vai
						promover toda a comunicação para a vaga que você está se
						candidatando.
					</p>
				</Text>

				<Title size="1.8rem">Sobre direitos autorais</Title>
				<Text>
					<p>
						O Cadastro Portal Boas-vindas, utilizou várias imagens de artistas
						diversos, todos do mesmo site e portanto, está indicando aqui cada
						obra e seus artistas criadores. Para maiores informações sobre isso,
						é possível consultar o site do Freepik, no site{' '}
						<a
							href="https://www.freepikcompany.com/copyright"
							target="_blank"
							rel="noopener noreferrer"
						>
							https://www.freepikcompany.com/copyright
						</a>
						, lá consta todas as informações sobre como e porque devemos dar
						créditos aos criadores das arte utilizada por nossa empresa. Abaixo
						segue a lista com todas as imagens e links.
					</p>
				</Text>
				<Accordion label="Imagens" open={false}>
					<ul className="images-list">
						{imagesList.map((item) => {
							return (
								<li key={item.url}>
									<a href={item.url} target="_blank" rel="noopener noreferrer">
										{item.text}
									</a>
								</li>
							)
						})}
					</ul>
				</Accordion>
			</AboutWrapper>
		</MainLayout>
	)
}

export default AboutPage
