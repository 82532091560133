import styled from 'styled-components'

import { Title } from '~/components'
import { mq } from '~/helpers/breakpoints'

export const AboutWrapper = styled.div`
	${mq.min('md')} {
		max-width: 700px;
		margin: 0 auto;
	}

	${Title} {
		color: #000;
		${mq.max('md')} {
			font-size: 1.2rem;
		}
	}

	a {
		text-decoration: none;
	}

	p a {
		font-weight: 600;
		font-style: italic;
		color: #00beff;
	}

	.images-list {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.images-list li {
		margin: 1.2rem 0;
	}

	.images-list li a {
		font-size: 0.8rem;
		font-style: italic;
		color: #00beff;
	}
`
