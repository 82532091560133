/* eslint-disable no-shadow */
import React, { useRef, useState } from 'react'
import queryString from 'query-string'
import { Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import { useLocation } from 'react-router'
import { useSelector } from 'react-redux'

import {
	Button,
	FieldWrapper,
	PassField,
	Text,
	Title,
	Link,
	FormError,
	FormFooter,
	FormWrapper,
	FormBody,
	EmailField,
} from '~/components'
import LoginLayout from '~/layouts/loginLayout'
import handleReqError from '~/helpers/handleReqError'
import { required } from '~/helpers/validators'
import api from '~/services/api'
import { getViewPortSize } from '~/store/reducers/ui/selectors'
import { breakpointMatch } from '~/helpers/breakpoints'

import { path as homePath } from '../homePage'
import { SITEKEY } from '../../config/constants'
import { StyledReaptcha } from './styles'

export const title = 'Login'
export const path = '/login'
export const isPublic = true

const LoginPage = () => {
	const { search } = useLocation()
	const { redirectTo } = queryString.parse(search)
	const vp = useSelector(getViewPortSize)
	const { isDesktop } = breakpointMatch(vp)

	const captcha = useRef(null)
	const [recaptchaResponseToken, setRecaptchaResponseToken] = useState(null)

	const onLoadRecaptcha = () => {
		if (captcha.current) {
			captcha.current.renderExplicitly()
		}
	}

	const handleSubmit = async (values) => {
		try {
			await api.authentication.login({
				values: {
					email: values.email,
					senha: values.senha,
					g_recaptcha_response: recaptchaResponseToken,
				},
			})

			window.location = redirectTo || homePath
		} catch (err) {
			captcha.current.reset()

			return {
				[FORM_ERROR]: handleReqError(err, {
					401: (msg) => msg,
					404: (msg) => msg,
				}),
			}
		}
	}

	const sitekey = SITEKEY.join('-')

	return (
		<LoginLayout>
			<Form
				onSubmit={handleSubmit}
				subscription={{ submitting: true, pristine: true, submitError: true }}
			>
				{({ handleSubmit, submitError, submitting }) => (
					<FormWrapper onSubmit={handleSubmit}>
						<FormBody style={{ padding: '0 1.25rem' }}>
							{/* TODO F: screen reader h1 Login */}
							<Text style={{ marginBottom: '2rem' }}>
								<Title size={isDesktop ? '1.3rem' : undefined}>Login</Title>
								<p>Informe seu e-mail e senha </p>
							</Text>

							<FieldWrapper>
								<EmailField
									name="email"
									label="E-mail"
									validators={[required]}
								/>
							</FieldWrapper>
							<FieldWrapper>
								<PassField name="senha" label="Senha" validators={[required]} />
							</FieldWrapper>

							<div style={{ textAlign: 'center' }}>
								<Link to="/recuperar-senha">Recuperar Senha</Link>
							</div>
						</FormBody>

						<FormFooter>
							<StyledReaptcha
								ref={(ref) => (captcha.current = ref)}
								sitekey={sitekey}
								hl="pt-BR"
								onLoad={onLoadRecaptcha}
								onVerify={(recaptchaToken) =>
									setRecaptchaResponseToken(recaptchaToken)
								}
								onExpire={() => setRecaptchaResponseToken(null)}
								explicit
							/>

							{submitError && <FormError error={submitError} />}

							<Button
								type="submit"
								primary
								large
								disabled={!recaptchaResponseToken}
								submitting={submitting}
							>
								Entrar
							</Button>
						</FormFooter>
					</FormWrapper>
				)}
			</Form>
		</LoginLayout>
	)
}

export default LoginPage
