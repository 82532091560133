/* eslint-disable indent */
import * as t from './types'

const user = (state = {}, action) => {
	switch (action.type) {
		case t.SET_USER:
			return {
				...state,
				...action.payload,
			}
		default:
			return state
	}
}

export default user
