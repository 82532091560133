import React from 'react'
import { formatDate } from '~/helpers/formatters'
import { date } from '~/helpers/validators'
import TextField from './textField'

function DateTextField({ name, validators, fieldProps, hideDays, ...props }) {
	validators = [
		date(hideDays ? 'MM/yyyy' : 'dd/MM/yyyy'),
		...(validators ?? []),
	]

	return (
		<TextField
			name={name}
			validators={validators}
			formatter={formatDate({ hideDays })}
			parser={(value) =>
				value.replace(/[^\d/]/g, '').slice(0, hideDays ? 7 : 10)
			}
			fieldProps={fieldProps}
			inputMode="numeric"
			{...props}
		/>
	)
}

export default DateTextField
