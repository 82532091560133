/* eslint-disable indent */
import React, { useRef, useState } from 'react'
import { Field } from 'react-final-form'
import styled from 'styled-components'
import {
	composeValidators,
	minLength,
	mustHaveLower,
	mustHaveNumber,
	mustHaveSpecial,
	mustHaveUpper,
} from '~/helpers/validators'
import IconEye from '~/svgs/iconEye'
import TextInput from '../inputs/textInput'
import useSetFieldData from '~/hooks/useSetFieldData'
import FieldInfo from '../fieldInfo'

// TODO F: ao clicar no olho, manter o campo selecionado
function PassField({
	name,
	newPass,
	validators,
	fieldProps,
	helperText,
	...props
}) {
	validators = [
		...(validators ?? []),
		...(newPass
			? [
					mustHaveUpper,
					mustHaveLower,
					mustHaveNumber,
					mustHaveSpecial,
					minLength(8),
			  ]
			: []),
	]

	props = {
		...(newPass && { autoComplete: 'new-password' }),
		...props,
	}

	const [passVisibibility, setPassVisibility] = useState(false)

	const inputRef = useRef(null)
	useSetFieldData(name, { inputRef, label: props.label })

	return (
		<Field
			name={name}
			validate={composeValidators(...validators)}
			{...fieldProps}
		>
			{({ input, meta }) => {
				const error =
					(input.value || meta.submitFailed) && meta.touched && meta.error
				return (
					<div>
						<InputWrapper>
							<TextInput
								type={passVisibibility ? `text` : `password`}
								{...input}
								{...props}
								error={
									(meta.dirty || meta.submitFailed) &&
									meta.touched &&
									meta.error
								}
								valid={input.value.length && meta.valid}
							/>
							<ButtonEye onClick={() => setPassVisibility((v) => !v)}>
								<IconEye crossed={passVisibibility} />
							</ButtonEye>
						</InputWrapper>
						<FieldInfo helperText={helperText} error={error} />
					</div>
				)
			}}
		</Field>
	)
}

const InputWrapper = styled.div`
	position: relative;
`

const ButtonEye = styled.button.attrs({
	type: 'button',
})`
	position: absolute;
	z-index: 1;
	right: 0;
	top: 0;
	bottom: 0;
	width: 3em;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
	color: var(--color-text-lighter);
	transition: all 0.2s ease;
	:hover,
	:focus {
		color: var(--color-text-light);
	}
	:active {
		transform: scale(0.9);
	}
	-webkit-tap-highlight-color: transparent;
	user-select: none;
`

export default PassField
