import React from 'react'
import { nanoid } from 'nanoid'
import Svg from '~/components/svg'
import { themeColors } from '~/styles/cssVariables'

function IconNameTag({ ...props }) {
	const id = `id${nanoid()}`

	return (
		<Svg viewBox="14 21.6 47 60" {...props}>
			<linearGradient
				id={id}
				x1="0.5"
				x2="1"
				y1="1"
				y2="0"
				gradientUnits="objectBoundingBox"
			>
				{themeColors.map((c, i, arr) => (
					<stop
						key={`key-${i + c}`}
						offset={i === arr.length - 1 ? 0.9 : i / (arr.length - 1)}
						stopColor={c}
					/>
				))}
			</linearGradient>
			<path
				d="M59.4 73.5a7.2 7.2 0 01-5.5 7.2l-1.9.2H22.4a7.2 7.2 0 01-7.5-7.4V29.9c0-2.4 1-4.7 2.9-6.2 1.3-1 2.8-1.5 4.5-1.5H52c3.4 0 6.4 2.3 7.2 5.6l.2 2zm-40.1-.2c0 2.1 1.1 3.2 3.2 3.2h29.3c1.9 0 3.1-1.2 3.1-3.1V29.7c0-1.9-1.2-3.1-3.1-3.1H22.4c-.6 0-1.1.1-1.5.3-1.1.6-1.6 1.4-1.6 2.9zm7.5-3.9c-.7-.6-.7-1.6-.6-2.2.4-4.8 2.8-8.2 7-9.8 3-1.2 6.3-1 9 .5 2.8 1.5 4.8 4.3 5.5 7.5.2.7.3 1.4.3 2.2 0 .8-.2 1.4-.6 1.9a3 3 0 01-1.9.7H28.9c-.6 0-1.5-.1-2.1-.8zm16.4-3.8a6.6 6.6 0 00-6.3-4.5c-2.7 0-5 1.9-5.9 4.5zM29.6 47.8a7.5 7.5 0 117.5 7.5 7.7 7.7 0 01-7.5-7.5zm4.6 0a3 3 0 002.9 3 3 3 0 003-3c0-.8-.3-1.5-.9-2.1-.6-.6-1.3-.9-2.1-.9-1.7.1-3 1.4-2.9 3zm-4.3-15.6c0-1 .7-2 1.7-2.1l.9-.1h9.2l.8.1c1 .2 1.8 1.1 1.8 2.1.1 1-.6 1.9-1.6 2.2l-1 .1h-9.2l-1-.1c-1-.3-1.7-1.2-1.6-2.2z"
				fill={props.color || `url(#${id})`}
			/>
		</Svg>
	)
}

export default IconNameTag
