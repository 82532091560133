export const formatOnlyText = (value) => {
	if (!value) return ''

	return value.replace(/[^a-z]/gi, '').toUpperCase()
}
export const formatOnlyNumbers = (value) => {
	if (!value) return ''

	return value.replace(/[^0-9]/gi, '')
}

export const formatGenericDoc = (value) => {
	if (!value) return ''

	return value.replace(/[^-a-z0-9/]/gi, '').toUpperCase()
}

export const formatCPF = (value) => {
	if (!value) return ''

	const x = value.replace(/\D/g, '')
	if (x.length <= 3) return x
	if (x.length <= 6) return `${x.slice(0, 3)}.${x.slice(3, 6)}`
	if (x.length <= 9) return `${x.slice(0, 3)}.${x.slice(3, 6)}.${x.slice(6, 9)}`
	return `${x.slice(0, 3)}.${x.slice(3, 6)}.${x.slice(6, 9)}-${x.slice(9, 11)}`
}

export const formatPIS = (value) => {
	if (!value) return ''

	const x = value.replace(/\D/g, '')
	if (x.length <= 3) return x
	if (x.length <= 8) return `${x.slice(0, 3)}.${x.slice(3, 8)}`
	if (x.length <= 10)
		return `${x.slice(0, 3)}.${x.slice(3, 8)}.${x.slice(8, 10)}`
	return `${x.slice(0, 3)}.${x.slice(3, 8)}.${x.slice(8, 10)}-${x.slice(
		10,
		11,
	)}`
}

export const formatDate = (options) => (value) => {
	if (!value) return ''

	if (options?.hideDays) {
		if (/^\d\d?(\/\d?\d?\d?\d?)?$/g.test(value)) return value

		const x = value.replace(/\D/g, '')
		if (x.length <= 2) return x
		if (x.length <= 4) return `${x.slice(0, 2)}/${x.slice(2, 4)}`
	} else {
		if (/^\d\d?(\/\d?\d?(\/\d?\d?\d?\d?)?)?$/g.test(value)) return value

		const x = value.replace(/\D/g, '')
		if (x.length <= 2) return x
		if (x.length <= 4) return `${x.slice(0, 2)}/${x.slice(2, 4)}`
		return `${x.slice(0, 2)}/${x.slice(2, 4)}/${x.slice(4, 8)}`
	}
}

export const formatPhone = (value) => {
	if (!value) return ''

	const x = value.replace(/\D/g, '') // .match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
	if (x.length <= 2) return x
	if (x.length <= 6) return `(${x.slice(0, 2)}) ${x.slice(2, 6)}`
	if (x.length <= 10)
		return `(${x.slice(0, 2)}) ${x.slice(2, 6)}-${x.slice(6, 10)}`
	return `(${x.slice(0, 2)}) ${x.slice(2, 7)}-${x.slice(7, 11)}`
}

export const formatCellPhone = (value) => {
	if (!value) return ''

	const x = value.replace(/\D/g, '') // .match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
	if (x.length <= 2) return x
	if (x.length <= 7) return `(${x.slice(0, 2)}) ${x.slice(2, 7)}`
	return `(${x.slice(0, 2)}) ${x.slice(2, 7)}-${x.slice(7, 11)}`
}

export const formatCEP = (value) => {
	if (typeof value === 'string' && /^\$error=/.test(value))
		value = value.split('&')?.[1]?.split('value=')?.join('')

	if (!value) return ''

	const x = value.replace(/\D/g, '')
	if (x.length <= 5) return x

	return `${x.slice(0, 5)}-${x.slice(5, 8)}`
}
