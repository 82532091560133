import React from 'react'
import { nanoid } from 'nanoid'
import Svg from '~/components/svg'
import { themeColors } from '~/styles/cssVariables'

function IconPersona(props) {
	const id = `id${nanoid()}`

	return (
		<Svg viewBox="0 0 14.45 18.36" {...props}>
			<linearGradient
				id={id}
				x1="0.5"
				x2="1"
				y1="1"
				y2="0"
				gradientUnits="objectBoundingBox"
			>
				{themeColors.map((c, i, arr) => (
					<stop
						key={`key-${i + c}`}
						offset={i === arr.length - 1 ? 0.9 : i / (arr.length - 1)}
						stopColor={c}
					/>
				))}
			</linearGradient>
			<path
				d="M12.33 11.1A7.17 7.17 0 007.23 9h-.01a7.17 7.17 0 00-5.1 2.12A7.17 7.17 0 000 16.2v1.63a.52.52 0 00.52.52h13.4a.52.52 0 00.53-.52v-1.63a7.17 7.17 0 00-2.12-5.1zm1.08 6.23H1.04V16.2a6.2 6.2 0 013.09-5.37 6.2 6.2 0 016.19 0 6.2 6.2 0 013.09 5.37zM7.12 7.87a3.94 3.94 0 003.94-3.93A3.94 3.94 0 007.12 0 3.94 3.94 0 003.2 3.94a3.95 3.95 0 003.93 3.93zm0-6.83a2.9 2.9 0 012.9 2.9 2.9 2.9 0 01-2.9 2.9 2.9 2.9 0 01-2.9-2.9 2.9 2.9 0 012.9-2.9z"
				fill={props.color || `url(#${id})`}
			/>
		</Svg>
	)
}

export default IconPersona
