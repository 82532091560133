import React from 'react'
import styled from 'styled-components'
import Svg from '~/components/svg'

function IconExclamation(props) {
	return (
		<Svg viewBox="0 1 18 16" {...props}>
			<path d="M7.6 13.6A1.4 1.4 0 019 12a1.4 1.4 0 011.4 1.4A1.4 1.4 0 019 15a1.4 1.4 0 01-1.4-1.4zm.7-3L7 4.5A1.2 1.2 0 018.2 3h1.6A1.2 1.2 0 0111 4.4l-1.3 6.3a.7.7 0 01-.7.5.7.7 0 01-.7-.5z" />
		</Svg>
	)
}

export default styled(IconExclamation)``
