import React, { useRef } from 'react'
import { Field } from 'react-final-form'
import styled from 'styled-components'

import { composeValidators } from '~/helpers/validators'
import useSetFieldData from '~/hooks/useSetFieldData'
import RadioInput from '../inputs/radioInput'

function RadioField({
	name,
	value,
	validators = [],
	isBoolean,
	fieldProps,
	...props
}) {
	const displayValueRef = useRef()
	useSetFieldData(name, { type: 'radio', displayValue: displayValueRef })

	return (
		<Field
			type="radio"
			name={name}
			value={value}
			{...fieldProps}
			validate={composeValidators(...validators)}
			format={isBoolean ? (bool) => (bool ? 'true' : 'false') : undefined}
			parse={isBoolean ? (val) => val === 'true' : undefined}
		>
			{({ input: { onChange, ...input } }) => {
				if (input.checked && displayValueRef.current === null) {
					displayValueRef.current = props.label
				}
				return (
					<Wrapper>
						<RadioInput
							onChange={(val) => {
								displayValueRef.current = props.label
								onChange(val)
							}}
							{...input}
							{...props}
						/>
					</Wrapper>
				)
			}}
		</Field>
	)
}

const Wrapper = styled.div`
	display: flex;
	margin: 0.5rem 0 0;

	:first-child:last-child {
		margin: 0.5rem 0;
	}
`

export default RadioField
