import React from 'react'

import MainLayout from '~/layouts/mainLayout'
import { Button, Error } from '~/components'
import history from '~/helpers/history'

import { path as homePath } from '../homePage'
import { Wrapper } from './styles'

export const title = 'Página não encontrada'
export const path = '*'

const ErrorPage = ({ notFound, onGoBack, ...props }) => {
	const handleGoBack = () => history.push(homePath)
	return (
		<MainLayout onGoBack={onGoBack || handleGoBack} {...props}>
			<Wrapper>
				<Error notFound={notFound} />
				<Button primary large onClick={handleGoBack}>
					Voltar para a home
				</Button>
			</Wrapper>
		</MainLayout>
	)
}

export default ErrorPage
