import React from 'react'
import styled from 'styled-components'

import { mq } from '~/helpers/breakpoints'
import { Text } from '~/components'

const DocsList = () => {
	return (
		<Text>
			<List>
				<li>
					<strong>RG</strong>
				</li>
				<li>
					<strong>CPF</strong>
				</li>
				<li>
					<strong>Comprovante de escolaridade</strong>
				</li>
				<li>
					<strong>PIS (Exceto primeiro emprego)</strong>
				</li>
				<li>
					<strong>Carteira de trabalho</strong>
				</li>
				<li>
					<strong>Título de eleitor</strong>
				</li>
				<li>
					<strong>Certificado de reservista</strong>
				</li>
				<li>
					<strong>Comprovante de residência</strong>
				</li>
				<li>
					<strong>Certidão de Nascimento dos dependentes</strong>
				</li>
				<li>
					<strong>Certidão de casamento</strong>
				</li>
				<li>
					<strong>CPF de todos os dependentes</strong>
				</li>
				<li>
					<strong>
						Cartão de Vacina para filhos com até 5 (cinco) anos de idade
					</strong>
				</li>
			</List>
		</Text>
	)
}

const List = styled.ul`
	${mq.max('md')} {
		li {
			padding: 0 0 0 25px;
			text-indent: -0.85rem;
		}
		li::before {
			margin-right: -0.15rem !important;
		}
	}
`

export default DocsList
