import styled, { css } from 'styled-components'
import { mq } from '~/helpers/breakpoints'
import Carousel from './carousel'

const Card = styled.div`
	background: #fff;
	padding: calc(var(--spacing)) calc(var(--spacing) * 0.85);
	border-radius: var(--border-radius);
	box-shadow: 0 0 3px 0 #00000033;
	line-height: 1.3;

	--title-size: 0.9rem;
	--title-color: #000;
	--title-weight: 500;

	${(props) =>
		props.center &&
		css`
			text-align: center;
		`}

	${Carousel} & {
		height: 100%;
	}

	${mq.desktop} {
		line-height: 1.5;
	}
`

export default Card
