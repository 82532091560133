// keep breakpoints in desc order
export const breakpoints = {
	xlg: '95em',
	lg: '80em',
	md: '64em',
	sm: '48em',
	xs: '0',
}

// for react / js | returns true/false
export const breakpointMatch = (bp) => ({
	isDesktop: ['md', 'lg', 'xlg'].includes(bp),
	isTablet: ['sm'].includes(bp),
	isMobile: ['xs'].includes(bp),
})

// for css
export const mq = {
	desktop: `@media (min-width: ${breakpoints.md})`,
	tablet: `@media (min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.md})`,
	mobile: `@media (max-width: ${breakpoints.sm})`,
	min: (bp) => `@media (min-width: ${breakpoints[bp] ?? bp})`,
	max: (bp) => `@media (max-width: ${breakpoints[bp] ?? bp})`,
	between: (ba, bb) =>
		`@media (min-width: ${breakpoints[ba] ?? ba}) and (max-width: ${
			breakpoints[bb] ?? bb
		})`,
}
