import styled from 'styled-components'

import { Button, Modal, Title, Text } from '~/components'
import { mq } from '~/helpers/breakpoints'

import TermsText from '../welcomePage/startForm/termsText'

export const AboutWrapper = styled.div`
	${mq.min('md')} {
		max-width: 700px;
		margin: 0 auto;
	}

	${Title} {
		color: #000;
		${mq.max('md')} {
			font-size: 1.2rem;
		}
	}

	${Text} {
		padding-bottom: 20px;
		margin-bottom: 40px;
		border-bottom: 1px solid #d8d8d8;
	}

	a {
		text-decoration: none;
	}

	p a {
		font-weight: 600;
		font-style: italic;
		color: #00beff;
	}
`

export const TextBox = styled(Text)`
	padding-bottom: 10px;
	margin-bottom: 20px !important;
	border: none !important;
`

export const FormWrap = styled.div`
	label {
		font-size: 1.1rem;
	}

	.itemChecks {
		margin: 5px 0;
	}

	.lastCheck {
		border-top: 1px solid #e6e6e6;
		margin: 30px 0 70px 0;
		padding-top: 15px;
	}
`

export const FormBox = styled.div`
	display: block;
	width: 100%;

	${Button} {
		margin: 0 !important;
	}
`

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 40px;

	${Button} {
		${mq.min('md')} {
			margin: 0 !important;
		}
	}
`
export const ButtonConfirmRemove = styled.div`
	display: flex;
	justify-content: center;

	${mq.min('md')} {
		justify-content: flex-start;
	}
`

export const StyledTermsText = styled(TermsText)`
	overflow: auto;
	padding: 0;
	height: unset;
	min-height: calc(100vh - var(--spacing) * 6 - 8.3rem);
	max-height: calc(100vh - var(--spacing) * 2 - 8.3rem);

	${mq.min('md')} {
		height: calc(100vh - var(--spacing) - 3rem);
		max-height: unset;
		padding: 40px;
		padding-left: 0;
	}
`

export const PrivacyModal = styled(Modal)`
	width: 100vw;
	max-width: 100vw;
	max-height: unset;
	height: 100%;
	box-shadow: none;
	border: 0;
	border-radius: 0;
	transform: none;
	top: 0;
	left: 0;
	padding-bottom: 0;

	.modal-close-btn {
		display: none;
	}
	.modal-body {
		display: flex;
		flex-direction: column;
		margin: 0 auto;
		max-width: 100%;
		${mq.min('md')} {
			width: 60em;
		}
		${mq.min('lg')} {
			width: 76em;
		}
		${mq.min('xlg')} {
			width: 90em;
		}
	}
`

export const TermsBody = styled.div`
	${Text} {
		margin: 40px 0 27px 0;
		font-weight: bold;
		font-family: Roboto;
		color: #000;
		font-size: 16px;
	}
`

export const TermsHeader = styled.div`
	border-bottom: ${(props) => (props.noLine ? 'none' : '1px solid #e6e6e6')};
	color: var(--color-text-dark);
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 3rem;
	margin-bottom: var(--spacing);

	${mq.min('md')} {
		font-size: 1.2em;
		margin-bottom: 0;
	}
`

export const CloseButton = styled.button.attrs({ type: 'button' })`
	cursor: pointer;
	padding: 1em 1em 0.5em 0.5em;
	font-size: 1rem;
	color: var(--close-button-color, #707070);
	transition: all 0.2s ease;
	z-index: 1;
	:hover {
		color: #444;
	}
`
