const keyPrefix = `redux_`
const keyPrefixPattern = new RegExp(`^${keyPrefix}`)

export const saveState = (state) => {
	localStorage.setItem('reduxState', JSON.stringify(state))
}

export const saveStateByKey = (state, reducers) => {
	// for every item in React state
	for (const key in state) {
		if (state.hasOwnProperty(key) && (!reducers || reducers.includes(key)))
			localStorage.setItem(keyPrefix + key, JSON.stringify(state[key]))
	}
}
export const loadStateByKey = (reducers) => {
	const state = {}
	for (const [key, value] of Object.entries(localStorage)) {
		if (keyPrefixPattern.test(key)) {
			const store = key.replace(keyPrefixPattern, '')
			if (!reducers || reducers.includes(store))
				state[store] = JSON.parse(value)
		}
	}
	return state
}
