import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getStages } from '~/store/reducers/stages/selectors'

export const useCheckStageActive = (path) => {
	const { pathname } = useLocation()
	const { currentStages } = useSelector(getStages)
	const [editingDisabled, setEditingDisabled] = useState(false)

	useEffect(() => {
		if (!currentStages) return

		const currentStage = currentStages
			?.flatMap((s) => s?.subEtapa || s)
			?.filter(
				(s) => s?.rota.indexOf((path || pathname).replace(/\/+$/, '')) > -1,
			)?.[0]
		if (!currentStage?.ativo || currentStage === undefined) {
			setEditingDisabled(true)
		} else if (currentStage?.ativo) {
			setEditingDisabled(false)
		}
	}, [currentStages, path, pathname])

	return { editingDisabled }
}
