/* eslint-disable indent */
import clsx from 'clsx'
import React from 'react'
import styled, { css } from 'styled-components'

import { TIPOS_FAMILIAR } from '~/config/constants'
import IconCheck from '~/svgs/iconCheck'
import IconExclamation from '~/svgs/iconExclamation'
import IconEye from '~/svgs/iconEye'
import IconPencil from '~/svgs/iconPencil'
import IconTrash from '~/svgs/iconTrash'

import InputBtn from './inputBtn'
import Svg from './svg'

function FamilyMember({ name, type, percent, onEdit, onDelete, ...props }) {
	return (
		<Wrapper iscomplete={percent === 1} {...props}>
			<Header>
				<Name>{name}</Name>
				<Type>{TIPOS_FAMILIAR.filter((t) => t[0] === type)?.[0]?.[1]}</Type>
			</Header>

			<Info>
				<Status>
					{percent === 1 ? (
						<>
							<IconCheck />
							Cadastro completo
						</>
					) : (
						<>
							<IconExclamation />
							Cadastro incompleto
						</>
					)}
				</Status>
				{props.disabled ? (
					<Btns>
						<InputBtn icon={IconEye} onClick={onEdit}>
							Revisar
						</InputBtn>
					</Btns>
				) : (
					<Btns>
						<InputBtn icon={IconPencil} onClick={onEdit}>
							Editar
						</InputBtn>
						<InputBtn icon={IconTrash} onClick={onDelete}>
							Excluir
						</InputBtn>
					</Btns>
				)}
			</Info>
		</Wrapper>
	)
}

const Wrapper = styled.div.attrs((props) => ({
	className: clsx({
		iscomplete: props.iscomplete,
		disabled: props.disabled,
	}),
}))`
	background-color: #fff;
	border-radius: var(--border-radius) var(--border-radius) 0 0;
	border: 1px solid #efefef;
	border-bottom: 0;
	position: relative;
	/* box-shadow: 0 0 2px #0006; */
	font-size: 1.05rem;
	height: 6.2em;
	min-width: 100%;
	transition: all 0.4s ease;

	&::after {
		content: '';
		position: absolute;
		left: -1px;
		right: -1px;
		bottom: 0;
		height: var(--input-line-thickness);
		transition: all 0.4s ease;
		z-index: 1;

		${({ iscomplete }) =>
			iscomplete
				? css`
						background: var(--color-success);
				  `
				: css`
						background: var(--color-error);
				  `}
	}
`

const Header = styled.div`
	height: 2.9em;
	padding: calc(var(--spacing) / 2) calc(var(--spacing) / 2) 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

const Name = styled.div`
	font-weight: 600;
	color: var(--color-text-dark);
`
const Type = styled.div`
	font-size: 0.875em;
`

const Info = styled.div`
	height: 3.3em;
	padding: calc(var(--spacing) / 2);
	position: relative;
	display: flex;
	align-items: center;
`
const Status = styled.div`
	font-size: 0.875em;
	color: var(--color-error);
	display: flex;
	align-items: center;

	${Wrapper}.iscomplete & {
		color: var(--color-success);
	}
	${Svg} {
		margin-right: 3px;
	}
`

const Btns = styled.div`
	height: 100%;
	background: #fff;
	border-top-right-radius: 5px;
	position: absolute;
	right: 0;
	top: 0;

	${InputBtn} {
		height: 100%;
	}
`

export default FamilyMember
