import React, { useRef } from 'react'
import { Transition } from 'react-transition-group'
import styled from 'styled-components'
import { FIELDWRAPPER_SHOULD_RENDER_TRANSITION_MS } from '~/config/constants'
import gsap from '~/helpers/gsap'

function AnimateHeight({ show, children }) {
	const wrapperRef = useRef(null)

	return (
		<Transition
			mountOnEnter
			unmountOnExit
			nodeRef={wrapperRef}
			in={Boolean(show)}
			addEndListener={(done) => {
				gsap.set(wrapperRef.current, {
					height: show ? 0 : 'auto',
				})
				gsap.to(wrapperRef.current, {
					duration: FIELDWRAPPER_SHOULD_RENDER_TRANSITION_MS / 1000,
					ease: 'none',
					height: show ? 'auto' : 0,
					onComplete: done,
				})
			}}
		>
			<Wrapper ref={wrapperRef}>{children}</Wrapper>
		</Transition>
	)
}

const Wrapper = styled.div`
	overflow: hidden;
`

export default AnimateHeight
