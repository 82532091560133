import * as t from './types'
import api from '~/services/api'

export const loadStages = () => {
	return async (dispatch) => {
		try {
			dispatch({
				type: t.LOAD_STAGES_REQUEST,
			})

			// const { data } = process.env.NODE_ENV === 'production' ? await api.etapas.get() : { data: [] };
			const { data } = await api.etapas.get()

			dispatch({
				type: t.LOAD_STAGES_SUCCESS,
				data,
			})

			return data
		} catch (err) {
			console.error('erro ao pegar etapas: ', err.response)

			dispatch({
				type: t.LOAD_STAGES_FAILURE,
				error: err,
			})
		}
	}
}
