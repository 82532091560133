import React, { useState } from 'react'
import toast from 'react-hot-toast'
import styled from 'styled-components'
import { FormError } from '~/components'
import Modal from '~/components/modal'
import { DEFAULT_REQUEST_ERROR_MSG } from '~/config/constants'
import errorCode from './errorCode'

export const handleReqError = (err, errorHandlers) => {
	if (!err || typeof err === 'string') return err

	if (!err?.response && err?.message) return err?.message

	console.error(err, err?.response)

	// ---- lida com o erro de acordo com os parâmetros enviados ----

	if (errorHandlers) {
		for (const resStatus in errorHandlers) {
			if (
				errorHandlers.hasOwnProperty(resStatus) &&
				Number(err?.response?.status) === Number(resStatus)
			) {
				if (typeof errorHandlers[resStatus] === 'function')
					return errorHandlers[resStatus](err?.response?.data?.message)

				return errorHandlers[resStatus]
			}
		}

		if (errorHandlers.default) {
			if (typeof errorHandlers.default === 'function')
				return errorHandlers.default(err?.response?.data?.message)

			return errorHandlers.default
		}
	}

	// se não há nenhuma mensagem (ou ação) de erro definida nos parâmetros, gera um código de erro ou pega o uuid no corpo da resposta
	const code = errorCode(err)

	toast.error(
		(t) => (
			<SevereErrorToast code={code} toastInstance={t}>
				{DEFAULT_REQUEST_ERROR_MSG}
			</SevereErrorToast>
		),
		{ id: JSON.stringify(err?.response?.data) },
	)

	return null
}

const SevereErrorToast = ({ code, toastInstance, children, ...props }) => {
	const [modalOpen, setModalOpen] = useState(false)
	const handleClick = () => setModalOpen(true)

	return (
		<>
			<ToastWrapper onClick={handleClick} {...props}>
				{children}
				<div className="small">Clique para mais detalhes</div>
			</ToastWrapper>

			<StyledModal
				isOpen={modalOpen}
				onRequestClose={() => {
					setModalOpen(false)
					toast.dismiss(toastInstance.id)
				}}
				renderHeader={() => 'Erro no servidor'}
				onAfterOpen={({ overlayRef }) => {
					overlayRef.current.style.zIndex = '999999'
				}}
			>
				<FormError
					error={{ message: DEFAULT_REQUEST_ERROR_MSG, code }}
					showImg
				/>
			</StyledModal>
		</>
	)
}

const StyledModal = styled(Modal)`
	width: 60vh;
`

const ToastWrapper = styled.div`
	cursor: pointer;

	.small {
		font-size: 0.7em;
		color: #aaa;
	}
`

export default handleReqError
