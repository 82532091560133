import React, { useImperativeHandle, useRef, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import toast from 'react-hot-toast'
import Slider from 'react-rangeslider'
import styled from 'styled-components'
import { mq } from '~/helpers/breakpoints'

const ImageEditor = ({ file }, ref) => {
	const [scale, setScale] = useState(1)
	const [minScale, setMinScale] = useState(1)
	const editorRef = useRef(null)

	useImperativeHandle(ref, () => ({
		save: async () => {
			// pega o canvas no tamanho 320x240 (definido nas props)
			const sourceCanvas = editorRef.current.getImageScaledToCanvas()

			// criando um novo canvas para gerar barras brancas nas laterais caso usuário redimensione imagem
			const newCanvas = document.createElement('canvas')
			newCanvas.width = 320
			newCanvas.height = 240
			const context = newCanvas.getContext('2d')
			context.fillStyle = '#fff'
			context.fillRect(0, 0, 320, 240)

			// no novo canvas branco, desenho o canvas original com a foto
			context.drawImage(sourceCanvas, 0, 0)

			const blob = await new Promise((resolve) => {
				newCanvas.toBlob((blobVal) => resolve(blobVal), 'image/jpeg')
			})

			return blob
		},
	}))

	const handleScale = (value) => {
		setScale(value)
	}

	return (
		<Container>
			<Wrapper>
				<StyledAvatarEditor
					ref={editorRef}
					image={file}
					width={320}
					height={240}
					border={36.36}
					color={[255, 255, 255, 0.6]} // RGBA
					backgroundColor="#fff"
					scale={scale}
					rotate={0}
					onLoadFailure={() => {
						toast.error(
							'Ocorreu um erro ao carregar a imagem. Favor tentar novamente',
						)
					}}
					onLoadSuccess={(imgInfo) => {
						setMinScale(240 / imgInfo.height)
					}}
				/>
				<PersonPlaceholder />
			</Wrapper>
			<StyledSlider
				value={scale}
				onChange={handleScale}
				min={minScale}
				max={2}
				step={0.001}
				tooltip={false}
				labels={{
					1: '-',
					2: '+',
				}}
			/>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: calc(0.8 * var(--spacing)) 0;
`

const Wrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
`

const StyledAvatarEditor = styled(AvatarEditor)`
	max-width: 100%;
	height: auto !important;

	${mq.min('md')} {
		width: auto !important;
		height: 220px !important;
		max-height: calc(100vh - var(--spacing) * 12);
	}
	${mq.min('lg')} {
		height: 330px !important;
	}
	${mq.min('xlg')} {
		height: 400px !important;
	}
`

const PersonPlaceholder = styled.div`
	background-image: url("data:image/svg+xml,%3Csvg viewBox='-50 -10 227.9 197.3' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cmask id='a'%3E%3Cpath fill='%23fff' d='M-50-10h227.9v197.3H-50z'/%3E%3Cpath d='M62.9 39.6c-15.4-.3-27.7 14-30.6 29.3-2.9 14 .1 28.8 4.9 42 2 6.9 9.3 10.8 8 18.6.8 8.4-6.2 13.7-12 17.7-10.1 7.1-24 6.1-32.9 15.7-6.5 6.2-7.4 16-10.3 24.3l147.9.1c-3.4-9.2-4.4-20.6-13-26.5-10.4-8.4-25.3-6.1-35.3-15.8-6.4-3.9-11.3-11.2-9-19.4 1.5-6.8 7.6-11.3 9.2-18.3 5.5-16.6 7.9-36.5-1.5-52A29.8 29.8 0 0063 39.5z'/%3E%3C/mask%3E%3C/defs%3E%3Cpath mask='url(%23a)' d='M-50-10h227.9v197.3H-50z'/%3E%3Cpath stroke='%23fff' fill='transparent' d='M62.9 39.6c-15.4-.3-27.7 14-30.6 29.3-2.9 14 .1 28.8 4.9 42 2 6.9 9.3 10.8 8 18.6.8 8.4-6.2 13.7-12 17.7-10.1 7.1-24 6.1-32.9 15.7-6.5 6.2-7.4 16-10.3 24.3l147.9.1c-3.4-9.2-4.4-20.6-13-26.5-10.4-8.4-25.3-6.1-35.3-15.8-6.4-3.9-11.3-11.2-9-19.4 1.5-6.8 7.6-11.3 9.2-18.3 5.5-16.6 7.9-36.5-1.5-52A29.8 29.8 0 0063 39.5z'/%3E%3C/svg%3E");
	background-size: auto 123%;
	background-repeat: no-repeat;
	background-position: bottom;
	pointer-events: none;

	position: absolute;
	top: 11.7%;
	bottom: 11.5%;
	right: 9.3%;
	left: 9.3%;
	opacity: 0.3;
`

const StyledSlider = styled(Slider)`
	margin: calc(1.4 * var(--spacing)) 0 calc(0.8 * var(--spacing));
	${mq.min('md')} {
		margin: calc(0.8 * var(--spacing)) 0 calc(1.4 * var(--spacing));
	}

	display: block;
	box-shadow: inset 0 1px 3px rgb(0 0 0 / 40%);
	height: 6px;
	width: 280px;
	max-width: calc(100% - 40px);
	border-radius: 10px;

	position: relative;
	background: #e6e6e6;
	touch-action: none;

	.rangeslider__fill {
		display: block;
		box-shadow: inset 0 1px 3px rgb(0 0 0 / 40%);
		height: 100%;
		background-color: #222;
		border-radius: 10px;
		top: 0;
	}

	.rangeslider__handle {
		width: 26px;
		height: 26px;
		${mq.min('md')} {
			width: 20px;
			height: 20px;
		}
		border-radius: 30px;
		top: 50%;
		transform: translate3d(-50%, -50%, 0);

		background: #fff;
		border: 1px solid #ccc;
		cursor: pointer;
		display: inline-block;
		position: absolute;
		/* box-shadow: 0 1px 3px rgb(0 0 0 / 40%), 0 -1px 3px rgb(0 0 0 / 40%); */
		box-shadow: 0 0px 2px 1px rgb(0 0 0 / 30%);
	}

	.rangeslider__labels {
		list-style: none;
		margin: 0 -20px;
		padding: 0;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0px;
		right: 0;
		display: flex;
		justify-content: space-between;
		pointer-events: none;
		z-index: -1;
	}
	.rangeslider__label-item {
		display: block;
		pointer-events: none;
		line-height: 0;

		&[data-value='1'] {
			font-size: 2em;
			transform: translateY(-1px);
			order: -1;
		}
		&[data-value='2'] {
			font-size: 1.6em;
			transform: translateY(-1px);
			order: 1;
		}
	}
`

export default React.forwardRef(ImageEditor)
