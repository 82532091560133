import styled from 'styled-components'
import { Modal, DueDateBox, DarkBox } from '~/components'
import { mq } from '~/helpers/breakpoints'

export const FormWrap = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	&& > * {
		margin-top: var(--spacing);
	}

	${mq.min('sm')} {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		&& > * {
			margin-top: 0;
		}
	}
`

export const PrivacyTermsModal = styled(Modal)`
	width: 100vw;
	max-width: 100vw;
	max-height: unset;
	height: 100%;
	box-shadow: none;
	border: 0;
	border-radius: 0;
	transform: none;
	top: 0;
	left: 0;
	padding-bottom: 0;

	.modal-close-btn {
		display: none;
	}
	.modal-body {
		display: flex;
		flex-direction: column;
		margin: 0 auto;
		max-width: 100%;
		${mq.min('md')} {
			width: 60em;
		}
		${mq.min('lg')} {
			width: 76em;
		}
		${mq.min('xlg')} {
			width: 90em;
		}
	}
`

export const TermsBody = styled.div`
	display: flex;
	flex-direction: column;
	height: calc(100% - var(--spacing) - 3rem);

	${mq.min('md')} {
		flex-direction: row;
		height: auto;
	}
`

export const TermsHeader = styled.div`
	border-bottom: ${(props) => (props.noLine ? 'none' : '1px solid #e6e6e6')};
	color: var(--color-text-dark);
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 3rem;
	margin-bottom: var(--spacing);

	${mq.min('md')} {
		font-size: 1.2em;
		margin-bottom: 0;
	}
`
export const TermsFooter = styled.div`
	padding: var(--spacing);
	display: flex;
	align-items: flex-end;
`

export const CloseButton = styled.button.attrs({ type: 'button' })`
	cursor: pointer;
	padding: 1em 1em 0.5em 0.5em;
	font-size: 1rem;
	color: var(--close-button-color, #707070);
	transition: all 0.2s ease;
	z-index: 1;
	:hover {
		color: #444;
	}
`

export const StyledDueDateBox = styled(DueDateBox)`
	margin-top: calc(var(--spacing) * 2);
`

export const WelcomeLayoutWrapper = styled.div`
	margin-top: var(--spacing);
	${mq.desktop} {
		height: 100%;
		display: grid;
		grid-template-columns: minmax(30vw, 750px) minmax(700px, 60vw);
		grid-template-areas: 'splash content';
	}
`

export const Greeting = styled.div`
	::before {
		content: '${(props) => props.say}';
		display: block;
		color: var(--color-text-light);
		font-size: 0.8rem;
		font-weight: bold;
		margin-bottom: 0.2em;
	}

	color: var(--color-text-dark);
	font-size: 1.7rem;
	margin: 0.7em ​0 0.5em;

	${mq.desktop} {
		display: none;
	}
`

export const Splash = styled.div`
	height: 195vw;
	margin: 0 calc(var(--site-h-padding) * -1);
	padding: 64vw 29vw 0;
	margin-bottom: -60vw;

	background-image: url('/assets/splash-lasa.svg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: calc(50% + 3vw) center;

	text-align: center;
	font-size: 1.3rem;
	font-size: min(max(1.3rem, 5.7vw), 2.5rem);
	font-size: clamp(1.3rem, 5.7vw, 2.5rem);
	line-height: 1.4;
	color: var(--color-text-dark);
	font-weight: bold;

	${mq.desktop} {
		grid-area: splash;
		height: 100%;
		min-height: 750px;
		padding: 260px 75% 0;
		padding: max(30vh, 260px) 75% 0;
		margin-bottom: 0;
		margin-left: calc(var(--site-h-padding) * -1 + -40%);
		margin-right: -40%;

		background-size: contain;
		background-position: calc(50% + 1.3vw) top;

		/* font-size: clamp(1.3rem,5.7vw,2.5rem); */
		font-size: 1.5rem;
		font-size: min(max(1rem, 1.7vw), 1.7rem);
		font-size: clamp(1rem, 1.7vw, 1.7rem);
	}
	${mq.min('lg')} {
		padding: 260px 79% 0;
		padding: max(30vh, 260px) 81% 0;
	}
	${mq.min('90em')} {
		padding: 260px 81% 0;
	}
`

export const Content = styled.div`
	${mq.desktop} {
		grid-area: content;
	}
`

export const StyledDarkBox = styled(DarkBox)`
	::after {
		height: calc(100% - var(--spacing) - 25px);
	}
	${mq.desktop} {
		font-size: 0.9em;
		line-height: 1.4;
	}
`
