import React from 'react'

import Svg from '~/components/svg'

function IconArrow({ dir = 'right', ...props }) {
	const rotateAngle = {
		top: '-90deg',
		right: '0',
		bottom: '90deg',
		left: '180deg',
	}

	return (
		<Svg
			viewBox="0 0 512 512"
			{...props}
			transformCss={`rotate(${rotateAngle[dir]})`}
		>
			<path d="M294 256L167 129c-9-9-9-25 0-34s25-9 34 0l144 144c9 9 9 24 1 33L201 417a24 24 0 01-34-34l127-127z" />
		</Svg>
	)
}

export default IconArrow
