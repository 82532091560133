/* eslint-disable indent */
import toast from 'react-hot-toast'
import * as t from './types'
import api from '~/services/api'
import { setAppReady } from '../ui/actions'
import history from '~/helpers/history'
// import { path as welcomePath } from '~/pages/welcomePage';
// import { path as loginPath } from '~/pages/loginPage';
// import { path as recoveryPath } from '~/pages/recoveryPage';
// import { path as resetPassPath } from '~/pages/resetPassPage';
import { loadStages } from '../stages/actions'

const welcomePath = '/bem-vindo'
const loginPath = '/login'
const recoveryPath = '/recuperar-senha'
const resetPassPath = '/redefinir-senha'

export const logout = ({ redirect } = {}) => {
	return async () => {
		try {
			await api.authentication.logout()

			// não é necessário dar dispatch no logout pois a api já apaga o cookie de autenticação (único dado armazenado)
			window.location = `${loginPath}${
				redirect && window.location.pathname !== loginPath
					? `?redirectTo=${window.location.pathname}`
					: ''
			}`
		} catch (err) {
			console.error(err)
			toast.error('Erro ao sair do sistema. Tente novamente')
		}
	}
}

export const loadUser = () => {
	return async (dispatch) => {
		try {
			const { data: userData } =
				process.env.NODE_ENV === 'production'
					? await api.user.get()
					: {
							data: {
								nome: 'Usuário Mockado',
								email: 'usuariomockado@gmail.com',
								dataLimite: '2072-04-30T03:09:30.000Z',
								iniciou: true,
								aceiteTermoPrivacidade: true,
								dataNascimento: '1990-05-24T20:05:00.000Z',
								sexo: 'masculino',
								estadoCivil: 'casado',
							},
					  }

			if (!userData) {
				dispatch(logout())
				return false
			}

			dispatch({
				type: t.SET_USER,
				payload: userData,
			})

			if (
				![welcomePath, loginPath, recoveryPath, resetPassPath].includes(
					history.location.pathname,
				) &&
				(!userData.iniciou || !userData.aceiteTermoPrivacidade)
			)
				history.replace(welcomePath)

			dispatch(setAppReady())
			dispatch(loadStages())
		} catch (err) {
			console.error('erro ao logar: ', err)
			toast.error('Erro ao carregar dados do usuário')
			dispatch(logout())
		}
	}
}

export const setUser = ({ ...values }) => ({
	type: t.SET_USER,
	payload: {
		...values,
	},
})

export const setUserPrivacyTermsAccepted = () => {
	return async (dispatch) => {
		try {
			await api.user.acceptPrivacyTerms()

			await dispatch(loadUser())
		} catch (err) {
			console.error(
				'erro ao setar política de privacidade aceita no usuário: ',
				err,
			)
			throw err
		}
	}
}

export const setUserStarted = () => {
	return async (dispatch) => {
		try {
			await api.user.start()

			await dispatch(loadUser())
		} catch (err) {
			console.error('erro ao setar usuário como iniciado: ', err)
		}
	}
}
