import React from 'react'
import { email } from '~/helpers/validators'
import TextField from './textField'

function EmailField({ name, validators, fieldProps, ...props }) {
	validators = [...(validators ?? []), email]

	return (
		<TextField
			name={name}
			validators={validators}
			fieldProps={fieldProps}
			inputMode="email"
			{...props}
		/>
	)
}

export default EmailField
