import React from 'react'
import styled from 'styled-components'

const InputBtn = ({ icon: Icon, children, ...props }) => {
	return (
		<Wrapper {...props}>
			<div>
				{Icon ? <Icon size={2} color="#000" /> : undefined}
				{children}
			</div>
		</Wrapper>
	)
}

const Wrapper = styled.button.attrs({
	type: 'button',
})`
	font-size: 0.65em;
	padding: 0 1em;
	position: relative;
	cursor: pointer;

	::before {
		content: '';
		position: absolute;
		width: 1px;
		height: calc(100% - 2em);
		top: calc(1em - 1px);
		left: 0;
		background: #efefef;
	}

	& > div {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 3px;
		transition: all 0.15s ease;
	}

	:hover > div {
		transform: scale(1.1);
		color: #555;
	}
	:focus-visible > div {
		transform: scale(1.1);
		color: #555;
	}
	:active > div {
		transform: scale(0.95);
		color: #555;
	}
`

export default styled(InputBtn)``
