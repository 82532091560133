import React from 'react'
import styled, { css } from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { breakpointMatch, mq } from '~/helpers/breakpoints'
import Header from './header'
import { Button, Card, DarkBox, DueDateBox, Title } from '~/components'
import { path as faqPath } from '~/pages/faqPage'
import { getViewPortSize } from '~/store/reducers/ui/selectors'
import SideMenu from './sideMenu'

const MainLayout = ({
	children,
	hasSideMenu,
	onGoBack,
	noPadding,
	center,
	hideMenu,
	// eslint-disable-next-line no-unused-vars
	...props
}) => {
	const { pathname } = useLocation()
	const vp = useSelector(getViewPortSize)
	const { isDesktop } = breakpointMatch(vp)

	return (
		<Layout>
			<Header onGoBack={onGoBack} hideMenu={hideMenu} />
			<Main noPadding={noPadding}>
				<ContentWrapper center={center}>
					{hasSideMenu && isDesktop && <SideMenu active />}
					<Content>{children}</Content>
					{(pathname === '/' || isDesktop) && (
						<>
							<StyledDarkBox>
								<Title>E se surgirem dúvidas?</Title>
								<StyledCard>
									Em caso de dúvidas, preparamos uma página para te ajudar.
									<StyledButton to={hideMenu ? `${faqPath}?ns=0` : faqPath}>
										Perguntas Frequentes
									</StyledButton>
								</StyledCard>
							</StyledDarkBox>
							<StyledDueDateBox />
						</>
					)}
				</ContentWrapper>
			</Main>
		</Layout>
	)
}

const Layout = styled.div`
	--header-height: 50px;
	${mq.min('md')} {
		--header-height: 120px;
	}

	height: 100%;
	display: grid;
	grid-template-rows: var(--header-height) 1fr;
	grid-template-areas:
		'header'
		'main';
`

const Main = styled.main`
	grid-area: main;
	padding-left: var(--site-h-padding);
	padding-right: var(--site-h-padding);
	margin-bottom: calc(var(--spacing) * 2);

	${mq.min('md')} {
		padding-left: 0;
		padding-right: 0;
		margin-bottom: 0;
	}
`

const ContentWrapper = styled.div`
	flex-grow: 1;
	/* height: 100%; */
	display: flex;
	flex-direction: column;

	max-width: 40em;
	margin: 0 auto;
	/* padding-top: calc(var(--spacing) * 1); */
	${(props) =>
		props.center &&
		css`
			align-items: center;
			justify-content: center;
		`}

	${mq.min('md')} {
		max-width: none;
		margin: 0;
		display: grid;
		grid-template-columns: min-content 1fr max(min(365px, 30%), 300px);
		grid-template-rows:
			max(
				min(
					calc((100vh - var(--header-height)) * 0.63),
					calc((100vh - var(--header-height) - 232px))
				),
				220px
			)
			max(calc((100vh - var(--header-height)) * 0.37), 232px);
		grid-template-areas:
			'sidemenu content dueDate'
			'sidemenu content faq';
	}

	${mq.min('xlg')} {
		grid-template-columns: min-content 1fr max(min(400px, 30%), 300px);
	}
`

const Content = styled.div`
	grid-area: content;
	position: relative;
	flex: 1;
	width: 100%;

	padding-top: calc(var(--spacing) * 1);

	${mq.min('md')} {
		height: 100%;
		padding: calc(var(--spacing) * 2);
		overflow: auto;
	}
`

const StyledDueDateBox = styled(DueDateBox)`
	grid-area: dueDate;

	margin-top: calc(var(--spacing) * 1.5);

	${mq.min('md')} {
		margin: 0;
		height: 100%;
		flex-direction: column;
		padding: calc(var(--spacing) * 2);

		::before {
			display: none;
		}

		h1 {
			margin-bottom: 0.6em;
		}
		p {
			margin: 0.6em 0;
		}

		img {
			display: none;

			@media (min-height: 38.5em) {
				display: block;
				width: 40%;
				align-self: center;
			}
			@media (min-height: 42.5em) {
				position: static;
				width: 55%;
			}
			@media (min-height: 46em) {
				width: 85%;
			}
		}
	}

	${mq.min('lg')} {
		padding: calc(var(--spacing) * 3);
		@media (min-height: 38.5em) {
			padding: calc(var(--spacing) * 2);
		}
		@media (min-height: 46em) {
			padding: calc(var(--spacing) * 3);
		}
	}
`

const StyledDarkBox = styled(DarkBox)`
	grid-area: faq;

	--title-size: 0.8rem;
	--title-weight: 500;

	${mq.min('md')} {
		--title-size: 1rem;

		height: 100%;
		margin: 0;
		padding: calc(var(--spacing) * 1.5);
		padding-bottom: 0;
		display: flex;
		flex-direction: column;

		::after {
			height: 100%;
			background: rgba(0, 0, 0, 0.9);
		}
	}
`

const StyledCard = styled(Card)`
	padding: calc(var(--spacing) * 1.25) calc(var(--spacing) * 1);
	text-align: center;
	margin-top: calc(var(--spacing) * 0.5);
	display: flex;
	flex-direction: column;

	${mq.min('md')} {
		flex: 1;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		justify-content: center;
	}
`

const StyledButton = styled(Button)`
	display: flex;
	margin-top: var(--spacing);
	min-width: 90%;
	align-self: center;
`

export default MainLayout
