/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react'
import setFieldData from 'final-form-set-field-data'
import { Field, Form } from 'react-final-form'
// import queryString from 'query-string'
// import { useLocation } from 'react-router-dom'
import toast from 'react-hot-toast'
import arrayMutators from 'final-form-arrays'
import {
	Button,
	FormBody,
	FormFooter,
	FormWrapper,
	Loading,
	Text,
	FieldWrapper,
	FormInfo,
	RadioFieldGroup,
	RadioField,
	FormTip,
	FieldValuesSpy,
	SelectInput,
	NumberInput,
	Modal,
	InputBtn,
} from '~/components'
import history from '~/helpers/history'
import MainLayout from '~/layouts/mainLayout'
import usePBVForm from '~/hooks/usePBVForm'
// import { path as complementaryPath } from '../complementaryPage'
import IconPencil from '~/svgs/iconPencil'
import IconTrash from '~/svgs/iconTrash'
import ErrorPage from '../errorPage'

import { Btns, ItemTypeVT, ItemVT, Wrapper } from './styles'

export const title = 'Vale-transporte'
export const path = '/acoes-complementares/vale-transporte'

const VtPage = () => {
	// const { search } = useLocation()
	// const { backTo } = queryString.parse(search)

	const { loading, error, initialValues, submitForm, editingDisabled } =
		usePBVForm(`valeTransporte`)

	const [currentItem, setCurrentItem] = useState({})
	const [getValeTransporte, setGetValeTransporte] = useState(null)

	const [currentIndexItem, setCurrentIndexItem] = useState(null)
	const [itemFormOpen, setItemFormOpen] = useState(false)

	useEffect(() => {
		if (
			initialValues !== undefined &&
			initialValues?.flagValeTransporte !== null &&
			initialValues?.flagValeTransporte !== undefined
		) {
			setGetValeTransporte(() =>
				initialValues?.flagValeTransporte === true ? 'sim' : 'nao',
			)
		}
	}, [initialValues])

	const handleGoBack = () => history.goBack()

	const handleSubmit = async (values) => {
		try {
			if (!editingDisabled) {
				await submitForm(values)
			} else {
				handleGoBack()
			}
		} catch (err) {
			console.log(err)
		}
	}

	const optionsTipoTransporte = [
		['metro', 'Metrô'],
		['onibus', 'Ônibus'],
		['trem', 'Trem'],
		['barca', 'Barca'],
	]

	const handleSaveVT = (form) => () => {
		if (
			!(
				currentItem.tipoTransporte &&
				currentItem.quantidade &&
				currentItem.valor
			)
		) {
			toast.error('Você deve preencher todos os campos')
			return null
		}

		if (currentIndexItem !== null) {
			form.mutators.update('docs', currentIndexItem, currentItem)
		} else {
			form.mutators.push('docs', currentItem)
		}
		setCurrentIndexItem(null)
		setItemFormOpen(false)
		setCurrentItem({})
	}

	const handleEditVT = (index, item) => () => {
		setCurrentIndexItem(index)
		setItemFormOpen(true)
		setCurrentItem(item)
	}

	const handleCloseModal = () => {
		setCurrentIndexItem(null)
		setItemFormOpen(false)
		setCurrentItem({})
	}

	function currencyFormatter(value) {
		if (!Number(value)) return ''

		const amount = new Intl.NumberFormat('pt-BR', {
			style: 'currency',
			currency: 'BRL',
		}).format(value / 100)

		return amount
	}

	const isGetVTCheckedYes = getValeTransporte
		? getValeTransporte === 'sim'
			? { checked: true }
			: { checked: false }
		: ''
	const isGetVTCheckedNo = getValeTransporte
		? getValeTransporte === 'nao'
			? { checked: true }
			: { checked: false }
		: ''

	if (loading)
		return (
			<MainLayout hasSideMenu onGoBack={handleGoBack}>
				<Loading />
			</MainLayout>
		)

	if (error) return <ErrorPage hasSideMenu onGoBack={handleGoBack} />

	return (
		<MainLayout hasSideMenu onGoBack={handleGoBack}>
			<Form
				onSubmit={handleSubmit}
				initialValues={initialValues}
				subscription={{
					submitting: true,
					submitSucceeded: true,
					pristine: true,
					submitError: true,
				}}
				mutators={{ setFieldData, ...arrayMutators }}
			>
				{({ handleSubmit, submitting, form }) => (
					<>
						<FormWrapper onSubmit={handleSubmit}>
							<FormBody>
								<Text dark>
									{editingDisabled ? (
										<FormInfo>
											Você já enviou seus dados de vale transporte
										</FormInfo>
									) : (
										<p>Preencha seus dados com muita atenção.</p>
									)}
								</Text>

								<FieldWrapper>
									<RadioFieldGroup
										inline
										label="Deseja receber Vale Transporte?"
									>
										<RadioField
											name="flagValeTransporte"
											value
											onClick={() => setGetValeTransporte('sim')}
											{...isGetVTCheckedYes}
											label="Sim"
											isBoolean
											disabled={editingDisabled}
										/>
										<RadioField
											name="flagValeTransporte"
											value={false}
											onClick={() => setGetValeTransporte('nao')}
											{...isGetVTCheckedNo}
											label="Não"
											isBoolean
											disabled={editingDisabled}
										/>
									</RadioFieldGroup>

									<FieldValuesSpy name="flagValeTransporte">
										{(flagValeTransporte) =>
											flagValeTransporte || (
												<FormTip>
													O benefício VALE TRANSPORTE é um direito instituído em
													lei. Havendo disponibilidade de vaga para contratação,
													precisamos nos programar para compra do benefício.
													Favor informar no formulário abaixo, com base no
													deslocamento CASA X Loja onde participa do processo
													seletivo, os itens: meio de transporte, quantidade
													diária no trajeto de ida e volta e o valor unitário do
													transporte.
												</FormTip>
											)
										}
									</FieldValuesSpy>
								</FieldWrapper>

								<FieldValuesSpy name="flagValeTransporte">
									{(flagValeTransporte) =>
										flagValeTransporte && (
											<>
												{/* listagem dos transportes */}
												<Field name="docs">
													{({ input: { value } }) => (
														<>
															{value?.map &&
																value.map((item, index) => (
																	<VTCard
																		key={item}
																		item={item}
																		onEdit={handleEditVT(index, item)}
																		onDelete={() =>
																			form.mutators.remove('docs', index)
																		}
																	/>
																))}
															{!editingDisabled && (
																<Button
																	large
																	onClick={() => setItemFormOpen(true)}
																>
																	{value?.length
																		? 'Adicionar outro'
																		: 'Adicionar'}
																</Button>
															)}
														</>
													)}
												</Field>
											</>
										)
									}
								</FieldValuesSpy>
							</FormBody>

							<FormFooter style={{ marginTop: '22px' }}>
								<Button type="submit" primary large submitting={submitting}>
									{editingDisabled ? 'Voltar' : 'Salvar'}
								</Button>
							</FormFooter>
						</FormWrapper>

						<Modal
							isOpen={itemFormOpen}
							onRequestClose={handleCloseModal}
							renderHeader={() =>
								currentIndexItem !== null
									? `Editar transporte`
									: `Novo transporte`
							}
							// renderFooter={() => (<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
							//   <Button to={HomePath} primary large>Começar</Button>
							// </div>)}
						>
							<FieldWrapper>
								<SelectInput
									value={currentItem.tipoTransporte}
									onChange={(value) =>
										setCurrentItem((item) => ({
											...item,
											tipoTransporte: value,
										}))
									}
									label="Tipo de transporte"
									options={optionsTipoTransporte}
								/>
							</FieldWrapper>

							<FieldWrapper>
								<SelectInput
									value={currentItem.quantidade}
									onChange={(value) =>
										setCurrentItem((item) => ({ ...item, quantidade: value }))
									}
									label="Quantidade"
									options={[1, 2, 3, 4, 5]}
								/>
							</FieldWrapper>

							<FieldWrapper>
								<NumberInput
									value={currentItem.valor}
									onValueChange={({ floatValue }) => {
										const newValue = floatValue
											? (floatValue / 100).toFixed(2)
											: 0
										setCurrentItem((item) => ({ ...item, valor: newValue }))
									}}
									label="Valor unitário"
									// prefix="R$ "
									// fixedDecimalScale={true}
									format={currencyFormatter}
								/>
							</FieldWrapper>
							<Button large onClick={handleSaveVT(form)}>
								{currentIndexItem !== null ? 'Salvar' : 'Adicionar'}
							</Button>
						</Modal>
					</>
				)}
			</Form>
		</MainLayout>
	)
}

const VTCard = ({ item, onEdit, onDelete, ...props }) => {
	const optionsTipoTransporte = {
		metro: 'Metrô',
		onibus: 'Ônibus',
		trem: 'Trem',
		barca: 'Barca',
	}

	const formatValue = (value) => {
		return Number(value).toFixed(2).toString().replace('.', ',')
	}

	return (
		<Wrapper {...props}>
			<div>
				<ItemTypeVT>{optionsTipoTransporte[item?.tipoTransporte]}</ItemTypeVT>
				<ItemVT>
					Qntd diárias: <b>{String(item?.quantidade)?.padStart(2, '0')}</b>
				</ItemVT>
				<ItemVT>
					Valor unitário: <b>R$ {formatValue(item?.valor)}</b>
				</ItemVT>
			</div>

			{!props.disabled && (
				<Btns>
					<InputBtn icon={IconPencil} onClick={onEdit}>
						Editar
					</InputBtn>
					<InputBtn icon={IconTrash} onClick={onDelete}>
						Deletar
					</InputBtn>
				</Btns>
			)}
		</Wrapper>
	)
}

export default VtPage
