import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import gsap from '~/helpers/gsap'
import IconArrow from '~/svgs/iconArrow'
import Button from './button'

const ReviewAccordion = ({ label, children, to }) => {
	// ------ Abrindo e fechando grupo ------
	const [active, setActive] = useState(false)
	const bodyRef = useRef(null)

	const handleClick = () => setActive((a) => !a)

	// abrir e fechar o grupo
	useEffect(() => {
		if (active)
			gsap.to(bodyRef.current, {
				duration: 0.4,
				ease: 'none',
				height: 'auto',
				paddingTop: '.5rem',
				paddingBottom: '.5rem',
			})
		else
			gsap.to(bodyRef.current, {
				duration: 0.4,
				ease: 'none',
				height: 0,
				paddingTop: 0,
				paddingBottom: 0,
			})
	}, [active])

	return (
		<Container>
			<Wrapper>
				<Header onClick={handleClick}>
					{label}
					<IconArrow dir={active ? 'left' : 'bottom'} />
				</Header>
				<Body ref={bodyRef}>
					<div>{children}</div>
				</Body>
			</Wrapper>
			{to && active && (
				<Button large to={to}>
					Editar {label}
				</Button>
			)}
		</Container>
	)
}

const Container = styled.div`
	& + & {
		margin-top: var(--spacing);
	}
`

const Wrapper = styled.div`
	background-color: #fff;
	border-radius: var(--border-radius);

	position: relative;
	/* font-size: 1.05rem; */
	min-width: 100%;
	transition: all 0.4s ease;

	& + ${Button} {
		margin-top: calc(var(--spacing) * 0.5);
	}
`

const Header = styled.button.attrs({ type: 'button' })`
	padding: calc(0.7em);
	height: 2.8em;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	outline: 0;
	-webkit-appearance: auto;
	cursor: pointer;
	font-size: 0.9rem;
	font-weight: 500;
	color: var(--color-text-dark);
`
const Body = styled.div`
	height: 0;
	overflow: hidden;
	box-sizing: content-box;
	padding-left: 0.7em;
	padding-right: 0.7em;
`

export default ReviewAccordion
