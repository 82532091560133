import { cover } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { clamp } from 'lodash'
import clsx from 'clsx'

const Percentage = ({ progress, size, ...props }) => {
	if (!progress) return null

	return (
		<Wrapper size={size} {...props} done={progress >= 1}>
			{progress < 1 ? (
				<>
					<svg
						width="100%"
						height="100%"
						viewBox="0 0 28 28"
						style={{ transform: 'rotate(-90deg)' }}
					>
						<circle
							fill="none"
							stroke="#12C97A"
							cx={14}
							cy={14}
							r={13}
							strokeWidth={1}
							strokeDasharray={81.68140899}
							strokeDashoffset={81.68140899 * (1 - progress)}
						/>
					</svg>
					<PercentNumber>
						{clamp(Math.round(progress * 100), 1, 99)}%
					</PercentNumber>
				</>
			) : (
				<svg width="100%" height="100%" viewBox="0 0 28 28">
					<path
						fill="#12c97a"
						d="M19.02 8.61a1.4 1.4 0 011.98 0 1.4 1.4 0 010 1.99l-8.81 8.79a1.4 1.4 0 01-1.99-.01L7 16.18a1.4 1.4 0 011.98-1.97l2.22 2.22 7.82-7.83zM14 0a14 14 0 100 28 14 14 0 000-28z"
					/>
				</svg>
			)}
		</Wrapper>
	)
}

const Wrapper = styled.div.attrs((props) => ({
	className: clsx({
		done: props.done,
	}),
}))`
	width: 100%;
	height: 100%;
	font-size: calc(var(--status-icon-size) * 0.62rem);
	border-radius: 50%;
	&:not(.done) {
		box-shadow: 0 0 6px 0 rgb(0, 0, 0, 15%);
	}
	background: #fff;
	position: relative;

	& > * {
		${cover()}
	}
`

const PercentNumber = styled.div`
	color: #12c97a;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 1px;
`

export default styled(Percentage)``
