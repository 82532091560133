import { useCallback, useEffect } from 'react'
import { useForm } from 'react-final-form'

const useSetFieldData = (name, data) => {
	const {
		mutators: { setFieldData },
	} = useForm()

	useEffect(() => {
		if (setFieldData && data) setFieldData(name, data)
	}, [setFieldData, name, data])

	return useCallback(
		(dataProp) => setFieldData(name, dataProp),
		[setFieldData, name],
	)
}

export default useSetFieldData
