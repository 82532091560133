// Returns true if it is a DOM node
export const isDOMNode = (o) => {
	return typeof Node === 'object'
		? o instanceof Node
		: o &&
				typeof o === 'object' &&
				typeof o.nodeType === 'number' &&
				typeof o.nodeName === 'string'
}

// Returns true if it is a DOM element
export const isDOMElement = (o) => {
	return typeof HTMLElement === 'object'
		? o instanceof HTMLElement // DOM2
		: o &&
				typeof o === 'object' &&
				o !== null &&
				o.nodeType === 1 &&
				typeof o.nodeName === 'string'
}

export const watchDomFor = (elementQuery, containers = [document.body]) =>
	new Promise((resolve) => {
		let element = null

		for (const container of containers) {
			element = container.querySelector(elementQuery)
			if (element) {
				resolve(element)
				return
			}
		}

		const observer = new MutationObserver((observ) => {
			for (const container of containers) {
				element = container.querySelector(elementQuery)
				if (element) {
					resolve(element)
					observ.disconnect()
					return
				}
			}
		})

		for (const container of containers) {
			observer.observe(container, { childList: true, subtree: true })
		}
	})
