import React from 'react'
import { useSelector } from 'react-redux'

import { LogoBancoTalentos } from '~/components'
import ImportantInfo from './importantInfo'
import WelcomeLayout from '~/layouts/welcomeLayout'
import { getUser } from '~/store/reducers/user/selectors'
import { getViewPortSize } from '~/store/reducers/ui/selectors'
import { breakpointMatch } from '~/helpers/breakpoints'

import StartForm from './startForm'
import {
	StyledDueDateBox,
	WelcomeLayoutWrapper,
	Greeting,
	Splash,
	Content,
} from './styles'

export const title = 'Bem-vindo'
export const path = '/bem-vindo'

const WelcomePage = () => {
	const user = useSelector(getUser)
	const vp = useSelector(getViewPortSize)
	const { isDesktop } = breakpointMatch(vp)

	return (
		<WelcomeLayout>
			<WelcomeLayoutWrapper>
				<Greeting say="Olá">{user?.nome}</Greeting>
				<Splash>
					Bem-vindo à <span style={{ whiteSpace: 'nowrap' }}>2ª fase</span> do
					processo seletivo!
				</Splash>
				<Content>
					{isDesktop && <LogoBancoTalentos />}
					<ImportantInfo />
					<StyledDueDateBox />
					{!isDesktop && <StartForm />}
				</Content>
			</WelcomeLayoutWrapper>
		</WelcomeLayout>
	)
}

export default WelcomePage
