import * as t from './types'

export const setAppReady = () => ({
	type: t.SET_APP_READY,
	state: true,
})

export const setViewPortSize = (s) => ({
	type: t.SET_VIEWPORT_SIZE,
	size: s,
})

export const setCurrentPage = ({ title, path }) => ({
	type: t.SET_CURRENT_PAGE,
	title,
	path,
})
