import React, { useRef } from 'react'
import { Field } from 'react-final-form'
import { composeValidators } from '~/helpers/validators'
import useSetFieldData from '~/hooks/useSetFieldData'
import FieldInfo from '../fieldInfo'
import SelectInput from '../inputs/selectInput'

function SelectField({
	name,
	validators = [],
	fieldProps,
	helperText,
	...props
}) {
	const inputRef = useRef(null)
	const displayValueRef = useRef(null)
	useSetFieldData(name, {
		inputRef,
		label: props.label,
		displayValue: displayValueRef,
	})

	return (
		<Field
			name={name}
			{...fieldProps}
			validate={composeValidators(...validators)}
		>
			{({ input: { onChange, ...input }, meta }) => {
				const error =
					(input.value || meta.submitFailed) && meta.touched && meta.error

				return (
					<div>
						<SelectInput
							inputRef={inputRef}
							onChange={onChange}
							displayValueRef={displayValueRef}
							{...input}
							{...props}
							error={error}
							valid={input.value && meta.valid}
						/>
						<FieldInfo helperText={helperText} error={error} />
					</div>
				)
			}}
		</Field>
	)
}

export default SelectField
