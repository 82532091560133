import React from 'react'
import Svg from '~/components/svg'

function IconTip(props) {
	return (
		<Svg viewBox="0 0 25.51 25.51" {...props}>
			<path
				fill="#ffd200"
				d="M22.59 0H2.92A2.92 2.92 0 000 2.92v14.35a2.92 2.92 0 002.92 2.92h1.33v4.52a.8.8 0 00.5.74.79.79 0 00.3.06.8.8 0 00.56-.24l5.08-5.08h11.9a2.92 2.92 0 002.92-2.92V2.92A2.93 2.93 0 0022.59 0zm-7.67 14.76a1.19 1.19 0 01-1.19 1.18h-1.95a1.23 1.23 0 01-1.2-1.37V14h4.34v.76zm.86-3.14a2.28 2.28 0 00-.83 1.32h-4.38a2 2 0 00-.78-1.27 4.7 4.7 0 01-1.82-3.82 4.8 4.8 0 014.71-4.66 4.72 4.72 0 013.44 1.38 4.73 4.73 0 01-.34 7.05z"
			/>
		</Svg>
	)
}

export default IconTip
