import React from 'react'
import Svg from '../svg'

function LogoB2W(props) {
	return (
		<Svg
			viewBox="0 0 29.46 29.46"
			style={{ height: '1.7em', width: 'auto' }}
			{...props}
		>
			<path d="M.5 14.73a14.23 14.23 0 1114.23 14.23A14.25 14.25 0 01.5 14.73zm1.5 0A12.73 12.73 0 1014.73 2 12.74 12.74 0 002 14.73zm10.58 6.6a1.14 1.14 0 01-.32-.82 1.07 1.07 0 01.33-.79 1.05 1.05 0 01.78-.32 1 1 0 01.89.5l-.32.3a.68.68 0 00-.24-.25.6.6 0 00-.3-.09.6.6 0 00-.46.2.64.64 0 00-.19.46.66.66 0 00.2.49.61.61 0 00.45.2.58.58 0 00.28-.08.51.51 0 00.22-.27h-.52v-.43h1.02v.28a1.03 1.03 0 01-.55.84 1.2 1.2 0 01-.5.12 1.02 1.02 0 01-.77-.34zm6.85.3v-2.2h.47v1.73h.65v.47zm-.83 0l-.17-.43h-.85l-.17.43h-.47l.87-2.2h.37l.9 2.2zm-.86-.83h.53l-.27-.65zm-1.74.83V19.9h-.5v-.47h1.48v.47h-.5v1.74zm-1.27 0v-2.2h.47v2.2zm-3.26 0v-2.2h.48v2.2zm-2.17 0v-2.2h.76a1.03 1.03 0 01.77.3 1.11 1.11 0 01.3.8 1.04 1.04 0 01-1.11 1.1zm.48-.43h.19a.7.7 0 00.48-.16.66.66 0 00.19-.53.8.8 0 00-.1-.38.47.47 0 00-.26-.23 1.02 1.02 0 00-.32-.06h-.18zm11.99-3.7l-1.55-5.33-1.53 5.33h-1L15.7 9.9h1.22l1.32 5.43 1.53-5.43h.92l1.53 5.43 1.32-5.43h1.21l-1.98 7.6zm-10.67 0v-1l3-3.65a1.5 1.5 0 00.41-1.04 1.14 1.14 0 10-2.28.02H11.1a2.13 2.13 0 012.26-2.2 2.1 2.1 0 012.26 2.2 2.43 2.43 0 01-.73 1.67l-2.45 3h3.18v1zm-5.92 0V9.9h2.96a2.04 2.04 0 012.29 2.08A1.72 1.72 0 019.4 13.6a1.8 1.8 0 011.15 1.76 2.06 2.06 0 01-2.3 2.14zm1.15-1.04h1.82a1.16 1.16 0 100-2.32H6.33zm0-3.35h1.71c.74 0 1.24-.38 1.24-1.09s-.5-1.08-1.24-1.08h-1.7z" />
		</Svg>
	)
}

export default LogoB2W
