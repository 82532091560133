import React from 'react'
import { Scrollbars as CustomScrollbars } from 'react-custom-scrollbars-2'

const ScrollBars = ({ children, ...props }) => {
	return (
		<CustomScrollbars
			autoHeight
			renderTrackHorizontal={() => <div style={{ display: 'none' }} />}
			{...props}
		>
			{children}
		</CustomScrollbars>
	)
}

export default ScrollBars
