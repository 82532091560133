import React from 'react'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import menu from '~/config/menu'

import Link from '../link'

function DesktopMenu(props) {
	const { pathname } = useLocation()

	return (
		<Wrapper {...props}>
			{menu.map((item) => (
				<MenuItemLink
					key={item.path}
					to={item.path}
					current={pathname.replace(/(?!^)\/+$/, '') === item.path}
				>
					{item.title}
				</MenuItemLink>
			))}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: flex;
	justify-items: space-between;
`

const MenuItemLink = styled(Link).attrs((props) => ({
	className: clsx({
		current: props.current,
	}),
}))`
	padding-bottom: calc(var(--spacing) * 0.8);
	font-size: 1rem;
	font-weight: 400;
	color: var(--color-text-dark);
	text-decoration: none;
	position: relative;

	&.current {
		font-weight: 500;
		::after {
			content: '';
			height: 3px;
			position: absolute;
			bottom: -1px;
			right: 0;
			left: 0;
			background: var(--gradient);
		}
	}

	& + & {
		margin-left: calc(var(--spacing) * 2.8);
		::before {
			content: '';
			width: 1px;
			height: 1em;
			position: absolute;
			left: calc(var(--spacing) * -1.4);
			background: #c0c0c0;
		}
	}
`

export default DesktopMenu
