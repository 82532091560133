import styled from 'styled-components'

import FormWarning from '~/components/formWarning'

export const DocWrapper = styled.div`
	margin-bottom: 2rem;

	${FormWarning} {
		opacity: 1;
		height: 2em;
		transition: opacity 0.15s ease, height 0.15s ease;

		&.highlight {
			background: #f4f4f4;
			height: auto;

			p {
				line-height: 1.2;
			}
			strong {
				color: var(--color-text-dark);
			}
		}
	}

	.active + ${FormWarning} {
		opacity: 0;
		height: 0;
	}
`
