/* eslint-disable indent */
import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import styled, { css, keyframes } from 'styled-components'
import { blobToBase64 } from 'base64-blob'
import toast from 'react-hot-toast'

import IconAttachment from '~/svgs/iconAttachment'
import IconEye from '~/svgs/iconEye'
import IconTrash from '~/svgs/iconTrash'
import InputBtn from '../inputBtn'
import IconPencil from '~/svgs/iconPencil'
import api from '~/services/api'

import Modal from '../modal'
import Loading from '../loading'

function AttachmentInput({
	inputRef,
	label,
	name,
	value,
	onChange,
	attachmentPoster,
	attachmentGetter,
	attachmentDeleter,
	noDelete,
	error,
	valid,
	className,
	...props
}) {
	// ref onde fica armazenado o token de cancelamento do request de post (upload)
	const cancelTokenRef = useRef(null)

	const [submitting, setSubmitting] = useState(false)

	// ao limpar file (setFile(undefined)) limpar o input de arquivo de fato
	useEffect(() => {
		if (!value?.file) inputRef.current.value = ''
	}, [value, inputRef])

	// ----- visualização do arquivo -----

	// guarda url gerada para visualizar o arquivo
	const [fileView, setFileView] = useState(undefined)

	const handleView = async () => {
		if (!value) return

		try {
			setFileView('loading')
			if (value?.file)
				// se existe um arquivo inserido agora, ler dele
				setFileView(await blobToBase64(value.file))
			// else // se arquivo foi inserido anteriormente, baixar arquivo
			else setFileView(await blobToBase64(await attachmentGetter()))
		} catch (err) {
			setFileView(undefined)
			console.error(err)
			toast.error('Ocorreu um erro ao exibir o arquivo. Tente Novamente')
		}
	}

	// ----- deletando arquivo -----
	const handleDelete = async () => {
		if (!value || noDelete) return

		// se o request de upload ainda não tiver terminado, cancela upload
		if (submitting) {
			cancelTokenRef.current.cancel()
		}
		// se já tiver terminado, deletar. Se o request para deletar não tiver sucesso, parar a função (erro é tratado no request)
		else if (!(await attachmentDeleter())) {
			return
		}

		// retirando arquivo
		onChange(undefined)
	}

	// ----- Upload do arquivo no momento em que é inserido  -----

	const [progress, setProgress] = useState(undefined)

	// lida com a barra de progresso
	const handleProgress = (prog) => {
		setProgress(prog === 1 ? undefined : prog)
	}

	const handleChange = async (e) => {
		const file = e.target?.files?.[0]

		if (file) {
			onChange({
				sending: true,
				file,
			})

			cancelTokenRef.current = api.getCancelSource()

			try {
				setSubmitting(true)
				const uuid = await attachmentPoster(
					file,
					handleProgress,
					cancelTokenRef.current,
				)
				onChange({
					uuid,
					file,
				})
			} catch (err) {
				// se post (upload) do arquivo dá erro, zera o input e barra de progresso

				// zerar input apenas se upload não falhou por motivo de validação
				if (err.message !== 'validation') onChange(undefined)
				else onChange({ file })

				setProgress(undefined)
			} finally {
				setSubmitting(false)
			}
		}
		// else // se arquivo não existe no input e houve mudança, significa que foi apagado de alguma outra forma no browser
		else handleDelete()
	}

	// ----- estado focused -----

	const wrapperRef = useRef(null)
	const [focused, setFocused] = useState(false)

	useEffect(() => {
		const wrapper = wrapperRef.current
		const eventHandler = (e) => setFocused(e.type === 'focusin')

		wrapper.addEventListener('focusin', eventHandler)
		wrapper.addEventListener('focusout', eventHandler)

		return () => {
			wrapper.removeEventListener('focusin', eventHandler)
			wrapper.removeEventListener('focusout', eventHandler)
		}
	}, [wrapperRef])

	return (
		<>
			<Wrapper
				ref={wrapperRef}
				submitting={submitting}
				progress={progress}
				isfilled={Boolean(value)}
				focused={focused}
				error={error}
				valid={valid}
				disabled={props.disabled}
				className={className}
			>
				<Label>
					{/* TODO F: incluir pdf? image/jpeg, image/png, image/bmp, application/pdf */}
					{/* <HiddenInput ref={inputRef} type="file" onChange={handleChange} accept="image/jpeg, image/png, image/bmp" capture="camera" {...props} /> */}
					<HiddenInput
						ref={inputRef}
						type="file"
						onChange={handleChange}
						accept="image/jpeg, image/png, image/bmp"
						{...props}
					/>
					<LabelText>{label}</LabelText>
					<Value>
						{value ? value?.file?.name || 'arquivo anexado' : undefined}
					</Value>
				</Label>

				<Btns>
					{value ? (
						<>
							<InputBtn icon={IconEye} onClick={handleView}>
								Revisar
							</InputBtn>
							{!props.disabled &&
								(noDelete ? (
									<InputBtn
										icon={IconPencil}
										onClick={() => inputRef.current.click()}
										disabled={props.disabled}
									>
										Alterar
									</InputBtn>
								) : (
									<InputBtn
										icon={IconTrash}
										onClick={handleDelete}
										disabled={props.disabled}
									>
										Excluir
									</InputBtn>
								))}
						</>
					) : (
						<InputBtn
							icon={IconAttachment}
							onClick={() => inputRef.current.click()}
							disabled={props.disabled}
						>
							Anexar
						</InputBtn>
					)}
				</Btns>
			</Wrapper>

			{/* Visualização do arquivo */}
			<Modal
				isOpen={Boolean(fileView)}
				onRequestClose={() => {
					setFileView(undefined)
				}}
				renderHeader={() => label}
				// renderFooter={() => (<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
				//   <Button to={HomePath} primary large>Começar</Button>
				// </div>)}
			>
				{fileView === 'loading' ? (
					<div style={{ height: '60vh' }}>
						<Loading size={0.5} />
					</div>
				) : (
					<img src={fileView} alt="" />
				)}
			</Modal>
		</>
	)
}

const Wrapper = styled.div.attrs((props) => ({
	className: clsx({
		error: props.error,
		focused: props.focused,
		isfilled: props.isfilled,
		disabled: props.disabled,
	}),
}))`
	background-color: #fff;
	border-radius: var(--border-radius) var(--border-radius) 0 0;
	border: 1px solid #efefef;
	border-bottom: 0;
	position: relative;
	/* box-shadow: 0 0 2px #0006; */
	font-size: 1.05rem;
	height: 3.3em;
	min-width: 100%;
	transition: all 0.4s ease;

	&.disabled {
		background: #f5f5f5;
	}

	&.focused {
		box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
		/* TODO F: dentro do fieldGroup: box-shadow: 0 0 2px 0 rgb(0 0 0 / 20%) */
	}

	&.error {
		border: 1px solid var(--color-error);
		border-bottom: 0;
	}

	&::after {
		content: '';
		position: absolute;
		left: -1px;
		right: -1px;
		bottom: 0;
		height: var(--input-line-thickness);
		transition: all 0.4s ease;
		z-index: 1;

		${({ disabled, submitting, progress, error, valid, focused }) =>
			disabled
				? css`
						background: #e6e6e6;
						transform: scaleY(0.6);
						transform-origin: bottom;
				  `
				: progress
				? css`
						background: var(--color-success);
						width: ${progress * 100}%;
						right: auto;
				  `
				: submitting
				? css`
						background: linear-gradient(
							to right,
							var(--color-success) 20%,
							#f4f4f4 20%
						);
						background-size: 200%;
						background-position-x: 0;
						animation: ${keyframes`
          from {
            background-position-x: 0;
          }
          to {
            background-position-x: -100%;
          }
        `} 1s linear infinite;
				  `
				: error
				? css`
						background: var(--color-error);
				  `
				: valid
				? css`
						background: var(--color-success);
				  `
				: focused
				? css`
						background: var(--gradient);
				  `
				: css`
						background: #b5b5b5;
				  `}
	}
`

const HiddenInput = styled.input`
	margin: 0;
	padding: 0;
	border: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
`

const Label = styled.label`
	padding: 1.3em 0 0 0.6em;
	cursor: pointer;
	position: absolute;
	height: 100%;
	width: calc(100% - 56px);
	${Wrapper}.isfilled & {
		width: calc(100% - 111px);
	}
`

const LabelText = styled.div`
	color: var(--color-text-dark);
	position: absolute;
	top: 0;
	left: calc(0.7em - 1px);
	transform: translateY(calc(1.65em + 1px - 0.60375em));
	font-weight: 400;
	pointer-events: none;
	transition: all 0.3s ease;
	transform-origin: left;
	white-space: nowrap;

	${Wrapper}.disabled & {
		color: var(--color-text-light);
	}

	${Wrapper}.isfilled & {
		top: 0.37em;
		transform: scale(0.7);
		transform-origin: left;
		font-weight: 600;
		letter-spacing: 0.01em;
	}
`

const Value = styled.div`
	white-space: nowrap;
	display: inline-block;
	width: calc(100% - 2px);
	overflow: hidden;
	text-overflow: ellipsis;
`

const Btns = styled.div`
	height: 100%;
	background: #fff;
	border-top-right-radius: 5px;
	position: absolute;
	right: 0px;

	${Wrapper}.error & {
		top: 1px;
	}
	${Wrapper}.disabled & {
		background: #f5f5f5;
	}

	${InputBtn} {
		height: 100%;
	}
`

export default AttachmentInput
