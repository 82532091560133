import clsx from 'clsx'
import React from 'react'
import styled, { css } from 'styled-components'

import IconWarning from '~/svgs/iconWarning'

const FormWarning = ({ children, highlight, ...props }) => {
	return (
		<Wrapper highlight={highlight} {...props}>
			<StyledIconWarning />
			{children}
		</Wrapper>
	)
}

const StyledIconWarning = styled(IconWarning)`
	font-size: 1.7rem;
	flex: none;
	margin-right: 0.7rem;
`

const Wrapper = styled.div.attrs((props) => ({
	className: clsx({
		highlight: props.highlight,
	}),
}))`
	font-weight: 400;
	font-size: 0.875rem;
	display: flex;
	margin: 0.6rem 0;
	line-height: 1.3;
	${(props) =>
		props.center &&
		css`
			align-items: center;
		`}
	${(props) =>
		props.border &&
		css`
			border: 1px solid #e92100;
		`}

  &.highlight {
		background: #fcfcfc;
		border-radius: var(--border-radius);
		padding: calc(var(--spacing) * 0.8) calc(var(--spacing) * 0.6);
	}

	& > *:nth-child(2),
	& > *:nth-child(2):last-child > *:first-child {
		margin-top: 0;
	}
	& > *:last-child,
	& > *:nth-child(2):last-child > *:last-child {
		margin-bottom: 0;
	}
`

export default styled(FormWarning)``
