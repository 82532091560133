import { keyframes } from 'styled-components'

export const fadeIn = keyframes`
 0% { opacity: 0; }
 100% { opacity: 1; }
`
export const fadeOut = keyframes`
 0% { opacity: 1; }
 100% { opacity: 0; }
`

export const slideBg = keyframes`
  0% {
    background-position-x: 0%;
  }
  100% {
    background-position-x: 100%;
  }
`

export const rotate = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`
