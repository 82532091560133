/* eslint-disable indent */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import styled, { css } from 'styled-components'
import tippy from 'tippy.js'
// eslint-disable-next-line import/no-extraneous-dependencies
import sortBy from 'lodash.sortby'
import { useSelector } from 'react-redux'
import { nanoid } from 'nanoid'
import IconArrow from '~/svgs/iconArrow'
import { getViewPortSize } from '~/store/reducers/ui/selectors'
import { breakpointMatch } from '~/helpers/breakpoints'
import Modal from '../modal'
import ScrollBars from '../scrollBars'
import TextInput from './textInput'
import IconSearch from '~/svgs/iconSearch'
import queryMatch from '~/helpers/queryMatch'
import removeDiacritics from '~/helpers/removeDiacritics'
import { watchDomFor } from '~/helpers/dom'

function SelectInput({
	inputRef,
	label,
	name,
	value,
	options,
	onChange,
	displayValueRef,
	error,
	valid,
	sort,
	otherOption = [],
	showSearchByDefault,
	...props
}) {
	const vp = useSelector(getViewPortSize)
	const { isDesktop } = breakpointMatch(vp)

	const wrapperRef = useRef(null)
	const headerRef = useRef(null)
	const bodyRef = useRef(null)

	const [active, setActive] = useState(false)

	showSearchByDefault = options?.length > 10 ? true : showSearchByDefault
	const [showSearch, setShowSearch] = useState(showSearchByDefault)
	const [searchValue, setSearchValue] = useState('')
	const optionsWrapperUniqueID = useRef(nanoid())

	const tippyInstance = useRef(null)

	// inicializa tippy (lib para posicionamento dinâmico do popover)
	useEffect(() => {
		if (isDesktop) {
			setActive(false) // para responsividade. quando muda mobile -> desktop, modal do mobile fecha

			tippyInstance.current = tippy(headerRef.current, {
				content: bodyRef.current,
				placement: 'bottom-start',
				popperOptions: {
					strategy: 'fixed',
				},
				offset: ({ reference }) => [0, -1 * reference.height],
				arrow: false,
				interactive: true,
				trigger: 'manual',
				onHide: () => setActive(false),
				onShow(instance) {
					instance.setProps({
						maxWidth: headerRef.current.offsetWidth,
					})
					instance.popper.querySelector(
						'.tippy-content',
					).style.width = `${headerRef.current.offsetWidth}px`
				},
			})
		} else {
			// para responsividade. quando muda desktop -> mobile, tippy fecha
			tippyInstance.current?.hide && tippyInstance.current.hide()
		}
	}, [isDesktop])

	// atalho para fechar select e manter foco no campo
	const closeSelect = () => {
		setActive(false)
		headerRef.current.focus()
	}

	// ------ Ao abrir e fechar
	useEffect(() => {
		if (active) {
			if (isDesktop) tippyInstance.current.show()
			;(async () => {
				const optionsWrapper = await watchDomFor(
					`.options-wrapper-${optionsWrapperUniqueID.current}-${
						isDesktop ? 'md' : 'xs'
					}`,
				)

				const current =
					optionsWrapper.querySelector('.option:focus') ??
					optionsWrapper.querySelector('.selected') ??
					optionsWrapper.querySelector('.option:first-of-type')

				const searchInput = optionsWrapper.querySelector(`.input`)

				current && current.focus()

				if (showSearch && searchInput) {
					searchInput.focus()
				}
			})()
		} else {
			if (isDesktop) tippyInstance.current.hide()

			setSearchValue('')
		}
	}, [active, isDesktop, showSearch])

	// ------ Calcular estados do select (focus, etc) ------

	const [focused, setFocused] = useState(false)

	useEffect(() => {
		const header = headerRef.current
		const eventHandler = (e) => {
			setFocused(e.type === 'focus')
		}

		header.addEventListener('focus', eventHandler)
		header.addEventListener('blur', eventHandler)

		return () => {
			header.removeEventListener('focus', eventHandler)
			header.removeEventListener('blur', eventHandler)
		}
	}, [])

	// ------ Pesquisa e acessibilidade pelo teclado  ------

	useEffect(
		() =>
			showSearch &&
			document
				.getElementById(
					(isDesktop ? 'md' : 'xs') + optionsWrapperUniqueID.current,
				)
				?.focus(),
		[showSearch, isDesktop],
	)

	const handleUpDownNav = useCallback(
		async (e) => {
			e.preventDefault()
			let prevActiveState
			setActive((s) => {
				prevActiveState = s // impuro, mas facilita
				return true
			})

			const key = e.key || e.keyCode

			// aguardando até que já esteja ativo e opções inseridas na DOM
			const optionsWrapper = await watchDomFor(
				`.options-wrapper-${optionsWrapperUniqueID.current}-${
					isDesktop ? 'md' : 'xs'
				}`,
			)

			const current =
				optionsWrapper.querySelector('.option:focus') ??
				optionsWrapper.querySelector('.selected') ??
				optionsWrapper.querySelector('.option:first-of-type')

			const searchInput = optionsWrapper.querySelector(`.input`)

			if (document.activeElement === searchInput) {
				const first = optionsWrapper.querySelector('.option:first-of-type')
				first && first.focus()
			} else if (prevActiveState && (key === 'ArrowDown' || key === 40)) {
				const next = current?.nextElementSibling
				console.log('next', next)
				next && next.focus()
			} else if (prevActiveState && (key === 'ArrowUp' || key === 38)) {
				const prev = current?.previousElementSibling
				console.log('prev', prev)
				if (prev) prev.focus()
				else {
					searchInput && searchInput.focus()
				}
			}
		},
		[isDesktop],
	)

	useEffect(() => {
		const wrapper = wrapperRef.current

		const keyDownHandler = (e) => {
			const key = e.key || e.keyCode

			if (key === 'Escape' || key === 'Esc' || key === 27) {
				closeSelect()
				return
			}
			if (
				key === 'ArrowDown' ||
				key === 40 ||
				key === 'ArrowUp' ||
				key === 38
			) {
				handleUpDownNav(e)
				return
			}
			const searchInput = document.querySelector(
				`.options-wrapper-${optionsWrapperUniqueID.current}-${
					isDesktop ? 'md' : 'xs'
				} .input`,
			)
			if (key === ' ' || key === 32 || document.activeElement === searchInput)
				return
			if (
				key.length === 1 ||
				key === 'Backspace' ||
				key === 8 ||
				key === 'Delete' ||
				key === 46
			) {
				setActive(true)
				setShowSearch(true)
				if (searchInput) searchInput.focus()
			}
		}

		wrapper.addEventListener('keydown', keyDownHandler)
	}, [isDesktop, handleUpDownNav])

	// ------ Ordenando opções ------
	const sortedOptions = useMemo(() => {
		return (
			sort &&
			sortBy(options, (o) =>
				removeDiacritics(Array.isArray(o) ? o[1] : o).toLowerCase(),
			)
		)
	}, [sort, options])

	// ------ Filtrando opções (e ordenando automaticamente)
	const matchedOptions = useMemo(() => {
		if (!searchValue) return null

		const matches = options.filter(
			(option) =>
				queryMatch({
					query: searchValue,
					str: Array.isArray(option) ? option[1] : option,
				}).matches,
		)

		return sortBy(matches, (o) =>
			removeDiacritics(Array.isArray(o) ? o[1] : o).toLowerCase(),
		)
	}, [searchValue, options])

	// ------ Ao selecionar uma opção ------

	const handleSelect = (val) => {
		onChange(val)

		closeSelect()
	}

	// ------ Calcula texto a ser exibido como valor do campo
	const valueText = useMemo(() => {
		if (!value || !options?.length) return undefined

		return Array.isArray(options[0])
			? options.filter((o) => o[0] === value)?.[0]?.[1] || undefined
			: value
	}, [value, options])

	// ------ configura displayValue (para uso nos fieldGroups)
	useEffect(() => {
		if (displayValueRef?.current) displayValueRef.current = valueText
	}, [valueText, displayValueRef])

	const SelectList = (
		<Options>
			{
				// se não estiver buscando, mostra opção "nula" no topo
				!searchValue ? (
					<Option
						style={{ color: '#a0a0a0' }}
						selected={value === ''}
						disabled={!active}
						onClick={() => handleSelect('')}
					>
						Selecione
					</Option>
				) : undefined
			}

			{(searchValue ? matchedOptions : sort ? sortedOptions : options)
				?.concat(otherOption)
				?.map((option) =>
					Array.isArray(option) ? (
						<Option
							key={option[0]}
							selected={value === option[0]}
							disabled={!active}
							onClick={() => handleSelect(option[0])}
						>
							{option[1]}
						</Option>
					) : (
						<Option
							key={option}
							selected={value === option}
							disabled={!active}
							onClick={() => handleSelect(option)}
						>
							{option}
						</Option>
					),
				)}
		</Options>
	)

	return (
		<Wrapper
			ref={wrapperRef}
			focused={focused}
			error={error}
			valid={valid}
			disabled={props.disabled}
			hasValue={Boolean(value)}
			active={active}
		>
			<Header
				onClick={() => setActive((s) => !s)}
				ref={headerRef}
				disabled={props.disabled}
				onBlur={props.onBlur}
				onFocus={props.onFocus}
			>
				<div>
					<LabelText>{label}</LabelText>
					{value && <Value>{valueText}</Value>}
				</div>

				<IconArrow dir={active ? 'left' : 'bottom'} />
			</Header>

			{/* Desktop */}
			<TippyBody ref={bodyRef} label={label}>
				<OptionsWrapper
					className={`options-wrapper-${optionsWrapperUniqueID.current}-md`}
				>
					{showSearch && (
						<SearchInput
							value={searchValue}
							onChange={(e) => setSearchValue(e.target.value)}
						/>
					)}
					<ScrollBars
						autoHeightMax={`calc(40vh - 1.84rem - ${
							showSearch ? '(4 * 1.05rem)' : '0px'
						})`}
					>
						{SelectList}
					</ScrollBars>
				</OptionsWrapper>
			</TippyBody>

			{/* Mobile/Tablet */}
			{!isDesktop && (
				<StyledModal
					isOpen={active}
					onRequestClose={() => {
						closeSelect()
					}}
					renderHeader={() => label}
				>
					<OptionsWrapper
						className={`options-wrapper-${optionsWrapperUniqueID.current}-xs`}
					>
						{showSearch && (
							<SearchInput
								value={searchValue}
								onChange={(e) => setSearchValue(e.target.value)}
							/>
						)}
						<ScrollBars
							autoHeightMax={`calc(100vh - var(--spacing) * 8.5 - ${
								showSearch ? '(4 * 1.05rem)' : '0px'
							})`}
						>
							{SelectList}
						</ScrollBars>
					</OptionsWrapper>
				</StyledModal>
			)}
		</Wrapper>
	)
}

const Wrapper = styled.div.attrs((props) => ({
	className: clsx({
		error: props.error,
		focused: props.focused,
		disabled: props.disabled,
		hasValue: props.hasValue,
		valid: props.valid,
		active: props.active,
	}),
}))`
	background-color: #fff;
	border-radius: var(--border-radius) var(--border-radius) 0 0;
	position: relative;
	/* box-shadow: 0 0 2px #0006; */
	font-size: 1.05rem;
	height: 3.3em;
	min-width: 100%;
	transition: all 0.4s ease;

	&.disabled {
		background-color: #f5f5f5;
	}

	&.focused {
		box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
	}
	&.active {
		box-shadow: none;
	}

	&::after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: var(--input-line-thickness);
		transition: all 0.4s ease;

		${({ disabled, focused, active, error, valid }) =>
			disabled
				? css`
						background: #e6e6e6;
						transform: scaleY(0.6);
						transform-origin: bottom;
				  `
				: error
				? css`
						background: var(--color-error);
				  `
				: valid
				? css`
						background: var(--color-success);
				  `
				: active || focused
				? css`
						background: var(--gradient);
				  `
				: css`
						background: #b5b5b5;
				  `}
	}

	[data-tippy-root] {
		/* width: 100%; */
	}

	.tippy-box {
		background-color: transparent;
		color: inherit;
		border-radius: 0;
		font-size: inherit;
		line-height: inherit;
	}
	.tippy-content {
		padding: 0;
	}
`

const Header = styled.button.attrs({ type: 'button' })`
	padding: 0.7em;
	height: 3.3em;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	outline: 0;
	-webkit-appearance: auto;
	cursor: pointer;
	border-radius: var(--border-radius) var(--border-radius) 0 0;

	&:focus,
	&:hover {
		--icon-scale: 1.2;
		--icon-color: #333;
	}

	${Wrapper}.error & {
		padding: calc(0.7em - 1px);
		border: 1px solid var(--color-error);
	}
`

const LabelText = styled.div`
	color: var(--color-text-dark);
	position: absolute;
	top: 0;
	left: 0.7em;
	transform: translateY(calc(1.65em + 1px - 0.60375em));
	transform-origin: left;
	transition: all 0.3s ease;

	${Wrapper}.disabled & {
		color: var(--color-text-light);
	}

	${Wrapper}.active &, ${Wrapper}.hasValue & {
		/* ${Wrapper}.hasValue:not(.active) & { */
		top: 0.37em;
		transform: scale(0.7);
		font-weight: 600;
		letter-spacing: 0.01em;
	}
`

const Value = styled.div`
	transform: translateY(0.41em);
	transition: all 0.2s ease;
	transition-delay: 0.2s;

	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	overflow: hidden;
	-webkit-box-orient: vertical;
	text-align: left;
	${Wrapper}.active & {
		transform: translateY(1em);
		opacity: 0;
		transition-delay: 0s;
	}
`

const TippyBody = styled.div`
	max-height: 40vh;
	min-height: 3.3em;
	width: ${(props) => props.width};

	display: flex;
	flex-direction: column;

	width: 100%;
	z-index: 1;

	box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.25);

	background: #fff;
	border-radius: var(--border-radius);

	&::before {
		content: '${(props) => props.label}';

		color: var(--color-text-dark);
		font-size: 70%;
		font-weight: 600;
		letter-spacing: 0.01em;
		padding: 0.7em 1em;
		height: 1.84rem;
		box-sizing: border-box;
	}

	${Wrapper} > & {
		display: none;
	}
`

const OptionsWrapper = styled.div``

const Options = styled.div`
	flex: 1;
	overflow: auto;
`

const Option = styled.button.attrs((props) => ({
	type: 'button',
	className: clsx('option', {
		selected: props.selected,
	}),
}))`
	width: 100%;
	padding: 0.5em 0.7em;
	text-align: start;
	transition: all 0.2s ease;
	cursor: pointer;

	:last-child {
		margin-bottom: 0.5em;
	}

	&.selected {
		font-weight: 500;
		color: var(--color-text-dark);
	}

	:hover {
		background: #eee;
	}
	:focus {
		background: #eee;
	}
	:active {
		background: #ddd;
	}
`

const StyledModal = styled(Modal)`
	padding: 0;
	.modal-header {
		margin: 0;
		padding: calc(var(--spacing) * 0.7);
		padding-bottom: calc(var(--spacing) * 0.5);
		font-size: 0.75em;
		border: 0;
	}
	.modal-body {
		padding: 0 0 calc(var(--spacing) * 0.2);
	}
	/* ${Options} {
    max-height: calc(100vh - var(--spacing) * 8.5);
  } */
	${Option} {
		padding: calc(var(--spacing) * 0.6) calc(var(--spacing) * 0.7);
		:last-child {
			margin-bottom: 0;
		}
	}
`

const SearchInput = styled(TextInput).attrs({
	placeholder: `Busca`,
	EndIcon: IconSearch,
	autoComplete: 'off',
})`
	height: 2.8em;
	border-radius: var(--border-radius);
	color: var(--color-text-light);
	border: 1px solid currentColor;
	margin: 0.5em 0.7em 0.7em;
	min-width: calc(100% - 0.7em * 2);
	width: calc(100% - 0.7em * 2);

	&.focused {
		box-shadow: none;
		color: var(--color-text-dark);
	}

	&::after,
	.labelText {
		display: none;
	}
	.input {
		:focus,
		:not(.empty) {
			padding-top: 0.7em;
		}
		:-webkit-autofill {
			padding-top: 0.7em;
		}

		::placeholder {
			opacity: 1;
			font-weight: 300;
		}
	}
`

export default SelectInput
