/* eslint-disable indent */
import * as t from './types'

const initialState = {
	loading: true,
}

const stages = (state = initialState, action) => {
	switch (action.type) {
		case t.LOAD_STAGES_REQUEST:
			return {
				...state,
				loading: true,
			}
		case t.LOAD_STAGES_SUCCESS:
			return {
				currentStages: action.data,
				loading: false,
			}
		case t.LOAD_STAGES_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error,
			}
		default:
			return state
	}
}

export default stages
