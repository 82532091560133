import { blobToBase64 } from 'base64-blob'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import styled from 'styled-components'
import api from '~/services/api'

const PhotoImg = ({ file, ...props }) => {
	const [photo, setPhoto] = useState()

	useEffect(() => {
		;(async () => {
			try {
				let file_blob = file
				if (!file_blob) {
					const { data } = await api.upload('foto_cracha').get()
					file_blob = data
				}

				setPhoto(await blobToBase64(file_blob))
			} catch (err) {
				console.log(err)
				toast.error('Erro ao exibir foto')
			}
		})()
	}, [file])

	return (
		<Wrapper {...props}>
			<Image src={photo} />
		</Wrapper>
	)
}

const Wrapper = styled.div`
	width: 120px;
	height: 160px;

	border: 1px solid #959595;
	border-radius: 2px;
`

const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center center;
`

export default styled(PhotoImg)``
