import styled from 'styled-components'
import { Error } from '~/components'
import { mq } from '~/helpers/breakpoints'

export const Wrapper = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	${Error} {
		flex-grow: 1;
		margin-bottom: var(--spacing);
		max-height: calc(100vh - var(--spacing) * 9);

		${mq.min('md')} {
			max-height: calc(100vh - var(--spacing) * 9 - var(--header-height));
		}
	}
`
