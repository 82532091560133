import React, { useRef } from 'react'
import { useField } from 'react-final-form'
import toast from 'react-hot-toast'
import handleReqError from '~/helpers/handleReqError'
import {
	composeValidators,
	imagesOnly,
	maxFileSize,
} from '~/helpers/validators'
import useSetFieldData from '~/hooks/useSetFieldData'
import api from '~/services/api'
import FieldInfo from '../fieldInfo'
import AttachmentInput from '../inputs/attachmentInput'

function AttachmentField({
	name,
	type,
	validators = [],
	fieldProps,
	helperText,
	maxSize = 5,
	forceErrorUntilChange,
	...props
}) {
	const inputRef = useRef(null)
	useSetFieldData(name, { inputRef, label: props.label, type: 'attachment' })

	const {
		input: { value, onChange },
		meta,
	} = useField(name, {
		validate: composeValidators(
			...validators,
			imagesOnly,
			maxFileSize(maxSize),
		),
		...fieldProps,
	})

	const error =
		(!meta.modified && forceErrorUntilChange) ||
		((meta.dirty || meta.submitFailed) && meta.error)

	// ------ lógica para subir imagem ------
	const attachmentPoster = async (file, handleProgress, cancelSource) => {
		if (maxFileSize(maxSize)({ file }) || imagesOnly({ file })) {
			throw new Error('validation')
		}

		try {
			const formData = new FormData()
			formData.append('image', file)

			const { data } = await api.upload(type).post({
				values: formData,
				handleProgress,
				cancelSource,
			})

			setTimeout(() => toast.dismiss(name), 2000)

			return data?.id
		} catch (err) {
			// console.error(err)
			toast.dismiss(name)
			toast.error('Não foi possível inserir o arquivo. Tente Novamente') // usar props.label no toastr?
			throw err
		}
	}

	// ------ lógica para baixar e visualizar imagem ------
	const attachmentGetter = async () => {
		try {
			const { data } = await api.upload(type).get()
			return data
		} catch (err) {
			// console.error(err)
			toast.error('Não foi possível recuperar o arquivo. Tente Novamente')
			return false
		}
	}

	// ------ lógica para deletar imagem ------
	const attachmentDeleter = async () => {
		try {
			await api.upload(type).delete()
			return true
		} catch (err) {
			return handleReqError(err, {
				404: true, // se arquivo não for encontrado no banco, considerar "sucesso" no delete
				default: () => {
					// console.error(err)
					toast.error('Não foi possível excluir o arquivo. Tente Novamente')
					return false
				},
			})
		}
	}

	return (
		<div>
			<AttachmentInput
				inputRef={inputRef}
				value={value}
				onChange={onChange}
				attachmentPoster={attachmentPoster}
				attachmentGetter={attachmentGetter}
				attachmentDeleter={attachmentDeleter}
				{...props}
				error={error}
				valid={value?.uuid && meta.valid}
			/>
			<FieldInfo helperText={helperText} error={error} />
		</div>
	)
}

export default AttachmentField
