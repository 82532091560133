import React from 'react'
import styled from 'styled-components'
import LogoAmericanas from '../logos/logoAmericanas'
import Modal from '../modal'
import PhotoImg from './photoImg'

function NameTagModal({ info, ...props }) {
	return (
		<WrapperModal isOpen={Boolean(info)} {...props}>
			<NameTag>
				<StyledPhotoImg file={info?.photoFile} />
				<div style={{ gridArea: 'names' }}>
					<TagName>{info?.tagName ?? '-'}</TagName>
					<FullName>{info?.fullName}</FullName>
				</div>
				<div style={{ gridArea: 'logo', color: '#fff' }}>
					<LogoAmericanas size={1.9} />
				</div>
			</NameTag>
		</WrapperModal>
	)
}

const StyledPhotoImg = styled(PhotoImg)`
	grid-area: photo;
	width: 94px;
	height: 125px;
	margin: 0 auto;

	border: 0;
	box-shadow: 0 0 4px 0px rgb(0, 0, 0, 0.4);

	& ~ * {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
`

const WrapperModal = styled(Modal)`
	--close-button-color: #550404;
	padding: 0;
	width: 250px;
	.modal-body {
		width: 100%;
		position: relative;
		height: 0;
		padding-top: 157%;
	}
`

const NameTag = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: grid;
	grid-template-rows: 24% 32% 1fr 20%;
	grid-template-areas:
		'_'
		'photo'
		'names'
		'logo';
	background-image: linear-gradient(
		to bottom,
		#ee0f0f 20%,
		#fff 20%,
		#fff 80%,
		#ee0f0f 80%
	);
	background-image: linear-gradient(
		to bottom,
		#ee0f0f 20%,
		#fff 20% 80%,
		#ee0f0f 80%
	);
`

const TagName = styled.div`
	text-transform: uppercase;
	font-size: 1.5rem;
	color: #000;
	font-weight: 600;
`
const FullName = styled.div`
	font-size: 0.75rem;
	color: #000;
	margin-top: 4px;
`

export default NameTagModal
