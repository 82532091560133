import React, { useRef } from 'react'
import { hideVisually } from 'polished'
import styled from 'styled-components'

import FieldInfo from './fieldInfo'

const RadioFieldGroup = ({ label, helperText, inline, children, ...props }) => {
	const displayValueRef = useRef(null)

	const childrenWithDisplayValueRefProp = React.Children.map(
		children,
		(child) => {
			// Checking isValidElement is the safe way and avoids a typescript
			// error too.
			if (React.isValidElement(child)) {
				return React.cloneElement(child, { displayValueRef })
			}
			return child
		},
	)

	return (
		<Wrapper {...props}>
			<HiddenLegend>{label}</HiddenLegend>
			<Label>{label}</Label>
			<RadioFieldsContainer inline={inline}>
				{childrenWithDisplayValueRefProp}
			</RadioFieldsContainer>
			<FieldInfo helperText={helperText} />
		</Wrapper>
	)
}

const Wrapper = styled.fieldset`
	border: 0;
	padding: 0;
`

const HiddenLegend = styled.legend`
	${hideVisually()}
	font-size: 1px;
	color: transparent;
`

const Label = styled.div`
	font-weight: 500;
	color: var(--color-text-dark);
	font-size: 0.8rem;
	padding: 0.3em calc(0.7rem - 1px);
`

const RadioFieldsContainer = styled.div`
	display: flex;
	flex-direction: ${(props) => (props.inline ? 'row' : 'column')};
	justify-content: ${(props) => (props.inline ? 'space-around' : 'inherit')};
	align-items: flex-start;
`

export default RadioFieldGroup
