/* eslint-disable indent */
import removeDiacritics from './removeDiacritics'

const queryMatch = ({ str, query, regexQuery }) => {
	query = String(query)
	str = String(str)

	if (!regexQuery) {
		regexQuery = removeDiacritics(query)
			.replace(/[\s'!=/\\^$+?.()|[\]{}<>]+/g, ' ')
			.trim()
			// strings entre aspas darão match exatamente com aquelas palavras na ordem escrita
			.replace(/"[^"]*"/g, (v) => {
				// trocando espaços em branco pelo equivalente \s para que não seja feito o split e a ordem destas palavras seja mantida
				return v.replace(/\s/g, '\\s')
			})
			// criação do padrão RegEx, palavra por palavra
			.split(' ')
			.map((word) => {
				// delimitador do inicio de uma palavra
				const ws = '[-\\s_"\'/|\\?!*+=%$#@&(){}<>:;.,[\\]]'

				// números podem ser precedidos de 0
				if (/^[0-9]+$/g.test(word)) {
					word = `(?=((?:.*${ws})?0*${word}))`
				}
				// palavras antecedidas por '-' serão excluídas da pesquisa usando o "(?! ... )" do regex
				else if (/^-/g.test(word)) {
					word =
						word.length === 1
							? ''
							: `(?!(?:.*${ws})?${word
									.substring(1)
									.replace(/"/g, '')
									.replace(/\*/g, '\\S+')}\\b)`
				}
				// trechos entre aspas terão match exato no que foi escrito, inclusive na ordem das palavras
				else if (/^"[^"]*"$/g.test(word)) {
					word = `(?=((?:.*${ws})?${word
						.replace(/"/g, '')
						.replace(/\*/g, '\\S+')}))`
				}
				// restante das palavras funciona com fuzzy match e em qualquer ordem na busca
				else {
					word = `(?=((?:.*${ws})?${word
						.replace(/"/g, '')
						.split('')
						.join('\\S{0,1}?')
						.replace(/\*/g, '\\S+')}))`
				}
				return word
			})
			.join('')

		// regex final para match na string
		regexQuery = new RegExp(`^${regexQuery}.*$`, 'im')
	}

	const matches = regexQuery.test(removeDiacritics(str))

	return {
		matches,
		regexQuery,
	}
}

export default queryMatch
