import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'

import * as serviceWorker from '~/serviceWorker'
import { GlobalStyle } from '~/styles'
import { Toaster } from '~/components'
import configureStore from '~/store'

import App from './app'

const store = configureStore({ cache: true, cacheReducers: [] })

ReactDOM.render(
	<Provider store={store}>
		<ThemeProvider theme={{}}>
			<GlobalStyle />
			<App isPublic />
		</ThemeProvider>
	</Provider>,
	document.getElementById('root'),
)

ReactDOM.render(<Toaster />, document.getElementById('toaster-root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
