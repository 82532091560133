import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import 'tippy.js/dist/tippy.css'
import cssVariables from './cssVariables'
import { mq } from '~/helpers/breakpoints'

export default createGlobalStyle`
  ${normalize}
  ${cssVariables}

  * {
    box-sizing: border-box;
  }

  html {
    ${mq.min('md')} {
      font-size: 85%;
    }
    ${mq.min('87.5em')} {
      font-size: 100%;
    }
    /* mq.max('20.5em') font-size: 95%;*/
  }

  html, body {
    margin: 0;
    padding: 0;
    /* height: 100vh; */
    height: 100%;
    /* height: -webkit-fill-available; */
  }

  body {
    background-color: #f5f5f5;
    color: #707070;
    font-family: 'Roboto', sans-serif;
    -webkit-tap-highlight-color: var(--color-tap);
    text-rendering: geometricPrecision;
  }

  #root {
    height: 100%;
    /* height: -webkit-fill-available; */
    z-index: 0;
    position: relative;
    display: grid;
  }

  button {
    border: 0;
    background: none;
    outline: none;
    margin: 0;
    padding: 0;
    color: inherit;
  }

  strong {
    font-weight: 500;
  }

  img {
    max-width: 100%;
  }

  p {
    margin-top: 0;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }
  .ReactModal__Overlay--after-open{
    opacity: 1;
  }
  .ReactModal__Overlay--before-close{
    opacity: 0;
  }

`
