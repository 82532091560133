import styled from 'styled-components'
import { Modal } from '~/components'
import { mq } from '~/helpers/breakpoints'

import TermsText from './termsText'

export const FormWrap = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	&& > * {
		margin-top: var(--spacing);
	}

	${mq.min('sm')} {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		&& > * {
			margin-top: 0;
		}
	}
`

export const StyledTermsText = styled(TermsText)`
	overflow: auto;
	padding: 0;
	height: unset;
	min-height: calc(100vh - var(--spacing) * 6 - 8.3rem);
	max-height: calc(100vh - var(--spacing) * 2 - 8.3rem);

	${mq.min('md')} {
		height: calc(100vh - var(--spacing) - 3rem);
		max-height: unset;
		padding: 40px;
		padding-left: 0;
	}
`

export const PrivacyTermsModal = styled(Modal)`
	width: 100vw;
	max-width: 100vw;
	max-height: unset;
	height: 100%;
	box-shadow: none;
	border: 0;
	border-radius: 0;
	transform: none;
	top: 0;
	left: 0;
	padding-bottom: 0;

	.modal-close-btn {
		display: none;
	}
	.modal-body {
		display: flex;
		flex-direction: column;
		margin: 0 auto;
		max-width: 100%;
		${mq.min('md')} {
			width: 60em;
		}
		${mq.min('lg')} {
			width: 76em;
		}
		${mq.min('xlg')} {
			width: 90em;
		}
	}
`

export const TermsBody = styled.div`
	display: flex;
	flex-direction: column;
	height: calc(100% - var(--spacing) - 3rem);

	${mq.min('md')} {
		flex-direction: row;
		height: auto;
	}
`

export const TermsHeader = styled.div`
	border-bottom: ${(props) => (props.noLine ? 'none' : '1px solid #e6e6e6')};
	color: var(--color-text-dark);
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 3rem;
	margin-bottom: var(--spacing);

	${mq.min('md')} {
		font-size: 1.2em;
		margin-bottom: 0;
	}
`
export const TermsFooter = styled.div`
	padding: var(--spacing);
	display: flex;
	align-items: flex-end;
`

export const CloseButton = styled.button.attrs({ type: 'button' })`
	cursor: pointer;
	padding: 1em 1em 0.5em 0.5em;
	font-size: 1rem;
	color: var(--close-button-color, #707070);
	transition: all 0.2s ease;
	z-index: 1;
	:hover {
		color: #444;
	}
`

export const Wrapper = styled.div`
	padding: 40px;

	ul.lst-kix_ajvo4c46knn1-5 {
		list-style-type: none;
	}

	.lst-kix_fypnr8iab89a-2 > li:before {
		content: '• ';
	}

	ul.lst-kix_ajvo4c46knn1-4 {
		list-style-type: none;
	}

	ul.lst-kix_ajvo4c46knn1-7 {
		list-style-type: none;
	}

	.lst-kix_fypnr8iab89a-1 > li:before {
		content: '• ';
	}

	.lst-kix_fypnr8iab89a-3 > li:before {
		content: '• ';
	}

	ul.lst-kix_ajvo4c46knn1-6 {
		list-style-type: none;
	}

	ul.lst-kix_ajvo4c46knn1-1 {
		list-style-type: none;
	}

	.lst-kix_fypnr8iab89a-0 > li:before {
		content: '• ';
	}

	.lst-kix_fypnr8iab89a-4 > li:before {
		content: '• ';
	}

	ul.lst-kix_ajvo4c46knn1-0 {
		list-style-type: none;
	}

	ul.lst-kix_ajvo4c46knn1-3 {
		list-style-type: none;
	}

	ul.lst-kix_ajvo4c46knn1-2 {
		list-style-type: none;
	}

	.lst-kix_fypnr8iab89a-6 > li:before {
		content: '• ';
	}

	.lst-kix_fypnr8iab89a-5 > li:before {
		content: '• ';
	}

	.lst-kix_fypnr8iab89a-7 > li:before {
		content: '• ';
	}

	.lst-kix_fypnr8iab89a-8 > li:before {
		content: '• ';
	}

	.lst-kix_wzvwujewiftj-4 > li:before {
		content: '• ';
	}

	.lst-kix_wzvwujewiftj-6 > li:before {
		content: '• ';
	}

	.lst-kix_wzvwujewiftj-1 > li:before {
		content: '• ';
	}

	.lst-kix_wzvwujewiftj-5 > li:before {
		content: '• ';
	}

	.lst-kix_wzvwujewiftj-0 > li:before {
		content: '• ';
	}

	.lst-kix_wzvwujewiftj-8 > li:before {
		content: '• ';
	}

	.lst-kix_wzvwujewiftj-7 > li:before {
		content: '• ';
	}

	.lst-kix_wzvwujewiftj-2 > li:before {
		content: '• ';
	}

	ul.lst-kix_ajvo4c46knn1-8 {
		list-style-type: none;
	}

	.lst-kix_wzvwujewiftj-3 > li:before {
		content: '• ';
	}

	ul.lst-kix_fypnr8iab89a-5 {
		list-style-type: none;
	}

	.lst-kix_ajvo4c46knn1-6 > li:before {
		content: '• ';
	}

	ul.lst-kix_fypnr8iab89a-4 {
		list-style-type: none;
	}

	ul.lst-kix_fypnr8iab89a-7 {
		list-style-type: none;
	}

	ul.lst-kix_fypnr8iab89a-6 {
		list-style-type: none;
	}

	.lst-kix_ajvo4c46knn1-5 > li:before {
		content: '• ';
	}

	ul.lst-kix_fypnr8iab89a-8 {
		list-style-type: none;
	}

	ul.lst-kix_fypnr8iab89a-1 {
		list-style-type: none;
	}

	ul.lst-kix_fypnr8iab89a-0 {
		list-style-type: none;
	}

	ul.lst-kix_fypnr8iab89a-3 {
		list-style-type: none;
	}

	ul.lst-kix_fypnr8iab89a-2 {
		list-style-type: none;
	}

	.lst-kix_ajvo4c46knn1-7 > li:before {
		content: '• ';
	}

	ul.lst-kix_wzvwujewiftj-7 {
		list-style-type: none;
	}

	ul.lst-kix_wzvwujewiftj-8 {
		list-style-type: none;
	}

	ul.lst-kix_wzvwujewiftj-5 {
		list-style-type: none;
	}

	.lst-kix_ajvo4c46knn1-8 > li:before {
		content: '• ';
	}

	ul.lst-kix_wzvwujewiftj-6 {
		list-style-type: none;
	}

	ul.lst-kix_wzvwujewiftj-3 {
		list-style-type: none;
	}

	ul.lst-kix_wzvwujewiftj-4 {
		list-style-type: none;
	}

	ul.lst-kix_wzvwujewiftj-1 {
		list-style-type: none;
	}

	ul.lst-kix_wzvwujewiftj-2 {
		list-style-type: none;
	}

	ul.lst-kix_wzvwujewiftj-0 {
		list-style-type: none;
	}

	li.li-bullet-0:before {
		margin-left: -18pt;
		white-space: nowrap;
		display: inline-block;
		min-width: 18pt;
	}

	.lst-kix_ajvo4c46knn1-3 > li:before {
		content: '• ';
	}

	.lst-kix_ajvo4c46knn1-2 > li:before {
		content: '• ';
	}

	.lst-kix_ajvo4c46knn1-4 > li:before {
		content: '• ';
	}

	.lst-kix_ajvo4c46knn1-0 > li:before {
		content: '• ';
	}

	.lst-kix_ajvo4c46knn1-1 > li:before {
		content: '• ';
	}

	ol {
		margin: 0;
		padding: 0;
	}

	table td,
	table th {
		padding: 0;
	}

	.c0 {
		border-right-style: solid;
		padding-top: 0pt;
		border-top-width: 0pt;
		border-right-width: 0pt;
		padding-left: 0pt;
		padding-bottom: 0pt;
		line-height: 1.15;
		border-top-style: solid;
		background-color: #ffffff;
		margin-left: 36pt;
		border-bottom-width: 0pt;
		border-bottom-style: solid;
		orphans: 2;
		widows: 2;
		text-align: left;
		padding-right: 0pt;
	}

	.c1 {
		background-color: #ffffff;
		padding-top: 0pt;
		padding-bottom: 11pt;
		line-height: 1.15;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	.c2 {
		color: #666666;
		font-weight: 400;
		text-decoration: none;
		vertical-align: baseline;
		font-size: 10.5pt;
		font-family: 'Arial';
		font-style: normal;
	}

	.c14 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		line-height: 1.15;
		orphans: 2;
		widows: 2;
		text-align: left;
		height: 11pt;
	}

	.c9 {
		background-color: #ffffff;
		padding-top: 0pt;
		padding-bottom: 0pt;
		line-height: 1.15;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	.c6 {
		color: #666666;
		text-decoration: none;
		vertical-align: baseline;
		font-size: 15pt;
		font-family: 'Arial';
		font-style: normal;
	}

	.c10 {
		font-weight: 400;
		vertical-align: baseline;
		font-family: 'Arial';
		font-style: normal;
	}

	.c7 {
		text-decoration: none;
		vertical-align: baseline;
		font-family: 'Arial';
		font-style: normal;
	}

	.c13 {
		color: #000000;
		text-decoration: none;
		font-size: 11pt;
	}

	.c4 {
		text-decoration-skip-ink: none;
		-webkit-text-decoration-skip: none;
		text-decoration: underline;
	}

	.c8 {
		color: inherit;
		text-decoration: inherit;
	}

	.c11 {
		padding: 0;
		margin: 0;
	}

	.c3 {
		color: #666666;
		font-size: 10.5pt;
	}

	.c5 {
		font-weight: 700;
	}

	.c5.c6 {
		margin: 20px 0 10px;
		display: block;
	}

	.title {
		padding-top: 0pt;
		color: #000000;
		font-size: 26pt;
		padding-bottom: 3pt;
		font-family: 'Arial';
		line-height: 1.15;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	.subtitle {
		padding-top: 0pt;
		color: #666666;
		font-size: 15pt;
		padding-bottom: 16pt;
		font-family: 'Arial';
		line-height: 1.15;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	li {
		color: #000000;
		font-size: 11pt;
		font-family: 'Arial';
	}

	p {
		margin: 0;
		color: #000000;
		font-size: 11pt;
		font-family: 'Arial';
	}

	h1 {
		padding-top: 20pt;
		color: #000000;
		font-size: 20pt;
		padding-bottom: 6pt;
		font-family: 'Arial';
		line-height: 1.15;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	h2 {
		padding-top: 18pt;
		color: #000000;
		font-size: 16pt;
		padding-bottom: 6pt;
		font-family: 'Arial';
		line-height: 1.15;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	h3 {
		padding-top: 16pt;
		color: #434343;
		font-size: 14pt;
		padding-bottom: 4pt;
		font-family: 'Arial';
		line-height: 1.15;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	h4 {
		padding-top: 14pt;
		color: #666666;
		font-size: 12pt;
		padding-bottom: 4pt;
		font-family: 'Arial';
		line-height: 1.15;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	h5 {
		padding-top: 12pt;
		color: #666666;
		font-size: 11pt;
		padding-bottom: 4pt;
		font-family: 'Arial';
		line-height: 1.15;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	h6 {
		padding-top: 12pt;
		color: #666666;
		font-size: 11pt;
		padding-bottom: 4pt;
		font-family: 'Arial';
		line-height: 1.15;
		page-break-after: avoid;
		font-style: italic;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
`
