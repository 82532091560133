import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { Button, Card, Modal, Title, Link, Carousel } from '~/components'
import { path as FaqPath } from '~/pages/faqPage'
import { getViewPortSize } from '~/store/reducers/ui/selectors'
import { breakpointMatch } from '~/helpers/breakpoints'

import '~/styles/slick.css'

import DocsBox from './docsBox'
import DocsList from './docsBox/docsList'
import { StyledDarkBox } from './styles'

const ImportantInfo = () => {
	const vp = useSelector(getViewPortSize)
	const { isMobile } = breakpointMatch(vp)

	// modal state
	const [docsModalOpen, setDocsModalOpen] = useState(false)

	return (
		<>
			<StyledDarkBox>
				<Title>Informações Importantes</Title>
				<Carousel>
					<Card center>
						<Title>Prepare-se!</Title>
						Nesta fase, será necessário ter em mãos seus documentos. Para saber
						mais,{' '}
						{isMobile ? (
							<>
								clique abaixo em <em>Lista de Documentos</em>
							</>
						) : (
							<>veja a lista abaixo</>
						)}
						.
					</Card>
					<Card center>
						<Title>Comprovação</Title>
						Todos os documentos deverão ser enviados digitalizados ou
						fotografados.
						<small style={{ display: 'block', color: '#B5B5B5' }}>
							(você pode usar apps de scanner)
						</small>
					</Card>
					{!isMobile && (
						<Card center>
							<Title>Dúvidas Frequentes</Title>
							Em caso de dúvidas, visite a página de perguntas frequentes{' '}
							<Link to={`${FaqPath}?ns=0`}>clicando aqui</Link>
						</Card>
					)}
				</Carousel>
			</StyledDarkBox>

			{isMobile ? (
				<>
					<Button large onClick={() => setDocsModalOpen(true)}>
						Lista de documentos
					</Button>
					<Modal
						isOpen={docsModalOpen}
						onRequestClose={() => setDocsModalOpen(false)}
						renderHeader={() => <>Lista de documentos</>}
						renderFooter={() => (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									width: '100%',
								}}
							>
								<Button onClick={() => setDocsModalOpen(false)} primary large>
									Fechar
								</Button>
							</div>
						)}
					>
						<DocsList />
					</Modal>
				</>
			) : (
				<DocsBox />
			)}
		</>
	)
}

export default ImportantInfo
