import styled from 'styled-components'
import { DarkBox, TextInput } from '~/components'
import { mq } from '~/helpers/breakpoints'

export const FaqWrapper = styled.div`
	${mq.min('md')} {
		max-width: 700px;
		margin: 0 auto;
	}
`

export const StyledDarkBox = styled(DarkBox)`
	--title-size: 1rem;
	--title-weight: 400;

	padding-bottom: var(--spacing);
	margin-top: var(--spacing);
	${mq.min('md')} {
		margin-top: calc(var(--spacing) * 2);
	}

	&::after {
		height: 100%;
	}
`

export const StyledTextInput = styled(TextInput)`
	height: 2.8em;
	border-radius: var(--border-radius);

	&::after,
	.labelText {
		display: none;
	}
	.input {
		:focus,
		:not(.empty) {
			padding-top: 0.7em;
		}
		:-webkit-autofill {
			padding-top: 0.7em;
		}

		::placeholder {
			opacity: 1;
			font-weight: 300;
		}
	}
`
