import Webcam from 'react-webcam'
import styled, { css } from 'styled-components'
import { mq } from '~/helpers/breakpoints'

export const Container = styled.div`
	text-align: center;
`

export const Wrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;

	${(props) =>
		!props.$cameraIsOn
			? css`
					&::after {
						content: 'Carregando...';
						position: absolute;
						left: 50%;
						bottom: 10%;
						transform: translateX(-50%);
						color: #fff;
					}
			  `
			: props.$counter &&
			  css`
					&::after {
						content: '${props.$counter}';
						position: absolute;
						left: 50%;
						bottom: 10%;
						transform: translateX(-50%);
						color: #fff;
						font-size: 2em;
					}
			  `}
`

export const StyledWebcam = styled(Webcam)`
	width: 440px;
	${mq.min('xlg')} {
		width: 600px;
	}

	${(props) =>
		props.$cameraIsOn
			? css`
					height: auto;
			  `
			: css`
					height: 0;
					padding-top: 75%;
			  `}

	max-height: calc(100vh - var(--spacing) * 12);
	max-width: 100%;

	background: #000;
`

export const PersonPlaceholder = styled.div`
	background-image: url("data:image/svg+xml,%3Csvg viewBox='-50 -10 227.9 197.3' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cmask id='a'%3E%3Cpath fill='%23fff' d='M-50-10h227.9v197.3H-50z'/%3E%3Cpath d='M62.9 39.6c-15.4-.3-27.7 14-30.6 29.3-2.9 14 .1 28.8 4.9 42 2 6.9 9.3 10.8 8 18.6.8 8.4-6.2 13.7-12 17.7-10.1 7.1-24 6.1-32.9 15.7-6.5 6.2-7.4 16-10.3 24.3l147.9.1c-3.4-9.2-4.4-20.6-13-26.5-10.4-8.4-25.3-6.1-35.3-15.8-6.4-3.9-11.3-11.2-9-19.4 1.5-6.8 7.6-11.3 9.2-18.3 5.5-16.6 7.9-36.5-1.5-52A29.8 29.8 0 0063 39.5z'/%3E%3C/mask%3E%3C/defs%3E%3Cpath mask='url(%23a)' d='M-50-10h227.9v197.3H-50z'/%3E%3Cpath stroke='%23fff' fill='transparent' d='M62.9 39.6c-15.4-.3-27.7 14-30.6 29.3-2.9 14 .1 28.8 4.9 42 2 6.9 9.3 10.8 8 18.6.8 8.4-6.2 13.7-12 17.7-10.1 7.1-24 6.1-32.9 15.7-6.5 6.2-7.4 16-10.3 24.3l147.9.1c-3.4-9.2-4.4-20.6-13-26.5-10.4-8.4-25.3-6.1-35.3-15.8-6.4-3.9-11.3-11.2-9-19.4 1.5-6.8 7.6-11.3 9.2-18.3 5.5-16.6 7.9-36.5-1.5-52A29.8 29.8 0 0063 39.5z'/%3E%3C/svg%3E");
	background-size: auto 123%;
	background-repeat: no-repeat;
	background-position: bottom;

	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	opacity: 0.3;
`
