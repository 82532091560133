import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import history from '~/helpers/history'

function Link({ to, href, target, ...props }) {
	const handleClick = () => to && history.push(to)

	return to || props.onClick || props.disabled ? (
		<A as="button" type="button" onClick={handleClick} {...props} />
	) : (
		<A href={href} target={target} {...props} />
	)
}

const A = styled.a`
	border: 0;
	outline: 0;
	display: inline;
	color: #31a8ff;
	text-decoration: underline;
	text-underline-offset: 1px;
	cursor: pointer;
	text-align: start;

	:hover,
	:focus {
		color: ${darken(0.2, '#31a8ff')};
	}
`

export default styled(Link)``
