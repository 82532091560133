import IconBellWarning from '~/svgs/iconBellWarning'
import IconData from '~/svgs/iconData'
import IconNameTag from '~/svgs/iconNameTag'
import IconNewDoc from '~/svgs/iconNewDoc'
import IconPersona from '~/svgs/iconPersona'

export const stages = [
	{
		title: 'Pré-cadastro',
		path: '/pre-cadastro',
		icon: IconPersona,
	},
	{
		title: 'Dados cadastrais',
		path: '/dados-cadastrais',
		icon: IconData,
		children: [
			{
				title: 'Dados pessoais',
				description: 'Nome, filiação, data de nascimento...',
				path: '/dados-cadastrais/dados-pessoais',
			},
			{
				title: 'Endereço e contato',
				description: 'Endereço completo, telefone principal...',
				path: '/dados-cadastrais/enderecos-contatos',
			},
			{
				title: 'Formação',
				description: 'Instituição, curso, datas de início...',
				path: '/dados-cadastrais/formacao',
			},
			{
				title: 'Família',
				description: 'Dados e documentos da família',
				path: '/dados-cadastrais/familia',
			},
		],
	},
	{
		title: 'Documentos não aprovados',
		path: '/documentos-nao-aprovados',
		icon: IconBellWarning,
	},
	{
		title: 'Ações complementares',
		path: '/acoes-complementares',
		icon: IconNewDoc,
		children: [
			{
				title: 'Exame ocupacional',
				description: 'Download e envio do exame admissional',
				path: '/acoes-complementares/exame-ocupacional',
			},
			{
				title: 'Encaminhamento bancário',
				description: 'Informações da conta bancária',
				path: '/acoes-complementares/encaminhamento-bancario',
			},
			{
				title: 'Vale-transporte',
				description: 'Passagens e valores de translado',
				path: '/acoes-complementares/vale-transporte',
			},
			{
				title: 'Uniforme',
				description: 'Tamanho das peças a utilizar',
				path: '/acoes-complementares/uniforme',
			},
		],
	},
	{
		title: 'Foto',
		path: '/foto-cracha',
		icon: IconNameTag,
	},
]

const menu = [
	{
		title: 'Home',
		path: '/',
		children: stages,
	},
	{
		title: 'Perguntas Frequentes',
		path: '/perguntas-frequentes',
	},
	{
		title: 'Sobre',
		path: '/sobre',
	},
	{
		title: 'Privacidade',
		path: '/privacidade',
	},
]

export default menu
