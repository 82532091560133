import React, { useRef } from 'react'
import { Field } from 'react-final-form'

import { composeValidators } from '~/helpers/validators'
import useSetFieldData from '~/hooks/useSetFieldData'

import FieldInfo from '../fieldInfo'
import TextInput from '../inputs/textInput'

function TextField({
	name,
	validators = [],
	formatter,
	parser,
	fieldProps,
	helperText,
	forceErrorUntilChange,
	...props
}) {
	const inputRef = useRef(null)
	useSetFieldData(name, { inputRef, label: props.label })

	// TODO F: criar sistema para identificar se cursor está no fim ou no meio da palavra. se estiver no meio, desabilitar formatter (pesquisar context e custom hook)

	return (
		<Field
			name={name}
			{...fieldProps}
			validate={composeValidators(...validators)}
			format={formatter}
			parse={parser}
		>
			{({ input, meta }) => {
				const error =
					(!meta.modified && forceErrorUntilChange) ||
					(typeof meta.error === 'string'
						? (input.value || meta.submitFailed) && meta.touched && meta.error
						: (input.value || meta.submitFailed) &&
						  (meta.error?.showUntouched || meta.touched) &&
						  meta.error?.message)
				return (
					<div>
						<TextInput
							{...input}
							{...props}
							error={error}
							valid={input.value && meta.valid}
						/>
						<FieldInfo
							helperText={helperText}
							error={!props.disabled && error}
						/>
					</div>
				)
			}}
		</Field>
	)
}

export default TextField
