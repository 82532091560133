/* eslint-disable indent */
import clsx from 'clsx'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { HamburgerSpin } from 'react-animated-burgers'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import menu from '~/config/menu'
import { logout } from '~/store/reducers/user/actions'
import { getStages } from '~/store/reducers/stages/selectors'
import Link from '../link'

const MobileMenu = () => {
	const [menuOpen, setMenuOpen] = useState(false)
	const dispatch = useDispatch()
	const { currentStages } = useSelector(getStages)
	const { pathname } = useLocation()

	return (
		<WrapperMenu
			menuOpen={menuOpen}
			onClick={() => menuOpen && setMenuOpen((s) => !s)}
		>
			<HamburgerButton
				isActive={menuOpen}
				toggleButton={() => !menuOpen && setMenuOpen((s) => !s)}
			/>
			<Menu
				isOpen={menuOpen}
				onClick={() => menuOpen && setMenuOpen((s) => !s)}
			>
				<Main>
					{menu.map((item) => (
						<React.Fragment key={item.path}>
							<MenuItemLink
								current={pathname.replace(/(?!^)\/+$/, '') === item.path}
								to={item.path}
							>
								{item.title}
							</MenuItemLink>
							{item.children && (
								<>
									{item.children.map((i) =>
										currentStages?.filter(
											(cs) => cs.habilitado && cs.rota === i.path,
										).length ? (
											<MenuItemLink
												current={pathname.replace(/(?!^)\/+$/, '') === i.path}
												key={i.path}
												to={i.path}
											>
												{i.title}
											</MenuItemLink>
										) : undefined,
									)}
								</>
							)}
						</React.Fragment>
					))}
				</Main>

				<MenuItemLink
					style={{ marginBottom: '3rem' }}
					onClick={() => dispatch(logout())}
				>
					Sair
				</MenuItemLink>
			</Menu>
		</WrapperMenu>
	)
}

const WrapperMenu = styled.div`
	position: fixed;
	top: 0;
	right: -20px;
	width: 100px;
	height: 100vh;

	${({ menuOpen }) =>
		menuOpen &&
		css`
			width: 100%;
		`}
`

const HamburgerButton = styled(HamburgerSpin)`
	position: absolute;
	right: 20px;
	top: 0;
	/* padding: var(--spacing);
	margin-right: calc(var(--spacing) * -1.2); */
	transform: scale(0.7);
	font-size: 0;
	z-index: 99;

	-webkit-tap-highlight-color: transparent;
	user-select: none;
	outline: none;

	span,
	span::before,
	span::after {
		height: 3px;
	}
`

const Menu = styled.nav`
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	width: 70vw;
	transition: transform 0.5s ease, box-shadow 0.5s ease;
	transform: translateX(70vw);
	${(props) =>
		props.isOpen &&
		css`
			transform: translateX(0);
			box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.2);
		`}

	background: #F8F8F8;
	padding-top: calc(36px + var(--spacing));

	::before {
		content: '';
		font-weight: 500;
		color: var(--color-text-dark);
		position: absolute;
		top: calc(var(--spacing) * 0.3);
		left: calc(var(--spacing) * 0.3);
		right: calc(var(--spacing) * 0.3);
		padding: calc(var(--spacing) * 0.6) calc(var(--spacing) * 0.45);
		/* border-bottom: 1px solid #e6e6e6; */
		height: calc(50px - var(--spacing) * 0.4 + 1px);
		box-sizing: border-box;
	}
`
const Main = styled.div`
	flex-grow: 1;
`

const SubMenu = styled.div`
	margin-left: var(--spacing);
	margin-bottom: calc(var(--spacing) * 0.4);
	display: flex;
	flex-direction: column;
`

const MenuItemLink = styled(Link).attrs((props) => ({
	className: clsx({
		current: props.current,
	}),
}))`
	background: ${(props) => (props.current ? 'red' : 'none')};
	padding: calc(var(--spacing) * 0.78);
	width: 100%;
	font-size: 1rem;
	font-weight: 400;
	color: var(--color-text-dark);
	text-decoration: none;
	text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.1);
	transition: background-color 0.2s ease, color 0.2s ease;
	position: relative;
	border-bottom: 1px solid #dadada;

	:first-child {
		border-top: 1px solid #dadada;
	}

	&.active {
		color: blue;
	}

	/* current */
	&.current {
		font-weight: 600;
		background: #fff;
		color: var(--color-text-dark);

		::before {
			content: '';
			width: 4px;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: var(--gradient-top-bottom);
		}
	}

	${SubMenu} > & {
		padding: calc(var(--spacing) * 0.48) calc(var(--spacing) * 0.78);
	}
`

export default MobileMenu
