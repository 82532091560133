import React from 'react'
import Svg from '~/components/svg'

function IconPencil(props) {
	return (
		<Svg viewBox="10 -1 22 24" {...props}>
			<path d="M11 16.83V21h4.17L27.45 8.71 23.3 4.55zM30.68 5.5a1.1 1.1 0 000-1.56l-2.6-2.6a1.1 1.1 0 00-1.57 0l-2.03 2.03 4.16 4.16 2.03-2.03z" />
		</Svg>
	)
}

export default IconPencil
