import React from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'

// import { path as faqPath } from '~/pages/faqPage';
import { getStages } from '~/store/reducers/stages/selectors'
import { stages } from '~/config/menu'
import { mq } from '~/helpers/breakpoints'
import { getUser } from '~/store/reducers/user/selectors'

import StageLink from './stageLink'
import { Loading } from '..'
import Link from '../link'
import Button from '../button'
import Title from '../title'

const StageContainer = (props) => {
	const { revisaoGeralFinalizada, etapa } = useSelector(getUser)
	const { loading, currentStages } = useSelector(getStages)

	// verifica se todas as etapas estão concluídas (porcentagem === 1)
	const readyToFinish =
		currentStages?.length > 1 &&
		currentStages?.reduce(
			(ready, s) => (ready === false ? false : s.porcentagem === 1),
			undefined,
		)

	return (
		<Wrapper {...props}>
			<Header>
				<Title>Cadastro</Title>
				{!revisaoGeralFinalizada && (
					<Button to="/revisao" large primary disabled={!readyToFinish}>
						Revisar para enviar
					</Button>
				)}
			</Header>
			<StageGrid isLoading={loading} totalStages={currentStages?.length}>
				{loading ? (
					<Loading />
				) : (
					<>
						{currentStages?.map((cs) => {
							const Icon = stages.filter((s) => s.path === cs.rota)?.[0]?.icon
							return (
								<StageLink
									key={cs.id}
									name={cs.nome}
									Icon={Icon}
									percent={cs.porcentagem}
									enabled={cs.habilitado}
									error={cs.aprovado === false}
									to={cs.rota}
								/>
							)
						})}
						<Instruction>
							{['CadastroFinalizado', 'ProcessoEncerrado'].includes(etapa) ? (
								// finalizado (não há mais o que fazer)
								<div>
									Obrigado por finalizar o processo. Você será contatado para
									mais informações.
								</div>
							) : ['ValidacaoEsocial'].includes(etapa) ? (
								// não há nenhuma etapa disponível para o usuário, ele deve aguardar
								<>
									<div
										style={{
											fontWeight: 600,
											color: '#000',
											fontSize: '1.1em',
											lineHeight: '1.8',
										}}
									>
										Aguarde!
									</div>
									<div style={{ lineHeight: '1.2' }}>
										Estamos validando seus dados, aguarde novas
										<br /> informações para a próxima etapa no seu e-mail.
									</div>
								</>
							) : (
								// texto default
								<>
									<div>
										Após completar o preenchimento, aguarde a próxima fase.
									</div>
									{/* Link oculto enquanto não houver um item do faq relacionado a explicar o processo */}
									{/* <Link to={faqPath}>Saiba mais</Link> */}
								</>
							)}
						</Instruction>
					</>
				)}
			</StageGrid>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	margin-bottom: calc(2 * var(--spacing));
	width: 100%;
	display: flex;
	flex-direction: column;

	${mq.min('md')} {
		height: 100%;
		margin: 0;
	}
`

const Header = styled.div`
	order: 1;
	--title-size: 0;

	${mq.min('md')} {
		order: 0;
		--title-size: 1.8rem;

		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #ddd;
		padding-bottom: calc(var(--spacing) * 0.5);
		margin-bottom: calc(var(--spacing) * 1.3);

		${Title} {
			margin: 0;
		}
		${Button} {
			margin: 0;
		}
	}
`

const StageGrid = styled.div`
	display: grid;
	margin-bottom: 2rem;

	${({ isLoading }) =>
		isLoading
			? css`
					place-items: center;
					height: 13.8rem;
			  `
			: css`
					grid-template-columns: 1fr 1fr;
					grid-auto-rows: 6.6rem;
					gap: 0.6rem;

					${mq.min('md')} {
						grid-template-columns: repeat(auto-fill, 14rem);
						grid-auto-rows: 9.6rem;
						gap: 1.5rem;
					}
			  `}

	${({ totalStages }) =>
		totalStages === 2 &&
		css`
			grid-auto-rows: 6.6rem 1.5rem;
		`}

	${({ totalStages }) =>
		totalStages === 4 &&
		css`
			grid-auto-rows: 6.6rem 6.6rem 1.5rem;
		`}
	
	${StageLink} {
		font-size: 0.8rem;
		${mq.min('md')} {
			font-size: 1rem;
		}

		padding: calc(var(--spacing) * 1) calc(var(--spacing) * 0.9)
			calc(var(--spacing) * 1.3);

		.status-icon {
			left: auto;
			right: 5px;
			top: 5px;
			transform: none;

			${mq.min('md')} {
				--status-icon-size: 1.2;
				right: calc(var(--spacing) * 0.7);
				top: calc(var(--spacing) * 0.8);
			}
		}
	}
`

const Instruction = styled.div`
	padding: calc(0.4 * var(--spacing));
	color: #959595;
	font-size: 0.7rem;
	line-height: 1.4;

	${Link} {
		font-size: 0.85rem;
		float: right;
		margin-top: 0.6rem;
		text-align: right;
	}

	&:nth-child(2n + 1) {
		padding: calc(0.4 * var(--spacing)) calc(0.2 * var(--spacing));
		font-size: 0.73rem;
		grid-column-end: 3;
		grid-column-start: 1;

		${Link} {
			float: left;
		}
	}

	${mq.min('md')} {
		&:nth-child(n) {
			position: absolute;
			bottom: calc(var(--spacing) * 2);
			left: 0;
			right: 0;
			text-align: center;
			font-size: 1.1rem;
			${Link} {
				font-size: 1rem;
				float: none;
			}
		}
	}
`

export default StageContainer
