import styled from 'styled-components'

export const BorderLessBtn = styled.button.attrs({ type: 'button' })`
	cursor: pointer;
	transition: transform 0.2s ease;
	:hover {
		transform: scale(1.1);
	}
	:focus-visible {
		transform: scale(1.1);
	}
	:active {
		transform: scale(0.9);
	}
`
