import React, { useCallback, useEffect, useState } from 'react'
import { FormSpy } from 'react-final-form'
import { delay } from '~/helpers/delay'

const FormIsntComplete = ({ children }) => {
	const [render, setRender] = useState(false)

	useEffect(() => {
		;(async () => {
			await delay(500) // delay "gambiarra" para aguardar form estar registrado
			setRender(true)
		})()
	}, [])

	const calculateNRender = useCallback(
		({ form }) => {
			const registeredFields = form.getRegisteredFields()
			// console.log('registeredFields',registeredFields)

			return registeredFields.reduce((prev, f) => {
				if (prev === false) return false

				const fieldState = form.getFieldState(f)
				return fieldState.data?.type === 'attachment'
					? Boolean(fieldState.value?.uuid)
					: (fieldState.value ||
							fieldState.data?.type === 'checkbox' ||
							(fieldState.data?.type === 'radio' &&
								fieldState.value === false)) &&
							fieldState.valid
			}, undefined)
				? null
				: children
		},
		[children],
	)

	return render ? (
		// eslint-disable-next-line react/no-children-prop
		<FormSpy subscription={{ values: true }} children={calculateNRender} />
	) : null
}

export default FormIsntComplete
