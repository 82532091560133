import styled from 'styled-components'

const FormWrapper = styled.form`
	width: 100%;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	max-width: 500px;
	margin: 0 auto;
`

export default FormWrapper
