import React from 'react'
import Svg from '~/components/svg'

function IconCamera(props) {
	return (
		<Svg viewBox="0 0 215.81 170.51" {...props}>
			{/* <path d="M3.35 20.5a2.87 2.87 0 01-2-.84 2.73 2.73 0 01-.85-2.01V5.62a2.87 2.87 0 01.85-2 2.73 2.73 0 012-.84h3.99v-.05A2.23 2.23 0 019.58.5h7.25a2.21 2.21 0 011.57.66 2.17 2.17 0 01.65 1.57v.05h4.01a2.89 2.89 0 012.85 2.85v12.02a2.87 2.87 0 01-.84 2 2.73 2.73 0 01-2 .85zM2.24 4.51a1.55 1.55 0 00-.46 1.11v12.03a1.57 1.57 0 001.57 1.57h19.71a1.57 1.57 0 001.57-1.57h.03V5.62a1.57 1.57 0 00-1.57-1.57h-4.67a.65.65 0 01-.65-.65v-.7a.9.9 0 00-.94-.94H9.61a.9.9 0 00-.94.94v.7a.65.65 0 01-.66.65H3.35a1.55 1.55 0 00-1.11.46zM9 15.84a5.94 5.94 0 1110.15-4.2 5.89 5.89 0 01-1.74 4.2 5.95 5.95 0 01-8.4 0zm.92-7.47a4.72 4.72 0 00-1.35 3.29 4.62 4.62 0 001.35 3.28 4.67 4.67 0 006.57 0 4.52 4.52 0 001.35-3.28 4.61 4.61 0 00-1.35-3.29 4.72 4.72 0 00-3.28-1.35 4.62 4.62 0 00-3.29 1.35zm10.7-1.52a1.18 1.18 0 111.18 1.19 1.18 1.18 0 01-1.18-1.19z" /> */}
			<path d="M25.56 170.06a25.5 25.5 0 01-17.39-7.34c-4.7-4.54-7.43-11-7.4-17.57V44.25a25.73 25.73 0 017.39-17.47 24.3 24.3 0 0117.41-7.34H57.9V20a20.13 20.13 0 015.78-13.96A19.95 19.95 0 0177.6.31h60.54c5.12.05 10.19 2.2 13.81 5.83a19.86 19.86 0 015.71 13.3h-.99 1v.5-.5h32.5c6.74.13 13.01 2.95 17.43 7.38a25.7 25.7 0 017.35 17.52v100.82a25.71 25.71 0 01-7.3 17.47 24.3 24.3 0 01-17.4 7.43H25.55zM17.02 35.65a11.5 11.5 0 00-3.55 8.6v100.9a11.74 11.74 0 003.54 8.6 11.62 11.62 0 008.56 3.57h164.59c3.41.04 6.31-1.3 8.56-3.56 2.24-2.26 3.83-4.94 3.78-8.37l.02-1.24v-99.9c.04-3.43-1.3-6.34-3.55-8.6a11.62 11.62 0 00-8.57-3.56h-38.99a6.88 6.88 0 01-4.55-1.9 6.92 6.92 0 01-1.88-4.56v-5.87c.14-2-.6-3.66-1.9-4.97a6.05 6.05 0 00-4.9-1.9H77.84a6.15 6.15 0 00-4.94 1.9 6.14 6.14 0 00-1.9 4.93v5.91a6.92 6.92 0 01-1.92 4.6 6.87 6.87 0 01-4.61 1.86h-38.9a11.36 11.36 0 00-8.55 3.56zm55.02 95.03a50.94 50.94 0 01-12.98-49.12 50.68 50.68 0 0135.86-35.85c15.97-4.2 32-.3 43.8 8.81a51.05 51.05 0 0119.78 40.22 51.12 51.12 0 01-14.81 35.94 50.86 50.86 0 01-35.78 14.85 50.85 50.85 0 01-35.78-14.85l.3.3h-.1l-.29-.3zm9.1-62.66a38.11 38.11 0 00-10.98 26.9 37.28 37.28 0 0010.98 26.8 37.36 37.36 0 0026.72 11.03c9.74 0 19.3-3.6 26.73-11.04a36.47 36.47 0 0010.98-26.78 37.24 37.24 0 00-10.98-26.9A37.75 37.75 0 00107.9 57a36.92 36.92 0 00-26.76 11.02zm87.64-13.46a11.25 11.25 0 015.5-9.39 11.17 11.17 0 0110.86.1 11.27 11.27 0 015.35 9.48c-.03 3.55-2 7.3-5.5 9.39-1.6.89-3.53 1.41-5.36 1.41-2.94 0-5.8-1.29-7.7-3.22a11.34 11.34 0 01-3.15-7.77z" />
		</Svg>
	)
}

export default IconCamera
