import React from 'react'
import { nanoid } from 'nanoid'
import Svg from '~/components/svg'
import { themeColors } from '~/styles/cssVariables'

function IconNewDoc(props) {
	const id = `id${nanoid()}`

	return (
		<Svg viewBox="4 2 19 20" {...props}>
			<linearGradient
				id={id}
				x1="0.5"
				x2="1"
				y1="1"
				y2="0"
				gradientUnits="objectBoundingBox"
			>
				{themeColors.map((c, i, arr) => (
					<stop
						key={`key-${i + c}`}
						offset={i === arr.length - 1 ? 0.9 : i / (arr.length - 1)}
						stopColor={c}
					/>
				))}
			</linearGradient>

			<path
				d="M19.3 23.61a3.49 3.49 0 00.74 1.24.73.73 0 01-.43.15H6.75a.75.75 0 01-.75-.74V7.58a.74.74 0 01.75-.75H8.8V5.74A.74.74 0 019.54 5h9.9l1.1 1.18h-.01v2.08a.14.14 0 00.14.13h1.43l1.05 1.2v9.45a3.58 3.58 0 00-.5-.04 3.53 3.53 0 00-.55.05V9.58h-1.89a.76.76 0 01-.77-.75V6.18H9.98a.13.13 0 00-.13.13v15.54a.13.13 0 00.13.14h9.18a3.27 3.27 0 00.02 1.18H9.54a.74.74 0 01-.74-.75V8.02H7.18a.13.13 0 00-.13.13v15.54a.13.13 0 00.13.13h11.99a.13.13 0 00.13-.13zm-.58-4.41v-.05a.5.5 0 00-.5-.5h-2.19a.5.5 0 00-.5.5v.05a.5.5 0 00.5.5h2.19a.5.5 0 00.5-.5zm.26-4.2v-.05a.5.5 0 00-.5-.5h-6.43a.5.5 0 00-.5.5V15a.5.5 0 00.5.5h6.43a.5.5 0 00.5-.5zm-1.59-2.1v-.05a.5.5 0 00-.5-.5h-4.84a.5.5 0 00-.5.5v.05a.5.5 0 00.5.5h4.84a.5.5 0 00.5-.5zm-4.25-3.15V9.7a.5.5 0 00-.5-.5h-.59a.5.5 0 00-.5.5v.05a.5.5 0 00.5.5h.6a.5.5 0 00.5-.5zm11.27 12.17h-1.18v-1.17a.59.59 0 00-1.17 0v1.17h-1.18a.58.58 0 100 1.16h1.18v1.17a.59.59 0 001.17 0v-1.17h1.18a.58.58 0 100-1.16z"
				transform="translate(-2 -3)"
				fill={props.color || `url(#${id})`}
			/>
		</Svg>
	)
}

export default IconNewDoc
