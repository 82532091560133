import { createStore, applyMiddleware, compose } from 'redux'
import throttle from 'lodash/throttle'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import rootReducer from './reducers'
import { saveStateByKey, loadStateByKey } from '~/helpers/localStorage'

// production middlewares and compose
const middlewares = [thunk]
let composeEnhancers = compose

// if dev, append dev tools
if (process.env.NODE_ENV === 'development') {
	middlewares.push(logger)
	composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
}

const configureStore = ({
	initialStore,
	cache = false,
	cacheReducers = [],
}) => {
	initialStore = initialStore || loadStateByKey(cacheReducers)

	const store = createStore(
		rootReducer,
		initialStore,
		composeEnhancers(applyMiddleware(...middlewares)),
	)
	// save state on localstorage
	if (cache) {
		store.subscribe(
			throttle(() => {
				const state = store.getState()
				saveStateByKey(state, cacheReducers)
			}, 1000),
		)
	}

	return store
}

export default configureStore
