import React, { useEffect, useRef, useState } from 'react'

import gsap from '~/helpers/gsap'
import IconArrow from '~/svgs/iconArrow'

import { Body, Header, Wrapper } from './styles'

function Accordion({ label, children, open }) {
	// ------ Abrindo e fechando grupo ------
	const [active, setActive] = useState(false)
	const bodyRef = useRef(null)

	// abrindo/fechando de acordo com prop
	useEffect(() => {
		setActive(open)
	}, [open])

	const handleClick = () => setActive((a) => !a)

	// abrir e fechar o accordion
	useEffect(() => {
		if (active)
			gsap.to(bodyRef.current, {
				duration: 0.4,
				ease: 'none',
				height: 'auto',
				paddingTop: '.5rem',
				paddingBottom: '.5rem',
			})
		else
			gsap.to(bodyRef.current, {
				duration: 0.4,
				ease: 'none',
				height: 0,
				paddingTop: 0,
				paddingBottom: 0,
			})
	}, [active])

	return (
		<Wrapper>
			<Header onClick={handleClick}>
				<div>{label}</div>
				<IconArrow dir={active ? 'left' : 'bottom'} />
			</Header>
			<Body ref={bodyRef}>
				<div>{children}</div>
			</Body>
		</Wrapper>
	)
}

export default Accordion
