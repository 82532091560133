/* eslint-disable no-shadow */
import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import { useSelector } from 'react-redux'

import LoginLayout from '~/layouts/loginLayout'
import {
	BackButton,
	Button,
	EmailField,
	FieldWrapper,
	FormBody,
	FormError,
	FormFooter,
	FormWrapper,
	Modal,
	Text,
	Title,
} from '~/components'
import { required } from '~/helpers/validators'
import api from '~/services/api'
import handleReqError from '~/helpers/handleReqError'
import { path as LoginPath } from '~/pages/loginPage'
import Check from '~/svgs/check'
import { getViewPortSize } from '~/store/reducers/ui/selectors'
import { breakpointMatch } from '~/helpers/breakpoints'
import history from '~/helpers/history'

export const title = 'Recuperar senha'
export const path = '/recuperar-senha'
export const isPublic = true

const RecoveryPage = () => {
	const vp = useSelector(getViewPortSize)
	const { isDesktop, isMobile } = breakpointMatch(vp)

	const [emailSent, setEmailSent] = useState(undefined)

	const handleSubmit = async (values) => {
		try {
			const { data } = await api.password.recover({
				values: {
					email: values.email,
				},
			})
			setEmailSent(data?.email)
			return true
		} catch (err) {
			return {
				[FORM_ERROR]: handleReqError(err, {
					404: 'E-mail não cadastrado',
				}),
			}
		}
	}

	return (
		<LoginLayout>
			<div style={{ width: '100%', padding: '1rem 0.5rem' }}>
				<BackButton
					onClick={() =>
						emailSent && isMobile ? setEmailSent(undefined) : history.goBack()
					}
				/>
			</div>

			{emailSent && isMobile ? (
				// submit succeeded
				<div
					style={{
						width: '100%',
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						marginTop: '1rem',
					}}
				>
					<div
						style={{
							padding: '0 1.25rem',
							flexGrow: 1,
							justifyContent: 'start',
							alignItems: 'center',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Check style={{ width: 170 }} />
						<Text center style={{ marginTop: 'calc(2 * var(--spacing))' }}>
							<Title size="1.2rem">E-mail enviado com sucesso</Title>
							<p>
								Enviamos um e-mail para <strong>{emailSent}</strong> com um link
								para redefinir sua senha
							</p>
						</Text>
					</div>
					<Button to={LoginPath} primary large>
						Voltar à tela de login
					</Button>
				</div>
			) : (
				<>
					<Form
						onSubmit={handleSubmit}
						subscription={{
							submitting: true,
							pristine: true,
							submitError: true,
							values: true,
						}}
					>
						{({ handleSubmit, submitError, submitting }) => (
							<FormWrapper onSubmit={handleSubmit}>
								<FormBody style={{ padding: '0 1.25rem' }}>
									<Text
										style={{
											textAlign: isDesktop ? 'left' : 'center',
											maxWidth: '14rem',
											margin: isDesktop ? '2rem 0' : '2rem auto',
										}}
									>
										<Title as="h2" size="1.3rem">
											Recuperação de senha
										</Title>
										<p>Informe seu e-mail para o reenvio da senha</p>
									</Text>

									<FieldWrapper>
										<EmailField
											name="email"
											label="E-mail"
											validators={[required]}
										/>
									</FieldWrapper>
								</FormBody>

								<FormFooter>
									{submitError && <FormError error={submitError} />}

									<Button type="submit" primary large submitting={submitting}>
										Enviar
									</Button>
								</FormFooter>
							</FormWrapper>
						)}
					</Form>
					{emailSent ? (
						<Modal
							isOpen={Boolean(emailSent)}
							onRequestClose={() => setEmailSent(undefined)}
							renderFooter={() => (
								<Button to={LoginPath} primary large>
									OK, voltar à tela de login
								</Button>
							)}
						>
							<p
								style={{
									color: 'var(--color-text-dark)',
									textAlign: 'center',
									marginBottom: 'var(--spacing)',
								}}
							>
								<strong>E-mail enviado com sucesso!</strong>
							</p>
							<p style={{ width: 400, lineHeight: 1.4, textAlign: 'center' }}>
								Enviamos um e-mail para <strong>{emailSent}</strong> com um link
								para redefinir sua senha
							</p>
						</Modal>
					) : undefined}
				</>
			)}
		</LoginLayout>
	)
}

export default RecoveryPage
