import React from 'react'
import Svg from '~/components/svg'

function IconTrash(props) {
	return (
		<Svg viewBox="11 0 20 22" {...props}>
			<path d="M27.75 3.83h-2.66v-1.2A1.64 1.64 0 0023.46 1h-4.91a1.64 1.64 0 00-1.64 1.64v1.2h-2.66A2.25 2.25 0 0012 6.08v2.05a.61.61 0 00.61.6h1.03v10.02A2.25 2.25 0 0015.89 21H26.1a2.25 2.25 0 002.26-2.25V8.74h1.02a.61.61 0 00.61-.61V6.08a2.25 2.25 0 00-2.25-2.25zm-9.2-1.49h4.9v1.5h-4.9zm1.32 14.49a.99.99 0 01-1.97 0v-5.4a.99.99 0 011.97 0zm4.24 0a.99.99 0 01-1.97 0v-5.4a.99.99 0 011.97 0z" />
		</Svg>
	)
}

export default IconTrash
