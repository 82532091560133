/* eslint-disable indent */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { hideVisually } from 'polished'
import clsx from 'clsx'
import { useForm } from 'react-final-form'
import { useValuesSubscription } from 'react-final-form-values-subscriptions'

import gsap from '~/helpers/gsap'
import { isDOMElement } from '~/helpers/dom'
import IconArrow from '~/svgs/iconArrow'
import { FIELDWRAPPER_SHOULD_RENDER_TRANSITION_MS } from '~/config/constants'

import FieldInfo from './fieldInfo'

function FieldGroup({
	label,
	name,
	children,
	helperText,
	mainField,
	forceErrorOnMount,
	unsetForcedErrorOn,
	// ...props
}) {
	// ------ lógica para pegar o estado dos campos do grupo ------
	const { getRegisteredFields, getFieldState } = useForm()

	const getGroupFieldsStates = useCallback(() => {
		const pattern = new RegExp(`^${name.replace('[', '\\[')}\\..*`)
		const fieldsStates = getRegisteredFields()
			.filter((f) => pattern.test(f))
			.map((f) => getFieldState(f))

		return fieldsStates
	}, [name, getRegisteredFields, getFieldState])

	// ------ Abrindo e fechando grupo ------

	const [active, setActive] = useState(false)
	const bodyRef = useRef(null)

	const handleClick = () => setActive((a) => !a)

	// abrir e fechar o grupo
	useEffect(() => {
		if (active) {
			gsap.set(bodyRef.current, { visibility: 'visible' })
			gsap.to(bodyRef.current, {
				duration: 0.4,
				ease: 'none',
				height: 'auto',
				paddingTop: '8px',
				paddingBottom: '8px',
				onComplete: () => gsap.set(bodyRef.current, { overflow: 'visible' }),
			})
		} else {
			gsap.set(bodyRef.current, { visibility: 'hidden', overflow: 'hidden' })
			gsap.to(bodyRef.current, {
				duration: 0.4,
				ease: 'none',
				height: 0,
				paddingTop: 0,
				paddingBottom: 0,
			})
		}
	}, [active])

	// ------ Inicializa valor atribuído ao grupo ------

	const [mainValue, setMainValue] = useState(undefined)
	// setMainValue é chamado no useValuesSubscription

	// ------ Calcular estados do grupo relativos aos campos do grupo (válido, erro etc) ------

	const [valid, setValid] = useState(undefined)
	const [error, setError] = useState(undefined)
	const [forcedError, setForcedError] = useState(forceErrorOnMount)

	// verifica se há erros nos campos para atualizar o estado error do grupo
	const checkErrors = useCallback(
		(fieldsStates) => {
			fieldsStates = fieldsStates ?? getGroupFieldsStates()

			if (unsetForcedErrorOn && unsetForcedErrorOn(fieldsStates))
				setForcedError(false)

			const errors = fieldsStates.reduce((acc, fs) => {
				// eslint-disable-next-line no-shadow
				const error = (fs.dirty || fs.submitFailed) && fs.touched && fs.error

				return error
					? [
							...acc,
							{
								error,
								label: fs.data.label,
							},
					  ]
					: acc
			}, [])

			setError(errors?.length ? errors : undefined)
		},
		[getGroupFieldsStates, unsetForcedErrorOn],
	)

	// calcula o valor do grupo e status de válido ou de erro
	const checkGroupState = useCallback(
		(fieldsStates) => {
			fieldsStates = fieldsStates ?? getGroupFieldsStates()

			if (!fieldsStates.length) return

			// ---- atribui o valor do campo principal ao valor do grupo ----

			const mainFieldState =
				(mainField &&
					(Array.isArray(mainField)
						? mainField.reduce((fieldState, field) => {
								return (
									fieldState ??
									fieldsStates.filter((fs) => fs.name === field)?.[0]
								)
						  }, undefined)
						: fieldsStates.filter((fs) => fs.name === mainField)?.[0])) ||
				fieldsStates?.[0]

			setMainValue(
				(typeof mainFieldState.data?.displayValue === 'object'
					? mainFieldState.data?.displayValue?.current
					: mainFieldState.data?.displayValue) ??
					(mainFieldState.value?.uuid &&
						(mainFieldState.value?.file?.name || 'Arquivo anexado')) ??
					mainFieldState.data?.inputRef?.current?.value ??
					mainFieldState.value,
			)

			// ---- calcula validade do grupo de acordo com campos do grupo ----
			setValid(
				fieldsStates.reduce(
					(acc, cf) =>
						acc === false
							? false
							: Boolean(
									cf.data?.type === 'attachment'
										? Boolean(cf.value?.uuid)
										: (cf.value ||
												cf.data?.type === 'checkbox' ||
												(cf.data?.type === 'radio' && cf.value === false)) &&
												cf.valid,
							  ),
					undefined,
				),
			)

			// ---- verifica erros nos campos do grupo ----
			checkErrors(fieldsStates)
		},
		[getGroupFieldsStates, checkErrors, mainField],
	)

	// quando o valor de um campo do grupo muda, verifica se todos os campos do grupo continuam válidos ou se há erros
	useValuesSubscription({
		subscriptionPath: name,
		onChange: () =>
			setTimeout(checkGroupState, FIELDWRAPPER_SHOULD_RENDER_TRANSITION_MS),
	})

	useEffect(() => {
		const fieldsStates = getGroupFieldsStates()

		// inicializa estado do grupo ao montar componente
		checkGroupState(fieldsStates)

		// --- no blur dos campos do grupo, verifica se têm erros ---

		const handleBlur = () => checkErrors()

		fieldsStates.forEach(
			(f) =>
				isDOMElement(f?.data?.inputRef?.current) &&
				f.data.inputRef.current.addEventListener('blur', handleBlur),
		)

		// retira o listener do blur dos campos ao desmontar componente
		return () =>
			fieldsStates.forEach(
				(f) =>
					isDOMElement(f?.data?.inputRef?.current) &&
					f.data.inputRef.current.removeEventListener('blur', handleBlur),
			)
	}, [checkGroupState, getGroupFieldsStates, checkErrors])

	// ------ Calcular estados visuais do grupo (focus, etc) ------

	const headerRef = useRef(null)
	const [focused, setFocused] = useState(false)

	useEffect(() => {
		const header = headerRef.current
		const eventHandler = (e) => setFocused(e.type === 'focus')

		header.addEventListener('focus', eventHandler)
		header.addEventListener('blur', eventHandler)

		return () => {
			header.removeEventListener('focus', eventHandler)
			header.removeEventListener('blur', eventHandler)
		}
	}, [headerRef])

	return (
		<div className={active ? 'active' : undefined}>
			<Wrapper
				focused={focused}
				active={active}
				valid={valid}
				error={forcedError || error}
				hasmainvalue={mainValue}
			>
				<HiddenLegend>{label}</HiddenLegend>
				<Header ref={headerRef} onClick={handleClick}>
					<div>
						<Label>{label}</Label>
						{mainValue && <MainValue>{mainValue}</MainValue>}
					</div>

					<IconArrow dir={active ? 'left' : 'bottom'} />
				</Header>
				<Body ref={bodyRef}>{children}</Body>
			</Wrapper>
			<FieldInfo helperText={helperText} error={error} />
		</div>
	)
}

const Wrapper = styled.fieldset.attrs((props) => ({
	className: clsx({
		error: props.error,
		active: props.active,
		hasmainvalue: props.hasmainvalue,
		focused: props.focused,
	}),
}))`
	--input-line-thickness: 1px;

	background-color: #fff;
	border: 0;
	border-radius: var(--border-radius) var(--border-radius) 0 0;
	position: relative;
	font-size: 1.05rem;
	padding: 0;
	min-width: 100%;
	transition: all 0.4s ease;

	&.active {
		box-shadow: 0 0 0 1px #efefef;
		margin-bottom: 0.5rem;
	}

	&.focused,
	&.active {
		box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
	}

	&::after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 2px;

		${({ focused, active, error, valid }) =>
			error
				? css`
						background: var(--color-error);
				  `
				: valid
				? css`
						background: var(--color-success);
				  `
				: active || focused
				? css`
						background: var(--gradient);
				  `
				: css`
						background: #b5b5b5;
				  `}
	}
`

const HiddenLegend = styled.legend`
	${hideVisually()}
	font-size: 1px;
	color: transparent;
`

const Header = styled.button.attrs({ type: 'button' })`
	padding: calc(0.7em - 1px);
	height: 3.3em;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	outline: 0;
	-webkit-appearance: auto;
	cursor: pointer;
	border-radius: var(--border-radius) var(--border-radius) 0 0;
	border: 1px solid transparent;
	transition: border 0.1s ease;

	&:focus,
	&:hover {
		--icon-scale: 1.2;
		--icon-color: #333;
	}

	${Wrapper}.error:not(.active) & {
		border: 1px solid var(--color-error);
		transition-delay: 0.4s;
	}
`

const Label = styled.div`
	color: var(--color-text-dark);
	position: absolute;
	top: 0;
	left: 0.7em;
	transform: translateY(calc(1.65em + 1px - 0.60375em));
	transform-origin: left;
	transition: all 0.3s ease;

	max-width: calc(100% - 0.8em);
	text-overflow: ellipsis;
	overflow: hidden;

	${Wrapper}.active & {
		font-weight: 500;
	}
	${Wrapper}.hasmainvalue:not(.active) & {
		top: 0.37em;
		transform: scale(0.7);
		font-weight: 600;
		letter-spacing: 0.01em;
	}
`
const MainValue = styled.div`
	text-align: left;
	white-space: nowrap;
	max-width: 85%;
	overflow: hidden;
	text-overflow: ellipsis;
	position: absolute;
	top: 30%;

	transform: translateY(0.41em);
	transition: all 0.2s ease;
	transition-delay: 0.2s;

	${Wrapper}.active & {
		transform: translateY(1em);
		opacity: 0;
		transition-delay: 0s;
	}
`

const Body = styled.div`
	height: 0;
	overflow: hidden;
	box-sizing: content-box;
	padding-left: 0.7em;
	padding-right: 0.7em;
`

export default styled(FieldGroup)``
