/* eslint-disable no-shadow */
/* eslint-disable indent */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'react-final-form'

import {
	Button,
	FieldWrapper,
	FormBody,
	FormFooter,
	FormWrapper,
	Loading,
	Text,
	CheckBoxField,
	FormSection,
	Modal,
} from '~/components'
import history from '~/helpers/history'
import MainLayout from '~/layouts/mainLayout'
import usePBVForm from '~/hooks/usePBVForm'
import { path as homePath } from '../homePage'
import { getStages } from '~/store/reducers/stages/selectors'
import ReviewAccordion from '~/components/reviewAccordion'
import { dateFieldsIsoToStr } from '~/helpers/converters'
import {
	formatCellPhone,
	formatCEP,
	formatCPF,
	formatPhone,
	formatPIS,
} from '~/helpers/formatters'
import {
	COUNTRIES,
	ESCOLARIDADES,
	ESTADOS_CIVIS,
	ETNIAS,
	FORMACOES,
	SEXES,
	TIPOS_FAMILIAR,
	TIPOS_INSTITUICAO,
} from '~/config/constants'
import ReviewAttachmentBtn from '~/components/reviewAttachmentBtn'

import { FieldTitle, FieldValue, Hr, SectionTitle } from './styles'

export const title = 'Revisão'
export const path = '/revisao'

const dateFields = [
	'preCadastro.dataNascimento',
	'dadosPessoais.rne.data_expedicao',
	'dadosPessoais.passaporte.data_chegada_brasil',
	'dadosPessoais.rg.dataEmissao',
	'dadosPessoais.ctps.dataEmissao',
	'dadosPessoais.cnh.dataValidade',
	'dadosPessoais.certificadoReservista.dataDispensa',
]

const monthFields = ['formacao.inicio', 'formacao.conclusao']

const ReviewPage = () => {
	const { currentStages } = useSelector(getStages)
	const readyToFinish = currentStages?.reduce(
		(ready, s) => (ready === false ? false : s.porcentagem === 1),
		undefined,
	)

	const {
		loading,
		error,
		initialValues: values,
		submitForm,
	} = usePBVForm(`revisaoGeral`, {
		dateFields,
		monthFields,
		afterSubmit: () => history.push(homePath),
	})

	const [confirmationVisible, setConfirmationVisibility] = useState(false)

	if (loading || readyToFinish === undefined)
		return (
			<MainLayout onGoBack={() => history.push(homePath)}>
				<Loading />
			</MainLayout>
		)

	if (error) console.log(error)

	if (readyToFinish === false) {
		return (
			<MainLayout onGoBack={() => history.push(homePath)}>
				<Text dark>
					<p>
						Você ainda não terminou de preencher seu cadastro. Por favor retorne
						à página inicial e continue.
					</p>
				</Text>
				<Button to={homePath} primary large>
					Voltar
				</Button>
			</MainLayout>
		)
	}

	return (
		<MainLayout onGoBack={() => history.push(homePath)}>
			<FormWrapper as="div">
				<FormBody>
					<Text dark>
						<p>
							Leia e revise atentamente, confirme se seus dados estão corretos
							antes de nos enviar. Caso haja algum erro, essa é a sua chance de
							alterá-lo.
						</p>
					</Text>

					<FormSection label="Pré-cadastro">
						<ReviewAccordion label="Dados pré-cadastrais">
							<FieldWrapper>
								<FieldTitle>CPF</FieldTitle>
								<FieldValue>
									{formatCPF(values?.preCadastro?.cpf) || '-'}
								</FieldValue>
							</FieldWrapper>

							<FieldWrapper>
								<FieldTitle>Nome</FieldTitle>
								<FieldValue>{values?.preCadastro?.nome || '-'}</FieldValue>
							</FieldWrapper>

							<FieldWrapper>
								<FieldTitle>Data de Nascimento</FieldTitle>
								<FieldValue>
									{values?.preCadastro?.dataNascimento || '-'}
								</FieldValue>
							</FieldWrapper>

							<FieldWrapper>
								<FieldTitle>E-mail</FieldTitle>
								<FieldValue>{values?.preCadastro?.email || '-'}</FieldValue>
							</FieldWrapper>

							<FieldWrapper>
								<FieldTitle>Celular</FieldTitle>
								<FieldValue>
									{formatCellPhone(values?.preCadastro?.celular) || '-'}
								</FieldValue>
							</FieldWrapper>

							<FieldWrapper>
								<FieldTitle>PIS</FieldTitle>
								<FieldValue>
									{values?.preCadastro?.primeiroEmprego
										? 'Primeiro emprego. Não possuo PIS'
										: formatPIS(values?.preCadastro?.pis)}
								</FieldValue>
							</FieldWrapper>
						</ReviewAccordion>
					</FormSection>

					<FormSection label="Dados Cadastrais">
						<ReviewAccordion
							to="/dados-cadastrais/dados-pessoais?backTo=/revisao"
							label="Dados pessoais"
						>
							<FieldWrapper>
								<FieldTitle>Nome da Mãe</FieldTitle>
								<FieldValue>
									{values?.dadosPessoais?.naoExisteNomeMae
										? 'Nada a informar'
										: values?.dadosPessoais?.nomeMae || '-'}
								</FieldValue>
							</FieldWrapper>

							<FieldWrapper>
								<FieldTitle>Nome do Pai</FieldTitle>
								<FieldValue>
									{values?.dadosPessoais?.naoExisteNomePai
										? 'Nada a informar'
										: values?.dadosPessoais?.nomePai || '-'}
								</FieldValue>
							</FieldWrapper>

							<FieldWrapper>
								<FieldTitle>País</FieldTitle>
								<FieldValue>
									{COUNTRIES.filter(
										(s) => s[0] === values?.dadosPessoais?.pais,
									)?.[0]?.[1] || '-'}
								</FieldValue>
							</FieldWrapper>

							<FieldWrapper>
								<FieldTitle>UF</FieldTitle>
								<FieldValue>{values?.dadosPessoais?.uf || '-'}</FieldValue>
							</FieldWrapper>

							<FieldWrapper>
								<FieldTitle>Município</FieldTitle>
								<FieldValue>
									{values?.dadosPessoais?.municipio || '-'}
								</FieldValue>
							</FieldWrapper>

							<FieldWrapper>
								<FieldTitle>Sexo</FieldTitle>
								<FieldValue>
									{SEXES.filter(
										(s) => s[0] === values?.dadosPessoais?.sexo,
									)?.[0]?.[1] || '-'}
								</FieldValue>
							</FieldWrapper>

							<FieldWrapper>
								<FieldTitle>Cor ou Raça</FieldTitle>
								<FieldValue>
									{ETNIAS.filter(
										(s) => s[0] === values?.dadosPessoais?.cor,
									)?.[0]?.[1] || '-'}
								</FieldValue>
							</FieldWrapper>

							<FieldWrapper>
								<FieldTitle>Estado Civil</FieldTitle>
								<FieldValue>
									{ESTADOS_CIVIS.filter(
										(s) => s[0] === values?.dadosPessoais?.estadoCivil,
									)?.[0]?.[1] || '-'}
								</FieldValue>
							</FieldWrapper>

							{/* Certidão Casamento */}
							{values?.dadosPessoais?.estadoCivil === 'casado' && (
								<>
									<Hr />

									<SectionTitle>Certidão de casamento</SectionTitle>

									<FieldWrapper>
										<FieldTitle>Foto do documento</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.certidaoCasamento?.idAnexo ? (
												<>
													Documento anexado{' '}
													<ReviewAttachmentBtn
														label="Certidao de Casamento"
														type="certidao_casamento"
													/>
												</>
											) : (
												'Documento pendente'
											)}
										</FieldValue>
									</FieldWrapper>
								</>
							)}

							{/* RNE e Passaporte */}
							{values?.dadosPessoais?.pais !== 'BR' && (
								<>
									<Hr />

									<SectionTitle>RNE</SectionTitle>

									<FieldWrapper>
										<FieldTitle>Número</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.rne?.numero || '-'}
										</FieldValue>
									</FieldWrapper>
									<FieldWrapper>
										<FieldTitle>Classificação</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.rne?.classificacao || '-'}
										</FieldValue>
									</FieldWrapper>
									<FieldWrapper>
										<FieldTitle>Órgão Emissor</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.rne?.orgaoEmissor || '-'}
										</FieldValue>
									</FieldWrapper>
									<FieldWrapper>
										<FieldTitle>UF</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.rne?.uf || '-'}
										</FieldValue>
									</FieldWrapper>
									<FieldWrapper>
										<FieldTitle>Data de Expedição</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.rne?.dataExpedicao || '-'}
										</FieldValue>
									</FieldWrapper>

									<FieldWrapper>
										<FieldTitle>Foto do documento</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.rne?.idAnexo ? (
												<>
													Documento anexado{' '}
													<ReviewAttachmentBtn label="RNE" type="rne" />
												</>
											) : (
												'Documento pendente'
											)}
										</FieldValue>
									</FieldWrapper>

									<Hr />

									<SectionTitle>Passaporte</SectionTitle>

									<FieldWrapper>
										<FieldTitle>Número</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.passaporte?.numero || '-'}
										</FieldValue>
									</FieldWrapper>
									<FieldWrapper>
										<FieldTitle>Data de Chegada ao Brasil</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.passaporte?.dataChegadaBrasil ||
												'-'}
										</FieldValue>
									</FieldWrapper>
									<FieldWrapper>
										<FieldTitle>Número do Visto</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.passaporte?.numeroSerieVisto ||
												'-'}
										</FieldValue>
									</FieldWrapper>

									<FieldWrapper>
										<FieldTitle>Foto do documento</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.passaporte?.idAnexo ? (
												<>
													Documento anexado{' '}
													<ReviewAttachmentBtn
														label="Passaporte"
														type="passaporte"
													/>
												</>
											) : (
												'Documento pendente'
											)}
										</FieldValue>
									</FieldWrapper>
								</>
							)}

							{/* RG */}
							<Hr />
							<SectionTitle>RG</SectionTitle>

							<FieldWrapper>
								<FieldTitle>Número</FieldTitle>
								<FieldValue>
									{values?.dadosPessoais?.rg?.numero || '-'}
								</FieldValue>
							</FieldWrapper>
							<FieldWrapper>
								<FieldTitle>Órgão</FieldTitle>
								<FieldValue>
									{values?.dadosPessoais?.rg?.orgao || '-'}
								</FieldValue>
							</FieldWrapper>
							<FieldWrapper>
								<FieldTitle>UF</FieldTitle>
								<FieldValue>{values?.dadosPessoais?.rg?.uf || '-'}</FieldValue>
							</FieldWrapper>
							<FieldWrapper>
								<FieldTitle>Data de Emissão</FieldTitle>
								<FieldValue>
									{values?.dadosPessoais?.rg?.dataEmissao || '-'}
								</FieldValue>
							</FieldWrapper>

							<FieldWrapper>
								<FieldTitle>Frente do documento</FieldTitle>
								<FieldValue>
									{values?.dadosPessoais?.rg?.idAnexoFrente ? (
										<>
											Documento anexado{' '}
											<ReviewAttachmentBtn
												label="RG - Frente"
												type="rg_frente"
											/>
										</>
									) : (
										'Documento pendente'
									)}
								</FieldValue>
							</FieldWrapper>
							<FieldWrapper>
								<FieldTitle>Verso do documento</FieldTitle>
								<FieldValue>
									{values?.dadosPessoais?.rg?.idAnexoVerso ? (
										<>
											Documento anexado{' '}
											<ReviewAttachmentBtn label="RG - Verso" type="rg_verso" />
										</>
									) : (
										'Documento pendente'
									)}
								</FieldValue>
							</FieldWrapper>

							{/* CPF */}
							<Hr />
							<SectionTitle>CPF</SectionTitle>

							<FieldWrapper>
								<FieldTitle>Foto do documento</FieldTitle>
								<FieldValue>
									{values?.dadosPessoais?.cpf?.constaNoRG ? (
										'Consta na foto do RG'
									) : values?.dadosPessoais?.cpf?.idAnexo ? (
										<>
											Documento anexado{' '}
											<ReviewAttachmentBtn label="CPF" type="cpf" />
										</>
									) : (
										'Documento pendente'
									)}
								</FieldValue>
							</FieldWrapper>

							{/* Carteira de Trabalho */}
							<Hr />
							<SectionTitle>Carteira de Trabalho</SectionTitle>

							<FieldWrapper>
								<FieldTitle>Tipo</FieldTitle>
								<FieldValue>
									{values?.dadosPessoais?.ctps?.flagDigital
										? 'Digital'
										: 'Física'}
								</FieldValue>
							</FieldWrapper>

							{values?.dadosPessoais?.ctps?.flagDigital ? (
								<FieldWrapper>
									<FieldTitle>Último contrato de trabalho</FieldTitle>
									<FieldValue>
										{values?.dadosPessoais?.ctps?.flagEmpregoAnterior ? (
											values?.dadosPessoais?.ctps
												?.idAnexoPaginaUltimoContratoDigital ? (
												<>
													Documento anexado{' '}
													<ReviewAttachmentBtn
														label="Captura do Último Contrato de Trabalho"
														type="ctps_ultimo_contrato_digital"
													/>
												</>
											) : (
												'Documento pendente'
											)
										) : (
											'Primeiro Emprego'
										)}
									</FieldValue>
								</FieldWrapper>
							) : (
								<>
									<FieldWrapper>
										<FieldTitle>Número</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.ctps?.numero || '-'}
										</FieldValue>
									</FieldWrapper>
									<FieldWrapper>
										<FieldTitle>Número de Série</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.ctps?.numeroSerie || '-'}
										</FieldValue>
									</FieldWrapper>
									<FieldWrapper>
										<FieldTitle>UF</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.ctps?.uf || '-'}
										</FieldValue>
									</FieldWrapper>
									<FieldWrapper>
										<FieldTitle>Data de Emissão</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.ctps?.dataEmissao || '-'}
										</FieldValue>
									</FieldWrapper>

									<FieldWrapper>
										<FieldTitle>Página de foto</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.ctps?.idAnexoPaginaFoto ? (
												<>
													Documento anexado{' '}
													<ReviewAttachmentBtn
														label="CTPS - Página de foto"
														type="ctps_foto"
													/>
												</>
											) : (
												'Documento pendente'
											)}
										</FieldValue>
									</FieldWrapper>
									<FieldWrapper>
										<FieldTitle>Página de Qualificação</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.ctps
												?.idAnexoPaginaQualificacao ? (
												<>
													Documento anexado{' '}
													<ReviewAttachmentBtn
														label="CTPS - Página de Qualificação"
														type="ctps_info"
													/>
												</>
											) : (
												'Documento pendente'
											)}
										</FieldValue>
									</FieldWrapper>
									<FieldWrapper>
										<FieldTitle>Página do Último Contrato</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.ctps
												?.idAnexoPaginaUltimoContrato ? (
												<>
													Documento anexado{' '}
													<ReviewAttachmentBtn
														label="CTPS - Página do Último Contrato"
														type="ctps_ultimo_contrato"
													/>
												</>
											) : (
												'Documento pendente'
											)}
										</FieldValue>
									</FieldWrapper>
									<FieldWrapper>
										<FieldTitle>
											Página em Branco após último contrato
										</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.ctps?.idAnexoPaginaEmBranco ? (
												<>
													Documento anexado{' '}
													<ReviewAttachmentBtn
														label="CTPS - Página em Branco após último contrato"
														type="ctps_pagina_branco"
													/>
												</>
											) : (
												'Documento pendente'
											)}
										</FieldValue>
									</FieldWrapper>
								</>
							)}

							{/* CNH */}
							{values?.dadosPessoais?.cnh && (
								<>
									<Hr />
									<SectionTitle>CNH</SectionTitle>

									<FieldWrapper>
										<FieldTitle>Número</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.cnh?.numero || '-'}
										</FieldValue>
									</FieldWrapper>
									<FieldWrapper>
										<FieldTitle>UF</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.cnh?.uf || '-'}
										</FieldValue>
									</FieldWrapper>
									<FieldWrapper>
										<FieldTitle>Data de Validade</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.cnh?.dataValidade || '-'}
										</FieldValue>
									</FieldWrapper>
									<FieldWrapper>
										<FieldTitle>Categoria</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.cnh?.categoria || '-'}
										</FieldValue>
									</FieldWrapper>

									<FieldWrapper>
										<FieldTitle>Frente do documento</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.cnh?.idAnexoFrente ? (
												<>
													Documento anexado{' '}
													<ReviewAttachmentBtn
														label="CNH - Frente"
														type="cnh_frente"
													/>
												</>
											) : (
												'Documento pendente'
											)}
										</FieldValue>
									</FieldWrapper>
									<FieldWrapper>
										<FieldTitle>Verso do documento</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.cnh?.idAnexoVerso ? (
												<>
													Documento anexado{' '}
													<ReviewAttachmentBtn
														label="CNH - Verso"
														type="cnh_verso"
													/>
												</>
											) : (
												'Documento pendente'
											)}
										</FieldValue>
									</FieldWrapper>
								</>
							)}

							{/* Título de eleitor */}
							{values?.dadosPessoais?.tituloEleitor && (
								<>
									<Hr />
									<SectionTitle>Título de eleitor</SectionTitle>

									<FieldWrapper>
										<FieldTitle>Inscrição</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.tituloEleitor?.inscricao || '-'}
										</FieldValue>
									</FieldWrapper>
									<FieldWrapper>
										<FieldTitle>Zona</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.tituloEleitor?.zona || '-'}
										</FieldValue>
									</FieldWrapper>
									<FieldWrapper>
										<FieldTitle>Seção</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.tituloEleitor?.secao || '-'}
										</FieldValue>
									</FieldWrapper>

									<FieldWrapper>
										<FieldTitle>Frente do documento</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.tituloEleitor?.idAnexoFrente ? (
												<>
													Documento anexado{' '}
													<ReviewAttachmentBtn
														label="Título de eleitor - Frente"
														type="titulo_eleitor_frente"
													/>
												</>
											) : (
												'Documento pendente'
											)}
										</FieldValue>
									</FieldWrapper>
									<FieldWrapper>
										<FieldTitle>Verso do documento</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.tituloEleitor?.idAnexoVerso ? (
												<>
													Documento anexado{' '}
													<ReviewAttachmentBtn
														label="Título de eleitor - Verso"
														type="titulo_eleitor_verso"
													/>
												</>
											) : (
												'Documento pendente'
											)}
										</FieldValue>
									</FieldWrapper>
								</>
							)}

							{/* Certificado de reservista */}
							{values?.dadosPessoais?.certificadoReservista && (
								<>
									<Hr />
									<SectionTitle>Certificado de reservista</SectionTitle>

									<FieldWrapper>
										<FieldTitle>Número</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.certificadoReservista
												?.numeroCertificado || '-'}
										</FieldValue>
									</FieldWrapper>
									<FieldWrapper>
										<FieldTitle>UF</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.certificadoReservista?.uf || '-'}
										</FieldValue>
									</FieldWrapper>
									<FieldWrapper>
										<FieldTitle>Data de dispensa</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.certificadoReservista
												?.dataDispensa || '-'}
										</FieldValue>
									</FieldWrapper>

									<FieldWrapper>
										<FieldTitle>Frente do documento</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.certificadoReservista
												?.idAnexoFrente ? (
												<>
													Documento anexado{' '}
													<ReviewAttachmentBtn
														label="Certificado de Reservista - Frente"
														type="certificado_reservista_frente"
													/>
												</>
											) : (
												'Documento pendente'
											)}
										</FieldValue>
									</FieldWrapper>
									<FieldWrapper>
										<FieldTitle>Verso do documento</FieldTitle>
										<FieldValue>
											{values?.dadosPessoais?.certificadoReservista
												?.idAnexoVerso ? (
												<>
													Documento anexado{' '}
													<ReviewAttachmentBtn
														label="Certificado de Reservista - Verso"
														type="certificado_reservista_verso"
													/>
												</>
											) : (
												'Documento pendente'
											)}
										</FieldValue>
									</FieldWrapper>
								</>
							)}
						</ReviewAccordion>

						<ReviewAccordion
							to="/dados-cadastrais/enderecos-contatos?backTo=/revisao"
							label="Endereço e Contato"
						>
							<FieldWrapper>
								<FieldTitle>País</FieldTitle>
								<FieldValue>
									{COUNTRIES.filter(
										(s) => s[0] === values?.enderecoContato?.pais,
									)?.[0]?.[1] || '-'}
								</FieldValue>
							</FieldWrapper>
							<FieldWrapper>
								<FieldTitle>CEP</FieldTitle>
								<FieldValue>
									{formatCEP(values?.enderecoContato?.cep) || '-'}
								</FieldValue>
							</FieldWrapper>
							<FieldWrapper>
								<FieldTitle>Estado</FieldTitle>
								<FieldValue>
									{values?.enderecoContato?.estado || '-'}
								</FieldValue>
							</FieldWrapper>
							<FieldWrapper>
								<FieldTitle>Cidade</FieldTitle>
								<FieldValue>
									{values?.enderecoContato?.cidade || '-'}
								</FieldValue>
							</FieldWrapper>
							<FieldWrapper>
								<FieldTitle>Bairro</FieldTitle>
								<FieldValue>
									{values?.enderecoContato?.bairro || '-'}
								</FieldValue>
							</FieldWrapper>
							<FieldWrapper>
								<FieldTitle>Logradouro</FieldTitle>
								<FieldValue>
									{values?.enderecoContato?.logradouro || '-'}
								</FieldValue>
							</FieldWrapper>
							<FieldWrapper>
								<FieldTitle>Número</FieldTitle>
								<FieldValue>
									{values?.enderecoContato?.numero || '-'}
								</FieldValue>
							</FieldWrapper>
							<FieldWrapper>
								<FieldTitle>Complemento</FieldTitle>
								<FieldValue>
									{values?.enderecoContato?.complemento || '-'}
								</FieldValue>
							</FieldWrapper>

							<FieldWrapper>
								<FieldTitle>Comprovante de Residencia</FieldTitle>
								<FieldValue>
									{values?.enderecoContato?.comprovanteResidencia?.idAnexo ? (
										<>
											Documento anexado{' '}
											<ReviewAttachmentBtn
												label="Comprovante de Residencia"
												type="comprovante_residencia"
											/>
										</>
									) : (
										'Documento pendente'
									)}
								</FieldValue>
							</FieldWrapper>

							<FieldWrapper>
								<FieldTitle>Celular</FieldTitle>
								<FieldValue>
									{formatCellPhone(values?.preCadastro?.celular) || '-'}
								</FieldValue>
							</FieldWrapper>
							<FieldWrapper>
								<FieldTitle>Telefone Secundário</FieldTitle>
								<FieldValue>
									{formatPhone(values?.enderecoContato?.telefoneSecundario) ||
										'-'}
								</FieldValue>
							</FieldWrapper>
							<FieldWrapper>
								<FieldTitle>E-mail</FieldTitle>
								<FieldValue>{values?.preCadastro?.email || '-'}</FieldValue>
							</FieldWrapper>
							<FieldWrapper>
								<FieldTitle>E-mail Secundário</FieldTitle>
								<FieldValue>
									{values?.enderecoContato?.emailSecundario || '-'}
								</FieldValue>
							</FieldWrapper>
						</ReviewAccordion>

						<ReviewAccordion
							to="/dados-cadastrais/formacao?backTo=/revisao"
							label="Formação"
						>
							<FieldWrapper>
								<FieldTitle>Escolaridade</FieldTitle>
								<FieldValue>
									{ESCOLARIDADES.filter(
										(s) => s[0] === values?.formacao?.escolaridade,
									)?.[0]?.[1] || '-'}
								</FieldValue>
							</FieldWrapper>
							<FieldWrapper>
								<FieldTitle>Instituição</FieldTitle>
								<FieldValue>{values?.formacao?.instituicao || '-'}</FieldValue>
							</FieldWrapper>
							<FieldWrapper>
								<FieldTitle>Tipo de Instituição</FieldTitle>
								<FieldValue>
									{TIPOS_INSTITUICAO.filter(
										(s) => s[0] === values?.formacao?.tipoInstituicao,
									)?.[0]?.[1] || '-'}
								</FieldValue>
							</FieldWrapper>
							<FieldWrapper>
								<FieldTitle>Formação</FieldTitle>
								<FieldValue>
									{FORMACOES.filter(
										(s) => s[0] === values?.formacao?.formacao,
									)?.[0]?.[1] || '-'}
								</FieldValue>
							</FieldWrapper>
							<FieldWrapper>
								<FieldTitle>País</FieldTitle>
								<FieldValue>
									{COUNTRIES.filter(
										(s) => s[0] === values?.formacao?.pais,
									)?.[0]?.[1] || '-'}
								</FieldValue>
							</FieldWrapper>
							<FieldWrapper>
								<FieldTitle>Início</FieldTitle>
								<FieldValue>{values?.formacao?.inicio || '-'}</FieldValue>
							</FieldWrapper>
							<FieldWrapper>
								<FieldTitle>Conclusão</FieldTitle>
								<FieldValue>{values?.formacao?.conclusao || '-'}</FieldValue>
							</FieldWrapper>

							<FieldWrapper>
								<FieldTitle>Comprovante de Escolaridade</FieldTitle>
								<FieldValue>
									{values?.formacao?.comprovanteEscolaridade?.idAnexo ? (
										<>
											Documento anexado{' '}
											<ReviewAttachmentBtn
												label="Comprovante de Escolaridade"
												type="comprovante_escolaridade"
											/>
										</>
									) : (
										'Documento pendente'
									)}
								</FieldValue>
							</FieldWrapper>
						</ReviewAccordion>

						<ReviewAccordion
							to="/dados-cadastrais/familia?backTo=/revisao"
							label="Família"
						>
							{values?.familia?.flagNaoPossuoFamilia ? (
								<strong>Não possuo familiares a declarar</strong>
							) : !values?.familia?.docs?.length ? (
								'Família pendente'
							) : (
								values?.familia?.docs.map((f, index) => {
									f = dateFieldsIsoToStr(['dataNascimento'])(f)

									return (
										<div key={f?.uuid}>
											{index > 0 && <Hr />}

											<SectionTitle size={1.1}>
												<strong>{f?.nome}</strong>
											</SectionTitle>

											<FieldWrapper>
												<FieldTitle>Vínculo</FieldTitle>
												<FieldValue>
													{TIPOS_FAMILIAR.filter(
														(s) => s[0] === f?.tipoFamiliar,
													)?.[0]?.[1] || '-'}
												</FieldValue>
											</FieldWrapper>
											<FieldWrapper>
												<FieldTitle>Data de Nascimento</FieldTitle>
												<FieldValue>{f?.dataNascimento || '-'}</FieldValue>
											</FieldWrapper>
											<FieldWrapper>
												<FieldTitle>CPF</FieldTitle>
												<FieldValue>{formatCPF(f?.cpf) || '-'}</FieldValue>
											</FieldWrapper>
											<FieldWrapper>
												<FieldTitle>País</FieldTitle>
												<FieldValue>
													{COUNTRIES.filter(
														(s) => s[0] === f?.pais,
													)?.[0]?.[1] || '-'}
												</FieldValue>
											</FieldWrapper>
											<FieldWrapper>
												<FieldTitle>UF</FieldTitle>
												<FieldValue>{f?.uf || '-'}</FieldValue>
											</FieldWrapper>
											<FieldWrapper>
												<FieldTitle>Sexo</FieldTitle>
												<FieldValue>
													{SEXES.filter((s) => s[0] === f?.sexo)?.[0]?.[1] ||
														'-'}
												</FieldValue>
											</FieldWrapper>
											<FieldWrapper>
												<FieldTitle>
													É Dependente para fins de imposto de renda?
												</FieldTitle>
												<FieldValue>
													{f?.flagDependenteIR ? 'Sim' : 'Não'}
												</FieldValue>
											</FieldWrapper>

											{f?.certidaoNascimento && (
												<>
													<SectionTitle>Certidão de Nascimento</SectionTitle>

													<FieldWrapper>
														<FieldTitle>Foto do documento</FieldTitle>
														<FieldValue>
															{f?.certidaoNascimento?.idAnexo ? (
																<>
																	Documento anexado{' '}
																	<ReviewAttachmentBtn
																		label="Certidão de Nascimento"
																		type={`certidao_nascimento/${f?.uuid}`}
																	/>
																</>
															) : (
																'Documento pendente'
															)}
														</FieldValue>
													</FieldWrapper>
												</>
											)}

											{f?.cadernetaVacinacao && (
												<>
													<SectionTitle>Caderneta de Vacinação</SectionTitle>

													<FieldWrapper>
														<FieldTitle>Página de identificação</FieldTitle>
														<FieldValue>
															{f?.cadernetaVacinacao?.idAnexoIdentificacao ? (
																<>
																	Documento anexado{' '}
																	<ReviewAttachmentBtn
																		label="Caderneta de Vacinação - Página de identificação"
																		type={`caderneta_vacinacao_identificacao/${f?.uuid}`}
																	/>
																</>
															) : (
																'Documento pendente'
															)}
														</FieldValue>
													</FieldWrapper>
													<FieldWrapper>
														<FieldTitle>Quadro Vacinal</FieldTitle>
														<FieldValue>
															{f?.cadernetaVacinacao?.idAnexoQuadroVacinal ? (
																<>
																	Documento anexado{' '}
																	<ReviewAttachmentBtn
																		label="Caderneta de Vacinação - Quadro Vacinal"
																		type={`caderneta_vacinacao_quadro_vacinal/${f?.uuid}`}
																	/>
																</>
															) : (
																'Documento pendente'
															)}
														</FieldValue>
													</FieldWrapper>
												</>
											)}
										</div>
									)
								})
							)}
						</ReviewAccordion>
					</FormSection>
				</FormBody>

				<FormFooter style={{ marginTop: 'var(--spacing)' }}>
					<Button onClick={() => setConfirmationVisibility(true)} primary large>
						Finalizar
					</Button>
				</FormFooter>
			</FormWrapper>

			<Modal
				isOpen={confirmationVisible}
				onRequestClose={() => setConfirmationVisibility(false)}
				renderHeader={() => <>Confirma que seus dados estão corretos?</>}
			>
				<Form onSubmit={submitForm}>
					{({ handleSubmit, submitting, values }) => (
						<FormWrapper
							onSubmit={handleSubmit}
							style={{ paddingTop: 'var(--spacing)' }}
						>
							<FieldWrapper>
								<CheckBoxField
									name="revisaoGeralFinalizada"
									label="Li e confirmo que meus dados estão corretos."
									autoFocus
								/>
							</FieldWrapper>

							<Button
								type="submit"
								primary
								large
								submitting={submitting}
								disabled={!values.revisaoGeralFinalizada}
							>
								Enviar
							</Button>
						</FormWrapper>
					)}
				</Form>
			</Modal>
		</MainLayout>
	)
}

export default ReviewPage
