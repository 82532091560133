import React, { useRef } from 'react'
import ReactModal from 'react-modal'
import styled from 'styled-components'
import { mq } from '~/helpers/breakpoints'
import IconClose from '~/svgs/iconClose'

const Modal = ({
	children,
	renderHeader,
	renderFooter,
	closeButton = true,
	onAfterOpen,
	onAfterClose,
	onRequestClose,
	...props
}) => {
	const overlayRef = useRef(null)
	const contentRef = useRef(null)
	return (
		<StyledReactModal
			closeTimeoutMS={200}
			parentSelector={() => document.querySelector('#portal-root')}
			onAfterOpen={() => {
				document.body.style.overflow = 'hidden'
				onAfterOpen && onAfterOpen({ overlayRef, contentRef })
			}}
			onAfterClose={() => {
				document.body.style.overflow = 'auto'
				onAfterClose && onAfterClose({ overlayRef, contentRef })
			}}
			onRequestClose={() =>
				onRequestClose && onRequestClose({ overlayRef, contentRef })
			}
			overlayRef={(el) => (overlayRef.current = el)}
			contentRef={(el) => (contentRef.current = el)}
			{...props}
		>
			{closeButton ? (
				<CloseButton
					onClick={() =>
						onRequestClose && onRequestClose({ overlayRef, contentRef })
					}
				>
					<IconClose />
				</CloseButton>
			) : null}
			{renderHeader && (
				<ModalHeader noLine={!children}>{renderHeader()}</ModalHeader>
			)}
			<ModalBody>{children}</ModalBody>
			{renderFooter && <ModalFooter>{renderFooter()}</ModalFooter>}
		</StyledReactModal>
	)
}

const StyledReactModal = styled(ReactModal)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: 1px solid rgb(204, 204, 204);
	background: rgb(255, 255, 255);
	overflow: auto;
	border-radius: var(--border-radius);
	outline: none;
	padding: var(--spacing) calc(var(--spacing) * 0.5);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
	display: flex;
	flex-direction: column;
	max-height: calc(100vh - calc(var(--spacing) * 3));
	width: calc(100vw - calc(var(--spacing) * 2));
	max-width: calc(100vw - calc(var(--spacing) * 2));

	${mq.min('sm')} {
		width: auto;
		max-height: calc(100vh - calc(var(--spacing) * 6));
	}
`

const CloseButton = styled.button.attrs({
	type: 'button',
	className: 'modal-close-btn',
})`
	cursor: pointer;
	padding: 1em 1em 0.5em 0.5em;
	position: absolute;
	top: 0;
	right: 0;
	font-size: 1rem;
	color: var(--close-button-color, #707070);
	transition: all 0.2s ease;
	z-index: 1;
	:hover {
		color: #444;
	}
`

const ModalHeader = styled.div.attrs({
	className: 'modal-header',
})`
	margin: -2px calc(var(--spacing) * 0.4) 0;
	padding-right: var(--spacing);
	padding-bottom: calc(var(--spacing) * 0.7);
	border-bottom: ${(props) => (props.noLine ? 'none' : '1px solid #e6e6e6')};
	color: var(--color-text-dark);
	font-weight: bold;
`
const ModalBody = styled.div.attrs({
	className: 'modal-body',
})`
	flex-grow: 1;
	padding: 0 calc(var(--spacing) * 0.4);
	overflow: auto;
	position: relative;
`
const ModalFooter = styled.div.attrs({
	className: 'modal-footer',
})`
	display: flex;
	justify-content: flex-end;
	padding-top: var(--spacing);
	position: relative;
	z-index: -1;
`

export default Modal
