import React from 'react'
import { nanoid } from 'nanoid'
import Svg from '~/components/svg'
import { themeColors } from '~/styles/cssVariables'

function IconBellWarning(props) {
	const id = `id${nanoid()}`

	return (
		<Svg viewBox="3 1 19 22" {...props}>
			<linearGradient
				id={id}
				x1="0.5"
				x2="1"
				y1="1"
				y2="0"
				gradientUnits="objectBoundingBox"
			>
				{themeColors.map((c, i, arr) => (
					<stop
						key={`key-${i + c}`}
						offset={i === arr.length - 1 ? 0.9 : i / (arr.length - 1)}
						stopColor={c}
					/>
				))}
			</linearGradient>
			<path
				fill={props.color || `url(#${id})`}
				d="M10.75 21.26h4.5A2.26 2.26 0 0113 23.52a2.25 2.25 0 01-2.25-2.26zM22 20.13H4v-1.12l2.25-2.26v-5.64a8.01 8.01 0 011.32-4.58 6.22 6.22 0 013.74-2.55v-.77A1.7 1.7 0 0113 1.52a1.7 1.7 0 011.69 1.7v.76a6.2 6.2 0 013.74 2.55 8.02 8.02 0 011.32 4.58v5.64L22 19.01v1.13zM7.94 11v7.62h10.12V11c0-3.36-2.08-5.7-5.06-5.7S7.94 7.64 7.94 11zm3.6 5.05A1.47 1.47 0 0113 14.58a1.47 1.47 0 011.47 1.47A1.47 1.47 0 0113 17.52a1.47 1.47 0 01-1.47-1.47zm0-5V8.37A1.47 1.47 0 0113 6.9a1.47 1.47 0 011.47 1.47v2.68A1.47 1.47 0 0113 12.52a1.47 1.47 0 01-1.47-1.47z"
			/>
		</Svg>
	)
}

export default IconBellWarning
