import React from 'react'

import { Wrapper } from './styles'

const TermsText = ({ ...props }) => {
	return (
		<Wrapper {...props}>
			<p className="c1">
				<span className="c2">
					A <b>americanas</b> tem o compromisso com a transparência, a
					privacidade e a segurança dos dados pessoais dos candidatos durante
					todo o processo de candidatura a uma de nossas vagas ou daqueles que
					consideramos interessados em trabalhar conosco.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Para que entendam melhor quais informações coletamos e como as
					utilizamos, armazenamos ou excluímos, detalhamos a seguir nossa
					Política de Privacidade.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Os dados cadastrais dos candidatos não são divulgados para terceiros,
					exceto quando necessários para a condução do processo seletivo.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Para que a Política de Privacidade seja bem compreendida, é
					fundamental esclarecer alguns conceitos importantes:
				</span>
			</p>
			<p className="c1">
				<span className="c3 c5">- Candidato</span>
				<span className="c2">
					&nbsp;&ndash; toda pessoa física que envia currículo ou participa de
					processo de seleção com o objetivo de candidatar-se a uma vaga de
					emprego ou oportunidade de trabalho;
				</span>
			</p>
			<p className="c1">
				<span className="c3 c5">- Dados pessoais</span>
				<span className="c2">
					&nbsp;&ndash; qualquer informação relacionada a uma pessoa que a
					identifique ou que, usada em combinação com outras informações
					tratadas, identifique um indivíduo. Ainda, qualquer informação por
					meio da qual seja possível identificar uma pessoa ou entrar em contato
					com ela;
				</span>
			</p>
			<p className="c1">
				<span className="c3">- </span>
				<span className="c3 c5">Tratamento de dados pessoais</span>
				<span className="c2">
					&nbsp;&ndash; considera-se tratamento de dado pessoal a coleta,
					produção, recepção, classificação, utilização, acesso, reprodução,
					transmissão, distribuição, processamento, arquivamento, armazenamento,
					eliminação, avaliação ou controle da informação; a comunicação,
					transferência, difusão ou extração de dados de pessoas físicas;
				</span>
			</p>
			<p className="c1">
				<span className="c3 c5">- Titular de dados</span>
				<span className="c2">
					&nbsp;&ndash; qualquer pessoa física que tenha seus dados pessoais
					tratados pela <b>americanas</b>;
				</span>
			</p>
			<p className="c1">
				<span className="c3 c5">- Finalidade</span>
				<span className="c2">
					&nbsp;&ndash; o que queremos alcançar com o tratamento de dados
					pessoais.
				</span>
			</p>
			<p className="c1">
				<span className="c3 c5">- Necessidade</span>
				<span className="c2">
					&nbsp;&ndash; o tratamento de dados pessoais deve se limitar ao mínimo
					necessário para o propósito almejado. Ou seja, deve ser pertinente,
					proporcional e não excessivo.
				</span>
			</p>
			<p className="c1">
				<span className="c3 c5">- Consentimento</span>
				<span className="c3">
					&nbsp;&ndash; autorização clara e objetiva que o titular dá para
					tratamento de seus dados pessoais com finalidade previamente
					estipulada. Após dar o consentimento, você pode revogá-lo a qualquer
					momento. A revogação não cancela os processamentos realizados
					previamente.
				</span>
			</p>
			<p className="c1">
				<span className="c3">- A </span>
				<b>americanas</b>
				<span className="c3">&nbsp;&eacute; marca integrante da </span>
				<b>americanas s.a.</b>
				<span className="c2">, composta pelas empresas listadas abaixo:</span>
			</p>
			<p className="c1">
				<span className="c2">americanas s.a. &ndash; 00.776.574/0006-60</span>
			</p>
			<p className="c1">
				<span className="c2">
					BIT Services Inovação e Tecnologia Ltda. &ndash; 03.789.968/0001-37
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Digital Finance Promotora Ltda. &ndash; 19.179.007/0001-40
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Direct Express Logística Integrada S.A. &ndash; 05.886.614/0001-36
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					ST Importações Ltda. &ndash; 02.867.220/0001-42
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Submarino Finance Promotora de Cr&eacute;dito Ltda. &ndash;
					07.897.468/0001-70
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Ame Digital Brasil Ltda. &ndash; 32.778.350/0001-70
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Ecologística Entregas Sustentáveis Ltda. &ndash; 22.236.389/0001-01
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Courrieros Transportes Ltda. &ndash; 29.386.039/0001-70
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Supernow Portal e Serviços de Internet Ltda. &ndash;
					23.559.907/0001-90
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Ecolivery Courrieros Ltda &ndash; 16.890.506/0001-53
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					1. a quem essa política de privacidade se aplica?
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Aplica-se a todos os candidatos e pessoas que tenham interesse em
					trabalhar na <b>americanas</b> ou em qualquer outra empresa integrante
					da <b>americanas s.a</b>, relacionadas acima.
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					2. que tipo de informações pessoais coletamos e utilizamos?
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Podemos coletar e tratar os seguintes tipos de informações do
					candidato:
				</span>
			</p>
			<p className="c1">
				<span className="c3 c5">Informações que o candidato nos fornece:</span>
				<span className="c2">
					&nbsp;Aquelas que o candidato fornece quando utiliza o portal de Boas
					Vindas, quando se inscreve no processo seletivo para provimento de
					vagas oferecidas, seja através da página “Americanas Carreiras”, da
					plataforma Pandapé ou por qualquer outro meio. Dentre as informações
					que podem ser solicitadas estão:
				</span>
			</p>
			<ul className="c11 lst-kix_fypnr8iab89a-0 start">
				<li className="c0 li-bullet-0">
					<span className="c2">
						Nome completo, CPF, data de nascimento, e-mail, número de telefone
						celular, PIS – Programa de Integração Social, nacionalidade,
						naturalidade, sexo, raça/cor, estado civil, endereço e comprovante
						de residência, nome completo dos pais, dados do cônjuge e filho(s)
						(nome completo, data de nascimento, CPF, nacionalidade, sexo,
						dependente ou não);
					</span>
				</li>
				<li className="c0 li-bullet-0">
					<span className="c2">
						Formação acadêmica (escolaridade, instituição, início e conclusão,
						certificado), histórico acadêmico, histórico de empregos anteriores,
						pretensão salarial, informações de candidatura a vaga;
					</span>
				</li>
				<li className="c0 li-bullet-0">
					<span className="c2">
						Exame ocupacional admissional (ASO); informações da conta bancária;
						foto e nome para o crachá; informar a opção de vale transporte, a
						linha (nome) e quantidade utilizado para o trajeto residência x
						trabalho x residência.
					</span>
				</li>
			</ul>
			<p>&nbsp;</p>
			<p className="c1">
				<span className="c3 c5">
					Informações coletadas durante o processo seletivo:
				</span>
				<span className="c2">
					&nbsp;registros de comunicação, caso o candidato entre em contato com
					a <b>americanas</b>; progresso e resultados obtidos por ele nas etapas
					e testes do processo seletivo. Se esse processo incluir entrevista
					virtual, e o candidato concordar com a gravação da entrevista, sua
					imagem e o som de sua voz podem ser capturados e armazenados.
				</span>
			</p>

			<p className="c1">
				<span className="c3 c5">Informações coletadas de outras fontes:</span>
				<span className="c2">
					&nbsp;informações existentes em redes sociais, como o Linkedin, se
					essas plataformas forem utilizadas para a candidatura nos processos
					seletivos disponibilizados.
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					3. por que solicitamos seus dados pessoais?
				</span>
			</p>
			<ul className="c11 lst-kix_wzvwujewiftj-0 start">
				<li className="c0 li-bullet-0">
					<span className="c2">
						Para considerar a sua candidatura e gerenciar o processo de seleção
						ao cargo para o qual se candidatou. Os dados também podem ser
						utilizados para outras posições disponíveis, no futuro;
					</span>
				</li>
				<li className="c0 li-bullet-0">
					<span className="c2">
						Para compor banco de dados que poderá ser consultado em outras
						oportunidades de vagas na americanas ou em empresas do mesmo grupo;
					</span>
				</li>
				<li className="c0 li-bullet-0">
					<span className="c2">
						Para avaliar se o perfil profissional e as competências técnicas do
						candidato potencialmente o qualificam para ocupar a vaga disponível
						na <b>americanas</b>;
					</span>
				</li>
				<li className="c0 li-bullet-0">
					<span className="c2">
						Para que seja possível entrar em contato com o candidato;
					</span>
				</li>
				<li className="c0 li-bullet-0">
					<span className="c2">
						Para viabilizar ou instruir processos seletivos e estatísticas
						relacionadas a processos seletivos na <b>americanas</b> e em demais
						empresas da <b>americanas s.a.</b>;
					</span>
				</li>
				<li className="c0 li-bullet-0">
					<span className="c2">
						Para conduzir o processo de contratação do candidato como associado
						na <b>americanas</b> e demais empresas da <b>americanas s.a.</b>
					</span>
				</li>
			</ul>

			<p className="c9">
				<span className="c6 c5">4. Com quem compartilhamos os seus dados?</span>
			</p>
			<p className="c1">
				<span className="c2">
					As informações coletadas são tratadas dentro da <b>americanas s.a.</b>{' '}
					e somente serão compartilhadas quando forem necessárias.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					(i) para prestação adequada dos serviços e nos propósitos detalhados
					acima; (ii) para proteção em caso de conflito; (iii) mediante decisão
					judicial ou requisição de autoridade competente; (iv) com empresas
					provedoras de infraestrutura tecnológica e operacional e provedoras de
					serviço de armazenamento de informações; (v) com empresas fornecedoras
					de software de Recursos Humanos (RH) e outras tecnologias para gestão
					dos processos seletivos.
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">5. Transferência internacional de dados</span>
			</p>
			<p className="c1">
				<span className="c2">
					Como a internet é um ambiente global, determinados serviços oferecidos
					pela americanas podem demandar a transferência dos seus dados para
					outros países.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Nesse caso, os dados são tratados de acordo com a LGPD (Lei Geral de
					Proteção de Dados) e demais instrumentos legislativos de proteção de
					dados pessoais, locais ou dos países a que serão destinados. Tomamos
					as medidas de segurança de acordo com a LGPD e com nossas políticas.
					Adotamos cláusulas-padrão em contratos com fornecedores e prestadores
					de serviço, que reforçam a responsabilidade das partes no estrito
					cumprimento da LGPD, e todos os demais diplomas legais que regulam a
					proteção dos dados pessoais.
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					6. por quanto tempo armazenamos informações pessoais?
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Armazenamos as informações dos candidatos por 1 ano. Após esse prazo,
					os dados serão anonimizados.
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					7. Quais são os direitos do titular de dados?
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					O titular dos dados pessoais tem o direito de obter da{' '}
					<b>americanas</b>, a qualquer momento, mediante requisição formal,
					informações referentes aos seus dados.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					A <b>americanas</b> terá 15 dias para responder às solicitações dos
					titulares. Os pedidos serão analisados conforme previsto em legislação
					vigente e, por questões legais, algumas solicitações podem não ser
					atendidas.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Os titulares dos dados, segundo a LGPD, podem requerer da{' '}
					<b>americanas</b>:
				</span>
			</p>
			<ul className="c11 lst-kix_wzvwujewiftj-0 start">
				<li className="c0 li-bullet-0">
					<span className="c2">confirmação da existência de tratamento;</span>
				</li>
				<li className="c0 li-bullet-0">
					<span className="c2">acesso aos dados;</span>
				</li>
				<li className="c0 li-bullet-0">
					<span className="c2">
						correção de dados incompletos, inexatos ou desatualizados;
					</span>
				</li>
				<li className="c0 li-bullet-0">
					<span className="c2">
						anonimização, bloqueio ou eliminação de dados desnecessários,
						excessivos ou tratados em desconformidade com o disposto na Lei;
					</span>
				</li>
				<li className="c0 li-bullet-0">
					<span className="c2">
						portabilidade dos dados a outro fornecedor de serviço ou produto,
						mediante requisição expressa, de acordo com a regulamentação da
						autoridade nacional, observados os segredos comercial e industrial;
					</span>
				</li>
				<li className="c0 li-bullet-0">
					<span className="c2">
						eliminação dos dados pessoais tratados com o consentimento do
						titular;
					</span>
				</li>
				<li className="c0 li-bullet-0">
					<span className="c2">
						informação das entidades públicas e privadas com as quais o
						controlador realizou uso compartilhado de dados;
					</span>
				</li>
				<li className="c0 li-bullet-0">
					<span className="c2">
						informação sobre a possibilidade de não fornecer consentimento e
						sobre as consequências da negativa;
					</span>
				</li>
				<li className="c0 li-bullet-0">
					<span className="c2">revogação do consentimento;</span>
				</li>
			</ul>

			<p className="c9">
				<span className="c6 c5">8. como exercer os seus direitos?</span>
			</p>
			<p className="c1">
				<span className="c3">
					O candidato pode exercer seus direitos enviando e-mail para{' '}
					<b>privacidade.portalboasvindas@americanas.io</b>
				</span>
			</p>

			<p className="c9">
				<span className="c6 c5">
					9. Menores de 18 anos podem se candidatar a uma vaga?
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Não. Pessoas menores de 18 anos não poderão concorrer a uma vaga
					oferecida através dessa plataforma. Caso sejam identificados
					candidatos menores de 18 anos seus dados pessoais serão imediatamente
					excluídos ou anonimizados.
				</span>
			</p>

			<p className="c9">
				<span className="c6 c5">
					10. Outras informações importantes sobre proteção de dados
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Nossa Política de Privacidade pode ser alterada a qualquer momento. O
					texto revisado será publicado neste site, com a data em que foi
					modificado. Se houver alguma mudança substancial, você receberá uma
					notificação por e-mail.
				</span>
			</p>

			<p className="c9">
				<span className="c6 c5">
					11. Como entrar em contato com o encarregado da proteção de dados da
					americanas?
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					O encarregado da proteção de dados é o responsável escolhido pela
					americanas para atuar como canal de comunicação entre o controlador,
					os titulares dos dados e a Autoridade Nacional de Proteção de Dados
					(ANPD). Qualquer dúvida sobre tratamento de dados pessoais poderá ser
					encaminhada para o e-mail{' '}
					<b>privacidade.portalboasvindas@americanas.io</b>.
				</span>
			</p>

			<p className="c9">
				<span className="c6 c5">12. Legislação e foro</span>
			</p>
			<p className="c1">
				<span className="c2">
					Esta política será regida, interpretada e executada de acordo com as
					Leis da República Federativa do Brasil, especialmente a Lei nº
					13.709/2018, independentemente das Leis de outros estados ou países,
					sendo competente o foro de domicílio do Candidato para dirimir
					qualquer dúvida decorrente deste documento.
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					13. Última atualização e versões anteriores
				</span>
			</p>
			<p className="c1">
				<span className="c7 c3">
					<b>Última atualização:</b> 26 de outubro de 2021 - Versão inicial
				</span>
			</p>
			<p className="c14">
				<span className="c10 c13" />
			</p>
		</Wrapper>
	)
}

export default TermsText
