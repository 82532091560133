/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { mq } from '~/helpers/breakpoints'
import { logout } from '~/store/reducers/user/actions'
import { getUser } from '~/store/reducers/user/selectors'

import Link from './link'

const UserArea = () => {
	const user = useSelector(getUser)
	const dispatch = useDispatch()

	return (
		<Wrapper>
			{user?.nome ? <Username>{user.nome}</Username> : undefined}
			<Link onClick={() => dispatch(logout())}>Sair</Link>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: flex;
	font-size: 0.8rem;
`
const Username = styled.span`
	display: none;
	padding-right: calc(var(--spacing) * 0.5);
	margin-right: calc(var(--spacing) * 0.5);
	border-right: 1px solid #cfcfcf;
	color: #555;

	${mq.min('sm')} {
		display: inline-flex;
	}
`

export default UserArea
