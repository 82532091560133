import styled from 'styled-components'

export const AlertBox = styled.div`
	width: 100%;
	max-width: 430px;
	height: 60px;
	background-color: #fff;
	border-radius: 7px 7px 0 0;
	border-bottom: 3px solid #ebc100;
	margin: 40px auto;
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: 500px) {
		flex-direction: column;
		height: 70px;

		div {
			font-size: 13px;
			text-align: center;
			margin-top: 5px;
		}
	}
`
