import axios from 'axios'
import { API_ROOT_URL } from '~/config/constants'

const api = {
	getCancelSource: () => axios.CancelToken.source(),

	accessLink: {
		validate: ({ token, cancelSource } = {}) => {
			if (!token) throw new Error('Token deve ser informado')

			const url = `${API_ROOT_URL}/accessLink/${token}`
			return axios.get(url, {
				cancelToken: cancelSource?.token,
			})
		},
	},
	authentication: {
		login: ({ values, cancelSource } = {}) => {
			const url = `${API_ROOT_URL}/authentication/login`
			return axios.post(url, values, {
				cancelToken: cancelSource?.token,
			})
		},
		logout: ({ cancelSource } = {}) => {
			const url = `${API_ROOT_URL}/authentication/logout`
			return axios.post(
				url,
				{},
				{
					cancelToken: cancelSource?.token,
				},
			)
		},
	},
	password: {
		recover: ({ values, cancelSource } = {}) => {
			const url = `${API_ROOT_URL}/password/recover`
			return axios.post(url, values, {
				cancelToken: cancelSource?.token,
			})
		},
		save: ({ values, token, g_recaptcha_response, cancelSource } = {}) => {
			const url = `${API_ROOT_URL}/password/save/${token}`
			return axios.post(
				url,
				{
					senha: values.pass,
					g_recaptcha_response,
				},
				{
					cancelToken: cancelSource?.token,
				},
			)
		},
	},
	user: {
		get: ({ cancelSource } = {}) => {
			const url = `${API_ROOT_URL}/user`
			return axios.get(url, {
				cancelToken: cancelSource?.token,
			})
		},
		start: ({ cancelSource } = {}) => {
			const url = `${API_ROOT_URL}/user/start`
			return axios.patch(
				url,
				{
					iniciou: true,
				},
				{
					cancelToken: cancelSource?.token,
				},
			)
		},
		acceptPrivacyTerms: ({ cancelSource } = {}) => {
			const url = `${API_ROOT_URL}/user/acceptPrivacyTerms`
			return axios.patch(
				url,
				{
					aceiteTermoPrivacidade: true,
				},
				{
					cancelToken: cancelSource?.token,
				},
			)
		},
		delete: () => {
			const url = `${API_ROOT_URL}/user`
			return axios.delete(url)
		},
	},
	etapas: {
		get: ({ cancelSource } = {}) => {
			const url = `${API_ROOT_URL}/etapas`
			return axios.get(url, {
				cancelToken: cancelSource?.token,
			})
		},
	},

	forms: (formName) => ({
		get: ({ cancelSource } = {}) => {
			const url = `${API_ROOT_URL}/${formName}`
			return axios.get(url, {
				cancelToken: cancelSource?.token,
			})
		},
		put: ({ values, cancelSource } = {}) => {
			const url = `${API_ROOT_URL}/${formName}`
			return axios.put(url, values, {
				cancelToken: cancelSource?.token,
			})
		},
		post: ({ values, cancelSource } = {}) => {
			const url = `${API_ROOT_URL}/${formName}`
			return axios.post(url, values, {
				cancelToken: cancelSource?.token,
			})
		},
	}),

	upload: (attachmentType) => ({
		get: ({ cancelSource, handleProgress } = {}) => {
			const url = `${API_ROOT_URL}/upload/${attachmentType}`
			return axios.get(url, {
				cancelToken: cancelSource?.token,
				responseType: 'blob',
				onDownloadProgress:
					handleProgress &&
					((progressEvent) => {
						handleProgress(progressEvent.loaded / progressEvent.total)
					}),
			})
		},
		post: ({ cancelSource, values, handleProgress } = {}) => {
			const url = `${API_ROOT_URL}/upload/${attachmentType}`
			return axios.post(url, values, {
				cancelToken: cancelSource?.token,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				onUploadProgress:
					handleProgress &&
					((progressEvent) => {
						handleProgress(progressEvent.loaded / progressEvent.total)
					}),
			})
		},
		delete: ({ cancelSource } = {}) => {
			const url = `${API_ROOT_URL}/upload/${attachmentType}`
			return axios.delete(url, {
				cancelToken: cancelSource?.token,
			})
		},
	}),

	cep: ({ value, cancelSource } = {}) => {
		const url = `${API_ROOT_URL}/cep/${value}`
		return axios.get(url, {
			cancelToken: cancelSource?.token,
		})
	},

	familia: {
		get: ({ uuid, cancelSource } = {}) => {
			const url = uuid
				? `${API_ROOT_URL}/familia/${uuid}`
				: `${API_ROOT_URL}/familia`
			return axios.get(url, {
				cancelToken: cancelSource?.token,
			})
		},
		put: ({ uuid, values, cancelSource } = {}) => {
			const url = uuid
				? `${API_ROOT_URL}/familia/${uuid}`
				: `${API_ROOT_URL}/familia`
			return axios.put(url, values, {
				cancelToken: cancelSource?.token,
			})
		},
		post: ({ uuid, values, cancelSource } = {}) => {
			const url = uuid
				? `${API_ROOT_URL}/familia/${uuid}`
				: `${API_ROOT_URL}/familia/new`
			return axios.post(url, values, {
				cancelToken: cancelSource?.token,
			})
		},
		delete: ({ uuid, cancelSource } = {}) => {
			const url = `${API_ROOT_URL}/familia/${uuid}`
			return axios.delete(url, {
				cancelToken: cancelSource?.token,
			})
		},
	},

	aso: {
		download: ({ cancelSource } = {}) => {
			const url = `${API_ROOT_URL}/aso/download`
			return axios.get(url, {
				cancelToken: cancelSource?.token,
			})
		},
	},

	dadosBancarios: {
		download: ({ cancelSource } = {}) => {
			const url = `${API_ROOT_URL}/dadosBancarios/download`
			return axios.get(url, {
				cancelToken: cancelSource?.token,
			})
		},
	},
}

export default api
