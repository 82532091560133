import React from 'react'
import Svg from '../svg'

function LogoAme(props) {
	return (
		<Svg
			viewBox="0 0 54.3 15.1"
			style={{ height: '1em', width: 'auto' }}
			{...props}
		>
			<path d="M8.9 14.11L.97 6.26a1.95 1.95 0 01.1-2.66h0L3.7.99A1.68 1.68 0 014.95.5h10.27a1.67 1.67 0 011.25.49l2.62 2.6h0a1.94 1.94 0 01.1 2.67l-7.92 7.85a1.7 1.7 0 01-2.38 0zM6.06 1l2.82 2.8a1.69 1.69 0 002.38 0L14.1.99H6.08a1.64 1.64 0 00-.53-.35 1.66 1.66 0 01.52.35zm20.54 10.97a5.98 5.98 0 01-2.25-.39 2.64 2.64 0 01-1.58-2.56h0v-.3h0a2.62 2.62 0 011.59-2.46 5.98 5.98 0 012.23-.4h2.16v-.88c0-.47-.2-.56-.27-.6a5.8 5.8 0 00-2.8 0c-.3.12-.43.6-.43.6a.23.23 0 01-.29.17l-1.66-.46a.23.23 0 01-.17-.28l.03-.1a2.53 2.53 0 011.5-1.81 7.31 7.31 0 014.75 0A2.62 2.62 0 0131 4.95h0V9.1h0a2.64 2.64 0 01-1.58 2.56 6.15 6.15 0 01-2.24.3zM25.3 8.14c-.08.04-.27.12-.27.6v.35c0 .47.19.55.27.6a4.1 4.1 0 001.31.17h.56a4.8 4.8 0 001.31-.09c.09-.04.27-.12.27-.6v-1.2h-2.14a4.1 4.1 0 00-1.31.17zm21.85 3.4a2.63 2.63 0 01-1.59-2.45h0V5.02h0a2.65 2.65 0 011.6-2.55 5.99 5.99 0 012.23-.4h.57a6.02 6.02 0 012.25.4 2.65 2.65 0 011.59 2.55h0v.31h0a2.62 2.62 0 01-1.6 2.46 6.02 6.02 0 01-2.23.39h-2.15v.88c0 .47.19.56.27.6a5.75 5.75 0 002.8.01c.3-.12.42-.6.42-.6a.23.23 0 01.29-.18l1.66.47a.23.23 0 01.17.28l-.02.1a2.54 2.54 0 01-1.5 1.8 7.32 7.32 0 01-4.76 0zm.76-7.18c-.08.03-.27.12-.27.59v1.12h2.14a4.07 4.07 0 001.31-.18c.09-.03.27-.12.27-.59v-.35c0-.47-.18-.56-.27-.6a4.1 4.1 0 00-1.31-.17h-.56a4.08 4.08 0 00-1.31.18zm-5.93 7.51a.23.23 0 01-.23-.23V5.15a.17.17 0 00-.32-.1l-1.2 1.53a2.41 2.41 0 01-3.9 0l-1.19-1.54a.17.17 0 00-.31.11v6.49a.23.23 0 01-.24.23H32.8a.23.23 0 01-.23-.23V3.86a1.8 1.8 0 011.8-1.8h.15a2.6 2.6 0 011.73.99L38 5.3a.33.33 0 00.59 0l1.74-2.25a2.58 2.58 0 011.74-.99h.27A1.68 1.68 0 0144 3.75v7.9a.23.23 0 01-.24.22z" />
		</Svg>
	)
}

export default LogoAme
