import React from 'react'
import styled from 'styled-components'
import { mq } from '~/helpers/breakpoints'
import IconInfo from '~/svgs/iconInfo'

const FormInfo = ({ children, ...props }) => {
	return (
		<Wrapper {...props}>
			<StyledIconInfo />
			<div>{children}</div>
		</Wrapper>
	)
}

const StyledIconInfo = styled(IconInfo)`
	font-size: 1.8rem;
	flex: none;
	margin-right: 0.7rem;
`

const Wrapper = styled.div`
	font-size: 1rem;
	display: flex;
	margin: 0.5rem 0 1.2rem;
	line-height: 1.3;
	${mq.min('md')} {
		align-items: center;
	}
`

export default FormInfo
