import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

import IconData from '~/svgs/iconData'
import MainLayout from '~/layouts/mainLayout'
import { getStages } from '~/store/reducers/stages/selectors'
import { getViewPortSize } from '~/store/reducers/ui/selectors'
import { breakpointMatch } from '~/helpers/breakpoints'
import { FormTip, Loading, Text } from '~/components'
import { stages } from '~/config/menu'
import history from '~/helpers/history'

import { path as vtPath } from '../vtPage'
import { path as homePath } from '../homePage'
import { StyledStageLink } from './styles'

export const title = 'Ações complementares'
export const path = '/acoes-complementares'
export const icon = IconData

const ComplementaryPage = () => {
	const { search } = useLocation()
	const { backTo } = queryString.parse(search)

	const { loading, currentStages } = useSelector(getStages)
	const viewPortSize = useSelector(getViewPortSize)

	if (breakpointMatch(viewPortSize).isDesktop)
		return <Redirect replace to={vtPath} />

	if (loading)
		return (
			<MainLayout>
				<Loading />
			</MainLayout>
		)

	return (
		<MainLayout onGoBack={() => history.push(backTo || homePath)}>
			<Text dark>
				<p>
					Preencha seus dados com muita atenção e não deixe de anexar a foto do
					documento oficial que comprove os dados preenchidos.
				</p>
			</Text>
			<FormTip>
				Certifique que os documentos estão legíveis, prefira locais com boa
				claridade e se possível utilize um aplicativo de scanner caso digitalize
				seu documento pelo celular.
			</FormTip>

			{currentStages
				?.filter((cs) => cs.rota === path)?.[0]
				?.subEtapa?.map((se) => {
					const description = stages
						?.filter((s) => s.path === path)[0]
						.children?.filter((c) => c.path === se.rota)?.[0]?.description
					return (
						<StyledStageLink
							key={se.rota}
							name={se.nome}
							description={description}
							percent={se.porcentagem}
							enabled={se.ativo}
							to={se.rota}
							displayArrow
						/>
					)
				})}
		</MainLayout>
	)
}

export default ComplementaryPage
