import React from 'react'
import { Field } from 'react-final-form'
import styled from 'styled-components'
import { composeValidators } from '~/helpers/validators'
import useSetFieldData from '~/hooks/useSetFieldData'
import CheckBoxInput from '../inputs/checkBoxInput'

function CheckBoxField({ name, validators = [], fieldProps, ...props }) {
	useSetFieldData(name, {
		displayValue: props.label,
		label: props.label,
		type: 'checkbox',
	})

	return (
		<Field
			type="checkbox"
			name={name}
			{...fieldProps}
			validate={composeValidators(...validators)}
		>
			{({ input }) => (
				<Wrapper>
					<CheckBoxInput {...input} {...props} />
				</Wrapper>
			)}
		</Field>
	)
}

const Wrapper = styled.div`
	display: flex;
	margin: 0.5rem 0 0;

	:first-child:last-child {
		margin: 0.5rem 0;
	}
`

export default CheckBoxField
