import React from 'react'
import styled from 'styled-components'
import Svg from '~/components/svg'

function IconCheck(props) {
	return (
		<Svg viewBox="140 150 195 185" {...props}>
			<path d="M301.6 188.1l-84.1 84.2-30.1-30.1a13.5 13.5 0 00-19.1 0 13.5 13.5 0 000 19.1L208 301a13.2 13.2 0 0019 0l93.7-93.7a13.5 13.5 0 000-19.1 13.4 13.4 0 00-19.1-.1z" />
		</Svg>
	)
}

export default styled(IconCheck)``
