import clsx from 'clsx'
import React from 'react'
import styled from 'styled-components'
import IconRadio from '~/svgs/iconRadio'

function RadioInput({ name, checked, onChange, label, ...props }) {
	return (
		<Label disabled={props.disabled}>
			<HiddenInput
				type="radio"
				onChange={onChange}
				checked={checked}
				{...props}
			/>
			{checked}
			<StyledIconRadio checked={checked} />
			<LabelText>{label}</LabelText>
		</Label>
	)
}

const Label = styled.label.attrs((props) => ({
	className: clsx({
		disabled: props.disabled,
	}),
}))`
	display: inline-flex;
	cursor: pointer;
	user-select: none;
	align-items: center;
	font-size: 0.9rem;
	padding: 2px;
	padding-left: 1px;
	border-radius: 2px;
	position: relative;

	-webkit-tap-highlight-color: transparent;
	/* touch devices */
	@media (hover: none) {
		:active {
			background: #d9d9d9;
		}
	}
`

const StyledIconRadio = styled(IconRadio)`
	flex: none;
	border-radius: 50%;
	color: #000;
	${Label}.disabled & {
		color: #707070;
	}
`

const LabelText = styled.div`
	margin-left: 0.4em;
	transition: all 0.3s ease;
`

const HiddenInput = styled.input`
	position: absolute;
	z-index: -1;
	opacity: 0;
	pointer-events: none;

	&:focus,
	&:focus-visible {
		& ~ ${StyledIconRadio} {
			box-shadow: 0 0 0px 2px #63389280;
			background: #fff;
		}
		& ~ ${LabelText} {
			color: #222;
		}
	}
`

export default RadioInput
