import styled from 'styled-components'

export const Wrapper = styled.div`
	background-color: #fff;
	border-radius: var(--border-radius);

	position: relative;
	/* font-size: 1.05rem; */
	min-width: 100%;
	transition: all 0.4s ease;

	& + & {
		margin-top: var(--spacing);
	}
`

export const Header = styled.button.attrs({ type: 'button' })`
	padding: calc(0.7em);
	min-height: 3.5em;
	width: 100%;
	display: flex;
	text-align: left;
	align-items: center;
	justify-content: space-between;
	outline: 0;
	-webkit-appearance: auto;
	cursor: pointer;
	font-size: 1.05rem;
	font-weight: 500;
	color: var(--color-text-dark);

	div {
		width: 95%;
	}
`
export const Body = styled.div`
	height: 0;
	overflow: hidden;
	box-sizing: content-box;
	padding-left: 0.7em;
	padding-right: 0.7em;
`
