import styled from 'styled-components'

const Title = styled.div`
	font-size: ${(props) => props.size ?? `var(--title-size)`};
	font-weight: var(--title-weight);
	margin-bottom: 0.6em;
	color: var(--title-color);
`

export default Title
