import styled from 'styled-components'

export const LabelField = styled.p`
	font-weight: bold;
	font-size: 13px;
	margin-bottom: 8px;
`

export const FieldFlexBox = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: center;

	span {
		width: 80px;
		text-align: center;
	}

	> div {
		width: 100%;
	}
`
