import React from 'react'
import Svg from '~/components/svg'

// 00BEFF

function IconInfo(props) {
	return (
		<Svg viewBox="0 0 256 256" {...props}>
			<path
				fill="#72a7cf"
				d="M128 22A106 106 0 0022 128a106 106 0 00106 106 106 106 0 00106-106A106 106 0 00128 22zm0 33c5 0 10 2 13 5 4 4 5 8 5 13s-1 10-5 13c-3 4-8 6-13 6s-9-2-13-6c-3-3-5-8-5-13s2-9 5-13c4-3 8-5 13-5zm-16 54h32v92h-32v-92z"
			/>
		</Svg>
	)
}

export default IconInfo
