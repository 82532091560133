import styled, { css } from 'styled-components'

const Text = styled.div`
	color: ${({ dark }) =>
		dark ? 'var(--color-text-dark)' : 'var(--color-text-light)'};
	font-weight: ${({ lightweight }) => (lightweight ? 300 : 400)};

	${({ center }) =>
		center &&
		css`
			text-align: center;
		`}

	p {
		line-height: 1.4;
	}

	ul {
		padding: 0;
		li {
			list-style-type: none;
			line-height: 1.3;
			margin-bottom: 0.4em;
			&::before {
				content: '\u2022';
				display: inline-block;
				margin-right: 0.3em;
				width: 1em;
				height: 1.2em;
				text-align: center;
				line-height: 1.2;
			}
		}

		&.validation {
			li {
				display: flex;
				align-items: center;
				white-space: break-spaces;
				margin-bottom: 0;
				&::before {
					flex: none;
				}
				&.check::before {
					content: '';
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='140 130 195 185'%3E%3Cpath fill='%2312c97a' d='M301.6 188.1l-84.1 84.2-30.1-30.1a13.5 13.5 0 00-19.1 0 13.5 13.5 0 000 19.1L208 301a13.2 13.2 0 0019 0l93.7-93.7a13.5 13.5 0 000-19.1 13.4 13.4 0 00-19.1-.1z'/%3E%3C/svg%3E");
					background-size: contain;
				}
			}
		}
	}

	small {
		font-style: italic;
		font-weight: 300;
	}
`

export default Text
