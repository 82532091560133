import React from 'react'
import { Field } from 'react-final-form'
import FieldInfo from '../fieldInfo'
import NumberInput from '../inputs/numberInput'

function NumberField({ name, fieldProps, helperText, ...props }) {
	return (
		<Field name={name} {...fieldProps}>
			{({ input, meta }) => {
				const error =
					(input.value || meta.submitFailed) && meta.touched && meta.error
				return (
					<div>
						<NumberInput
							onBlur={input.onBlur}
							onFocus={input.onFocus}
							onChange={(value) => input.onChange(value)}
							onValueChange={({ formattedValue }) =>
								input.onChange(formattedValue)
							}
							error={
								(meta.dirty || meta.submitFailed) && meta.touched && meta.error
							}
							valid={input.value.length && meta.valid}
							{...props}
						/>
						<FieldInfo helperText={helperText} error={error} />
					</div>
				)
			}}
		</Field>
	)
}

export default NumberField
