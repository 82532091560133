import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import LogoBancoTalentos from '~/components/logos/logoBancoTalentos'
import UserArea from '~/components/userArea'
import { breakpointMatch, mq } from '~/helpers/breakpoints'
import { getViewPortSize } from '~/store/reducers/ui/selectors'

const Header = ({ onGoBack, ...props }) => {
	const vp = useSelector(getViewPortSize)
	const { isDesktop } = breakpointMatch(vp)

	return (
		<HeaderContainer {...props}>
			{!isDesktop && <LogoBancoTalentos />}
			<UserArea />
		</HeaderContainer>
	)
}

const HeaderContainer = styled.header`
	padding-left: var(--site-h-padding);
	padding-right: var(--site-h-padding);
	grid-area: header;
	z-index: 2;
	padding-top: 4px;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	height: 50px;

	background: #e6e6e6;

	color: #000;
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* gap: 0.8rem; */

	${mq.min('md')} {
		background: transparent;
		justify-content: flex-end;
		position: static;
	}

	${mq.max('22.49em')} {
		font-size: 0.8em;
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 4px;
		background: var(--gradient);

		${mq.min('md')} {
			display: none;
		}
	}
`

export default Header
