import clsx from 'clsx'
import React from 'react'
import styled, { css } from 'styled-components'
import { mq } from '~/helpers/breakpoints'
import IconCheckBox from '~/svgs/iconCheckBox'

function CheckBoxInput({ checked, onChange, label, inline, ...props }) {
	return (
		<Label inline={inline} disabled={props.disabled}>
			<HiddenInput
				type="checkbox"
				onChange={onChange}
				checked={Boolean(checked)}
				{...props}
			/>
			<StyledIconCheckBox checked={checked} />
			<LabelText>{label}</LabelText>
		</Label>
	)
}

const Label = styled.label.attrs((props) => ({
	className: clsx({
		disabled: props.disabled,
	}),
}))`
	display: inline-flex;
	cursor: pointer;
	user-select: none;
	align-items: center;
	font-size: 0.9rem;
	padding: 2px;
	padding-left: 1px;
	border-radius: 2px;
	position: relative;

	${(props) =>
		!props.inline &&
		css`
			${mq.mobile} {
				width: 100%;
			}
		`}

	-webkit-tap-highlight-color: transparent;
	/* touch devices */
	@media (hover: none) and (pointer: coarse) {
		:active {
			background: #d9d9d9;
		}
	}
`

const StyledIconCheckBox = styled(IconCheckBox)`
	flex: none;
	border-radius: 3px;
	color: #000;
	${Label}.disabled & {
		color: #707070;
	}
`

const LabelText = styled.div`
	margin-left: 0.4em;
	transition: all 0.3s ease;
`

const HiddenInput = styled.input`
	position: absolute;
	z-index: -1;
	opacity: 0;
	pointer-events: none;

	&:focus {
		& ~ ${StyledIconCheckBox} {
			box-shadow: 0 0 0px 2px rgba(99, 56, 146, 0.5);
			background: #fff;
		}
		& ~ ${LabelText} {
			color: #222;
		}
	}
`

export default CheckBoxInput
