import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { mq } from '~/helpers/breakpoints'
import { getUser } from '~/store/reducers/user/selectors'
import illustration from '~/images/time-calendar.svg'

function DueDateBox(props) {
	const user = useSelector(getUser)

	return (
		<Wrapper {...props}>
			<div>
				<h1>Atenção!</h1>
				<p>O prazo para preenchimento das informações é até:</p>
				<Date>
					<strong>{format(parseISO(user.dataLimite), 'dd/MM/yyyy')}</strong>
				</Date>
				<p>
					<small>
						Até essa data você pode salvar e retornar para completar as
						informações.
					</small>
				</p>
			</div>
			<StyledImg src={illustration} />
		</Wrapper>
	)
}

const Wrapper = styled.section`
	background: #e5e5e5;
	padding: var(--spacing);
	border: 1px solid #fff;
	border-radius: var(--border-radius);
	text-align: center;
	font-size: 0.7em;
	position: relative;
	overflow: hidden;

	${mq.min('sm')} {
		background: #fff;
		border: 0;
		border-radius: 0;
		height: 11em;
		display: flex;
		justify-content: space-around;
		text-align: left;
		font-size: 1em;

		::before {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			height: 3px;
			z-index: 0;
			background-image: var(--gradient);
		}
	}

	strong {
		color: #000;
	}

	p {
		margin: 0.6em 0;

		${mq.min('sm')} {
			margin: 0.3em 0;
			text-align: center;
		}
	}

	h1 {
		font-weight: bold;
		color: #000;
		font-size: 1em;
		margin: 0.4em;

		${mq.min('sm')} {
			font-size: 1.5em;
		}
	}

	small {
		font-size: 1em;

		${mq.min('sm')} {
			font-size: 80%;
		}
	}
`

const Date = styled.p`
	font-size: 1.2em;
	${mq.min('sm')} {
		font-size: 1.7em;
	}
`

const StyledImg = styled.img`
	display: none;

	${mq.min('sm')} {
		display: block;
		height: 125%;
	}
`

export default DueDateBox
