import React from 'react'
import styled from 'styled-components'
import AnimateHeight from './animateHeight'

function FieldWrapper(props) {
	if (props.hasOwnProperty('shouldRender')) {
		return (
			<AnimateHeight show={props.shouldRender}>
				<Wrapper {...props} />
			</AnimateHeight>
		)
	}

	return <Wrapper {...props} />
}

const Wrapper = styled.div`
	margin-bottom: 1.15rem;
`

export default styled(FieldWrapper)``
