import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import {
	MobileMenu,
	DesktopMenu,
	LogoBancoTalentos,
	BackButton,
	UserArea,
} from '~/components'
import { breakpointMatch, mq } from '~/helpers/breakpoints'
import {
	getAppReady,
	getCurrentPage,
	getViewPortSize,
} from '~/store/reducers/ui/selectors'

const Header = ({ hideMenu, onGoBack, ...props }) => {
	const vp = useSelector(getViewPortSize)
	const { isDesktop } = breakpointMatch(vp)
	const appReady = useSelector(getAppReady)
	const page = useSelector(getCurrentPage)

	return (
		<Wrapper {...props}>
			{!isDesktop && page?.path !== '/' && page?.title ? ( // em página internas, no mobile
				<TitleWrapper>
					{/* <BackButton thick onClick={onGoBack} /> */}
					<BackButton thick onClick={false} />
					<Title>{page.title}</Title>
				</TitleWrapper>
			) : (
				<StyledLink to="/">
					<LogoBancoTalentos />
				</StyledLink>
			)}

			{!isDesktop ? (
				appReady && !hideMenu && <MobileMenu />
			) : (
				<RightSide>
					<UserArea />
					{!hideMenu && <DesktopMenu />}
				</RightSide>
			)}
		</Wrapper>
	)
}

const Wrapper = styled.header`
	padding-left: var(--site-h-padding);
	padding-right: var(--site-h-padding);
	background: #e6e6e6;
	grid-area: header;
	z-index: 2;
	padding-top: 4px;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	height: var(--header-height);
	color: #000;
	font-size: 1rem;

	display: flex;
	justify-content: space-between;
	align-items: center;

	${mq.min('md')} {
		background: #fbfbfb;
		border-bottom: 1px solid #d8d8d8;
	}

	${mq.max('22.49em')} {
		font-size: 0.8rem;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 4px;
		background: var(--gradient);

		${mq.min('md')} {
			display: none;
		}
	}
`

const RightSide = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-end;
	height: 100%;
	padding-top: calc(var(--spacing) * 0.5);
`

const StyledLink = styled(Link)`
	color: var(--color-text-dark);
	text-decoration: none;

	&:focus-visible {
		padding: 1em 0.5em 0;
		margin-top: -1em;
		margin-left: calc(-0.5em - 1px);
		outline: none;
		border: 1px dashed #633892;
		border-radius: 0.3em;
	}
`

const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	color: #707070;
	margin-left: -0.4em;
`
const Title = styled.h2`
	margin: 0;
	font-size: 1rem;
	margin-left: 1em;
`

export default Header
