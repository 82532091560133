import React from 'react'
import Svg from '~/components/svg'

function IconRadio({ checked, ...props }) {
	return (
		<Svg viewBox="0 0 512 512" size={1.25} {...props}>
			{checked ? (
				<path d="M356 492c-130 55-280-6-336-136-55-130 6-280 136-336 130-55 280 6 336 136 55 130-6 280-136 336zm117-236a217 217 0 10-434 0 217 217 0 00434 0zm-63 0a154 154 0 11-308 0 154 154 0 01308 0z" />
			) : (
				<path d="M492 156A257 257 0 00156 20 257 257 0 0020 356a257 257 0 00336 136 257 257 0 00136-336zm-19 100a217 217 0 11-434 0 217 217 0 01434 0h19-19z" />
			)}
		</Svg>
	)
}

export default IconRadio
