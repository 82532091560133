import React from 'react'
import Svg from '~/components/svg'

function IconSearch(props) {
	return (
		<Svg viewBox="0 0 21 21" {...props}>
			<path
				fill="none"
				stroke={props.color ?? 'currentColor'}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="3"
				d="M15.14 15.14a8 8 0 112.34-5.65 7.97 7.97 0 01-2.34 5.65l4.36 4.36z"
			/>
		</Svg>
	)
}

export default IconSearch
