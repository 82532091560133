import styled from 'styled-components'

export const Hr = styled.hr`
	border: 0;
	height: 1px;
	background-color: #e6e6e6;
	margin: 1.3em 0;
`

export const FieldTitle = styled.div`
	font-weight: bold;
	color: var(--color-text-dark);
	font-size: 0.8em;
`

export const FieldValue = styled.div`
	color: var(--color-text-light);
	font-size: 0.9em;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 0.5em;
`

export const SectionTitle = styled.div`
	color: var(--color-text-dark);
	font-size: calc(${(props) => props.size ?? 1} * 1.2em);
	margin-bottom: 0.5em;
`
