import React from 'react'
import styled from 'styled-components'
import IconTip from '~/svgs/iconTip'

const FormTip = ({ children, ...props }) => {
	return (
		<Wrapper {...props}>
			<StyledIconTip />
			<div>{children}</div>
		</Wrapper>
	)
}

const StyledIconTip = styled(IconTip)`
	font-size: 1.7rem;
	flex: none;
	margin-right: 0.7rem;
`

const Wrapper = styled.div`
	font-weight: 400;
	font-size: 0.875rem;
	display: flex;
	margin: 0.6rem 0;
	line-height: 1.3;
`

export default FormTip
