import styled from 'styled-components'

const Svg = styled.svg`
	fill: ${(props) => props.color || `var(--icon-color, currentColor)`};
	stroke: var(--icon-stroke-color, transparent);
	width: auto;
	height: calc(${(props) => props.size ?? 1} * 1em);
	max-width: 100%;
	max-height: 100%;
	transition: all 0.2s ease;
	transform: scale(${(props) => props.scale ?? `var(--icon-scale, 1)`})
		${(props) => props.transformCss};
`

export default Svg
