import styled, { css } from 'styled-components'
import Reaptcha from 'reaptcha'

import { Error } from '~/components'
import LoginLayout from '~/layouts/loginLayout'
import { mq } from '~/helpers/breakpoints'

export const StyledReaptcha = styled(Reaptcha)`
	margin-bottom: var(--spacing);
	margin-top: var(--spacing);
`

export const StyledLoginLayout = styled(LoginLayout)`
	${(props) =>
		props.tokenError &&
		css`
			${mq.between('md', 'lg')} {
				grid-template-rows: 1fr minmax(610px, 60vh) 1fr;
			}
			${mq.min('lg')} {
				padding-top: calc(3 * var(--spacing));
				padding-bottom: calc(3 * var(--spacing));

				@media (max-height: 700px) {
					padding-top: 45px;
					padding-bottom: 53px;
				}
				@media (min-height: 800px) {
					padding-top: calc(5 * var(--spacing));
					padding-bottom: calc(5 * var(--spacing));
				}
				@media (min-height: 900px) {
					padding-top: calc(8 * var(--spacing));
					padding-bottom: calc(8 * var(--spacing));
				}
			}
		`}

	${Error} {
		.error-img {
			margin-left: calc(-2 * var(--spacing));
			margin-right: calc(-2 * var(--spacing));
			max-width: calc(100% + 4 * var(--spacing));
			max-height: calc(100vh - 25 * var(--spacing));
			max-height: min(calc(100vh - 25 * var(--spacing)), 300px);

			${mq.min('lg')} {
				margin-left: calc(-4 * var(--spacing));
				margin-right: calc(-4 * var(--spacing));
				max-width: calc(100% + 8 * var(--spacing));
				min-height: 200px;
				max-height: min(calc(100vh - 25 * var(--spacing)), 300px);
			}
		}
	}
`
