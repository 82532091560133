import styled from 'styled-components'

const DarkBox = styled.div`
	position: relative;
	z-index: 0;
	padding: var(--spacing);
	padding-bottom: 0;
	margin-bottom: var(--spacing);

	::before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		height: 3px;
		z-index: 0;
		background-image: var(--gradient);
	}
	::after {
		content: '';
		background: rgba(0, 0, 0, 0.8);
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		height: calc(100% - var(--spacing));
		z-index: -1;
	}

	--title-size: 1em;
	--title-color: #fff;
	--title-weight: normal;
`

export default DarkBox
