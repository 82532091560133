import { base64ToBlob } from 'base64-blob'
import React, { useImperativeHandle, useState } from 'react'
import toast from 'react-hot-toast'

import { useSelector } from 'react-redux'
import { delay } from '~/helpers/delay'
import { FieldWrapper, CheckBoxInput } from '~/components/'
import { breakpointMatch } from '~/helpers/breakpoints'
import { getViewPortSize } from '~/store/reducers/ui/selectors'

import { Container, Wrapper, PersonPlaceholder, StyledWebcam } from './styles'

function Camera({ ...props }, ref) {
	const camRef = React.useRef(null)

	const [mirrored, setMirrored] = useState(false)
	const [cameraIsOn, setCameraIsOn] = useState(false)
	const [counter, setCounter] = useState(null)

	const vp = useSelector(getViewPortSize)
	const { isMobile } = breakpointMatch(vp)

	const videoConstraints = {
		// width: 450,
		// height: 600,
		aspectRatio: isMobile ? 0.75 : 1.333,
		facingMode: 'user',
	}

	useImperativeHandle(ref, () => ({
		capture: async () => {
			if (!cameraIsOn || counter !== null) return null

			setCounter(3)
			const counterTimeout = setInterval(() => setCounter((c) => c - 1), 1000)
			await delay(3000)
			clearInterval(counterTimeout)

			const blob = await base64ToBlob(
				camRef.current.getScreenshot({ width: 600, height: 450 }),
			)

			setCounter(null)
			return blob
		},
	}))

	return (
		<Container>
			<Wrapper {...props} $cameraIsOn={cameraIsOn} $counter={counter}>
				<StyledWebcam
					audio={false}
					width={600}
					height={450}
					ref={camRef}
					screenshotFormat="image/jpeg"
					mirrored={mirrored}
					videoConstraints={videoConstraints}
					onUserMedia={() => setCameraIsOn(true)}
					onUserMediaError={(error) => {
						console.error(error)
						toast.error(
							'Ocorreu um erro ao se conectar à câmera. Tente inserir uma foto',
						)
					}}
					$cameraIsOn={cameraIsOn}
				/>
				<PersonPlaceholder />
			</Wrapper>
			<FieldWrapper>
				<CheckBoxInput
					inline
					checked={mirrored}
					onChange={() => setMirrored((state) => !state)}
					label="Espelhar imagem"
				/>
			</FieldWrapper>
		</Container>
	)
}

export default React.forwardRef(Camera)
