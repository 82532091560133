import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { StageContainer } from '~/components'
import MainLayout from '~/layouts/mainLayout'
import { setUserStarted } from '~/store/reducers/user/actions'
import { getUserStarted } from '~/store/reducers/user/selectors'

export const title = 'Home'
export const path = '/'

const HomePage = () => {
	const dispatch = useDispatch()
	const userIsStarted = useSelector(getUserStarted)

	useEffect(() => {
		if (userIsStarted) return

		dispatch(setUserStarted())
	}, [dispatch, userIsStarted])

	return (
		<MainLayout center>
			<StageContainer />
		</MainLayout>
	)
}

export default HomePage
