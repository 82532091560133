/* eslint-disable no-shadow */
import React from 'react'
import setFieldData from 'final-form-set-field-data'
import { Form } from 'react-final-form'
// import queryString from 'query-string'
// import { useLocation } from 'react-router-dom'
import arrayMutators from 'final-form-arrays'
import {
	Button,
	FormBody,
	FormFooter,
	FormWrapper,
	Loading,
	Text,
	FieldWrapper,
	FormInfo,
	FormTip,
	// FieldValuesSpy,
	// SelectInput,
	SelectField,
} from '~/components'
import history from '~/helpers/history'
import MainLayout from '~/layouts/mainLayout'
import usePBVForm from '~/hooks/usePBVForm'
// import { path as complementaryPath } from '../complementaryPage'
import ErrorPage from '../errorPage'
import iconCamisa from '~/images/iconCamisa.svg'
import iconCamisaFrio from '~/images/iconCamisaFrio.svg'
import iconCalca from '~/images/iconCalca.svg'
import iconBotina from '~/images/iconBotina.svg'
import { required } from '~/helpers/validators'
import { UNIFORM_SIZES, UNIFORM_SHOES_SIZES } from '~/config/constants'

import { LabelField, FieldFlexBox } from './styles'

export const title = 'Uniforme'
export const path = '/acoes-complementares/uniforme'

const UniformPage = () => {
	// const { search } = useLocation()
	// const { backTo } = queryString.parse(search)

	const { loading, error, initialValues, submitForm, editingDisabled } =
		usePBVForm(`uniforme`)

	const handleGoBack = () => {
		history.goBack()
	}

	const handleSubmit = async (values) => {
		try {
			if (!editingDisabled) {
				await submitForm(values)
			} else {
				handleGoBack()
			}
		} catch (err) {
			console.log(err)
		}
	}

	if (loading)
		return (
			<MainLayout hasSideMenu onGoBack={handleGoBack}>
				<Loading />
			</MainLayout>
		)

	if (error) return <ErrorPage hasSideMenu onGoBack={handleGoBack} />

	return (
		<MainLayout hasSideMenu onGoBack={handleGoBack}>
			<Form
				onSubmit={handleSubmit}
				initialValues={initialValues}
				subscription={{
					submitting: true,
					submitSucceeded: true,
					pristine: true,
					submitError: true,
				}}
				mutators={{ setFieldData, ...arrayMutators }}
				validate={(values) => ({
					camisa: required(values.camisa),
					camisaFrio: required(values.camisaFrio),
					calca: required(values.calca),
					botina: required(values.botina),
				})}
			>
				{({ handleSubmit, submitting }) => (
					<FormWrapper onSubmit={handleSubmit}>
						<FormBody>
							<Text dark>
								{editingDisabled ? (
									<FormInfo>Você já enviou seus dados de uniforme</FormInfo>
								) : (
									<>
										<p>
											Selecione o tamanho do uniforme, talvez sua vaga não
											precise de todas as peças.
										</p>
										<FieldWrapper>
											<FormTip>
												Não se preocupe, caso o tamanho que selecionou não
												sirva, você poderá solicitar outro tamanho no momento do
												recebimento.
											</FormTip>
										</FieldWrapper>
									</>
								)}
							</Text>

							<FieldWrapper>
								<LabelField>Camisa</LabelField>
								<FieldFlexBox>
									<span>
										<img src={iconCamisa} alt="Camisa" />
									</span>
									<SelectField
										name="camisa"
										label="Tamanho da camisa"
										options={UNIFORM_SIZES}
										disabled={editingDisabled}
									/>
								</FieldFlexBox>
							</FieldWrapper>

							<FieldWrapper>
								<LabelField>Camisa de frio</LabelField>
								<FieldFlexBox>
									<span>
										<img src={iconCamisaFrio} alt="Camisa de frio" />
									</span>
									<SelectField
										name="camisaFrio"
										label="Tamanho da camisa de frio"
										options={UNIFORM_SIZES}
										disabled={editingDisabled}
									/>
								</FieldFlexBox>
							</FieldWrapper>

							<FieldWrapper>
								<LabelField>Calça</LabelField>
								<FieldFlexBox>
									<span>
										<img src={iconCalca} alt="Calça" />
									</span>
									<SelectField
										name="calca"
										label="Tamanho da calça"
										options={UNIFORM_SIZES}
										disabled={editingDisabled}
									/>
								</FieldFlexBox>
							</FieldWrapper>

							<FieldWrapper>
								<LabelField>Botina</LabelField>
								<FieldFlexBox>
									<span>
										<img src={iconBotina} alt="Botina" />
									</span>
									<SelectField
										name="botina"
										label="Tamanho da botina"
										options={UNIFORM_SHOES_SIZES}
										disabled={editingDisabled}
									/>
								</FieldFlexBox>
							</FieldWrapper>
						</FormBody>

						<FormFooter style={{ marginTop: '22px' }}>
							<Button type="submit" primary large submitting={submitting}>
								{editingDisabled ? 'Voltar' : 'Salvar'}
							</Button>
						</FormFooter>
					</FormWrapper>
				)}
			</Form>
		</MainLayout>
	)
}

export default UniformPage
