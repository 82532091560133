import React from 'react'
import { formatCellPhone, formatPhone } from '~/helpers/formatters'
import { cellphone, phone } from '~/helpers/validators'
import TextField from './textField'

function PhoneField({ mobile, name, validators, fieldProps, ...props }) {
	validators = [...(validators ?? []), mobile ? cellphone : phone]

	return (
		<TextField
			name={name}
			validators={validators}
			formatter={mobile ? formatCellPhone : formatPhone}
			parser={(value) => value.replace(/\D/g, '').slice(0, 11)}
			fieldProps={fieldProps}
			inputMode="tel"
			{...props}
		/>
	)
}

export default PhoneField
