import React from 'react'
import Svg from '../svg'

const LogoLets = (props) => {
	return (
		<Svg
			viewBox="0 0 42.9 15.9"
			style={{ height: '1em', width: 'auto' }}
			{...props}
		>
			<path d="M10 15.4a.2.2 0 01-.2-.2v-1.9l-1.1 2a.2.2 0 01-.2 0H.7a.2.2 0 01-.2 0V2a.2.2 0 01.2-.2h2.7a.2.2 0 01.2.2v10.7h6.2V2a.2.2 0 01.2-.2h21.4V2L30 4.5a.2.2 0 01-.1 0h-3v10.7a.2.2 0 01-.2.2h-2.8a.2.2 0 01-.1-.2V4.5H20a.2.2 0 01-.1-.1V2.5l-1.1 2a.1.1 0 01-.2 0H13V7h3.3a.2.2 0 01.2.1v2.4a.2.2 0 01-.2.2H13v3h6.6v.2l-1.4 2.5a.2.2 0 010 0zm23.6 0l-.1-.1 1.4-2.6a.1.1 0 01.2 0h3a1.2 1.2 0 001.2-1.2 1.1 1.1 0 00-.5-.9l-3-2A3.7 3.7 0 0134 5.3a3.7 3.7 0 013.8-3.6H41V2l-1.4 2.5a.2.2 0 01-.2.1H38a.8.8 0 00-.9.8.8.8 0 00.4.7l3 2h0a4 4 0 01-2 7.4h0zM31.3 4.3l1.4-2.5-.3-1.2h.1l3.1 1.2v.1l-4.2 2.5-.1-.1z" />
		</Svg>
	)
}

export default LogoLets
