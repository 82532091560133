import React from 'react'
import Svg from '~/components/svg'

function IconClose(props) {
	return (
		<Svg viewBox="0 0 12.3 12.3" {...props}>
			<path
				fill="none"
				stroke={props.color || 'currentColor'}
				d="M.4 12L12 .4M.4.4L12 12"
			/>
		</Svg>
	)
}

export default IconClose
